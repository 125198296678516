const STORYBLOK_IMAGE_HOST = 'https://a.storyblok.com/';

type OptimizeImageProps = {
	options?: {
		/**
		 * Auto-size image without cropping
		 * The "letterbox" background can be changed with a filter
		 * https://www.storyblok.com/docs/image-service#fit-in
		 */
		fitIn?: boolean;
		/**
		 * Width and height expressed as '{width}x{height}', e.g. '500x500'
		 * https://www.storyblok.com/docs/image-service#resizing
		 */
		size?: `${number}x${number}`;
		/**
		 * Enable cropping with face detection
		 * https://www.storyblok.com/docs/image-service#fit-in
		 */
		smartCrop?: boolean;
	};
	/**
	 * The a.storyblok.com hosted image URL.
	 * src will be null if image asset is not set in Storyblok
	 */
	src: string;
};

/**
 * Storyblok's Image Service allows us optimize an image served from their CDN
 * Appending '/m/' converts the image to webp
 * https://www.storyblok.com/docs/image-service
 * https://www.storyblok.com/docs/Guides/how-to-resize-images
 */
export const optimizeStoryblokImage = ({
	src,
	options: { fitIn, size, smartCrop } = {},
}: OptimizeImageProps): string =>
	src && src.includes(STORYBLOK_IMAGE_HOST)
		? `${src}/m/${fitIn ? 'fit-in/' : ''}${size ? size + '/' : ''}${
				smartCrop ? 'smart' : ''
			}`
		: src;
