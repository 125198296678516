import { FC } from 'react';

const VisaCardLogo: FC = () => {
	return (
		<svg
			width="26"
			height="18"
			viewBox="0 0 26 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="visa-card-logo"
		>
			<title id="visa-card-logo" lang="ja">
				{/* Visa card logo */}
				VISAカードのロゴ
			</title>
			<rect width="26" height="18" rx="2" fill="#EBF3FA" />
			<path
				d="M11.1237 12.0625H9.65356L10.5731 6.32227H12.0431L11.1237 12.0625Z"
				fill="#00579F"
			/>
			<path
				d="M16.4523 6.46216C16.1623 6.34601 15.7024 6.21777 15.1337 6.21777C13.6819 6.21777 12.6596 6.99938 12.6533 8.11683C12.6413 8.94129 13.3853 9.39922 13.9417 9.67412C14.5105 9.95502 14.7038 10.1384 14.7038 10.3888C14.6981 10.7733 14.2443 10.9506 13.821 10.9506C13.2341 10.9506 12.9196 10.8592 12.4417 10.6452L12.2481 10.5535L12.0424 11.8421C12.3872 12.0007 13.0224 12.1414 13.6819 12.1476C15.2245 12.1476 16.2287 11.3781 16.2406 10.1872C16.2465 9.53379 15.8536 9.03309 15.0066 8.62394C14.4924 8.3613 14.1775 8.1842 14.1775 7.91547C14.1836 7.67117 14.4439 7.42094 15.0243 7.42094C15.5022 7.40868 15.8533 7.52467 16.1194 7.64073L16.2523 7.70168L16.4523 6.46216Z"
				fill="#00579F"
			/>
			<path
				d="M18.4069 10.0289C18.528 9.69915 18.9939 8.42286 18.9939 8.42286C18.9878 8.43511 19.1147 8.087 19.1873 7.8733L19.29 8.36791C19.29 8.36791 19.5684 9.74192 19.6289 10.0289C19.3991 10.0289 18.6973 10.0289 18.4069 10.0289ZM20.2216 6.32227H19.0845C18.7338 6.32227 18.4674 6.42599 18.3161 6.79853L16.1324 12.0624H17.675C17.675 12.0624 17.9289 11.3539 17.9835 11.2013C18.1527 11.2013 19.6533 11.2013 19.871 11.2013C19.9132 11.4029 20.0464 12.0624 20.0464 12.0624H21.4076L20.2216 6.32227Z"
				fill="#00579F"
			/>
			<path
				d="M8.42544 6.32227L6.98573 10.2365L6.82841 9.44267C6.56224 8.52666 5.72746 7.53144 4.7959 7.03658L6.11462 12.0564H7.66921L9.97995 6.32227H8.42544Z"
				fill="#00579F"
			/>
			<path
				d="M5.64884 6.32227H3.2836L3.2594 6.43825C5.10444 6.9146 6.32637 8.06282 6.82841 9.44292L6.31423 6.80478C6.22958 6.43817 5.96944 6.33436 5.64884 6.32227Z"
				fill="#FAA61A"
			/>
		</svg>
	);
};

export { VisaCardLogo };
