import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Container } from '@/components/storyblok/bloks/Container';

function removeScriptTags(html: string): string {
	return html.replace(
		/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
		''
	);
}

type RawHTMLProps = {
	blok: {
		raw_html: string;
	};
};

export const RawHTML: FC<RawHTMLProps> = ({ blok }) => (
	<Container className="py-6">
		<div
			dangerouslySetInnerHTML={{
				__html: render(removeScriptTags(blok.raw_html)),
			}}
		/>
	</Container>
);
