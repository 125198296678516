import { Button, Dialog } from '@krakentech/coral';
import { FC, ReactNode } from 'react';

type Props = {
	ctaButtonText: string;
	ctaButtonUrl: string;
	descriptionText: ReactNode;
	onBack: () => void;
	open: boolean;
	titleText: string;
};

const OutOfServiceAreaDialog: FC<Props> = ({
	titleText,
	descriptionText,
	onBack,
	ctaButtonText,
	ctaButtonUrl,
	open,
}) => {
	return (
		<Dialog
			ariaLabelledBy="email-subscription-dialog-title"
			onClose={onBack}
			open={open}
		>
			<div className="space-y-8">
				<h2 className="text-2xl font-bold" id="email-subscription-dialog-title">
					{titleText}
				</h2>
				{descriptionText}
				<Button fullWidth href={ctaButtonUrl}>
					{ctaButtonText}
				</Button>
			</div>
		</Dialog>
	);
};

export { OutOfServiceAreaDialog };
