import { GridOperatorName } from '@/constants/industry/gridOperator';
import { UsageTier } from '@/constants/industry/industry';
import { getUsageAmount } from '@/utils/getUsageAmount';

interface GridOperatorUnitFee {
	amount: number;
	gridOperator: GridOperatorName;
}

export const calculateMonthlyRate = (
	gridOperatorUnitFee: GridOperatorUnitFee,
	usageTier: UsageTier,
	renewableEnergyCharge = 3.49, // Yen kWh
	month?: number
): number => {
	const { gridOperator, amount } = gridOperatorUnitFee;

	const usage = getUsageAmount(gridOperator, usageTier, month);

	return Math.floor(usage * amount) + Math.floor(usage * renewableEnergyCharge);
};
