import { FC } from 'react';

import { PostcodeFormVariantProps } from '@/components/shared/PostcodeForm/PostcodeForm';
import { copy } from '@/copy';

export const PostcodeFormWithButtonAttached: FC<PostcodeFormVariantProps> = ({
	postcodeInputLabelText = '郵便番号を入力:',
	postcodeInputProps,
	postcodeButtonProps,
}) => {
	return (
		<>
			<label className="font-bold xl:text-xl" htmlFor="postcode-input">
				{postcodeInputLabelText}
				<div className="mt-2 flex flex-nowrap items-center justify-start md:mt-8">
					<div className="rounded-l-lg pb-2">
						<input
							aria-label="postcode-input"
							data-testid="postcode-input"
							className="w-full rounded-l-lg border-none p-4 text-black outline-none placeholder:text-gray-500 focus:ring-4 focus:ring-inset focus:ring-voltage md:text-2xl lg:p-4 xl:w-64 xl:p-6"
							{...postcodeInputProps}
							type="search"
						/>
					</div>
					<div className="rounded-r-lg pb-2">
						<button
							data-testid="postcode-input-button"
							className="select-none whitespace-nowrap rounded-r-lg bg-soholights p-4 font-bold text-black outline-none transition-all hover:saturate-130 focus:ring-4 focus:ring-inset focus:ring-voltage disabled:opacity-50 md:text-2xl lg:p-4 xl:p-6"
							{...postcodeButtonProps}
							type="submit"
						>
							{copy.getMyQuote}
						</button>
					</div>
				</div>
			</label>
		</>
	);
};
