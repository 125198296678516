import { ContractCapacityOption } from '@/constants/industry/industry';
import { PostalArea, PostalAreaFragment } from '@/services/typed-graphql-sdk';

export const PREFECTURE_NAMES = [
	'北海道',
	'青森県',
	'岩手県',
	'宮城県',
	'秋田県',
	'山形県',
	'福島県',
	'新潟県',
	'茨城県',
	'栃木県',
	'群馬県',
	'埼玉県',
	'千葉県',
	'東京都',
	'神奈川県',
	'富山県',
	'石川県',
	'福井県',
	'山梨県',
	'長野県',
	'岐阜県',
	'静岡県',
	'愛知県',
	'三重県',
	'大阪府',
	'京都府',
	'兵庫県',
	'奈良県',
	'滋賀県',
	'和歌山県',
	'鳥取県',
	'島根県',
	'岡山県',
	'広島県',
	'山口県',
	'徳島県',
	'香川県',
	'愛媛県',
	'高知県',
	'福岡県',
	'佐賀県',
	'長崎県',
	'熊本県',
	'大分県',
	'宮崎県',
	'鹿児島県',
	'沖縄県',
] as const;

export type PrefectureName = (typeof PREFECTURE_NAMES)[number];

export const GRID_OPERATOR_NAME_BY_PREFECTURE_NAME: Record<
	PrefectureName,
	GridOperatorName
> = {
	北海道: 'HEPCO',
	青森県: 'TOHOKU',
	岩手県: 'TOHOKU',
	宮城県: 'TOHOKU',
	秋田県: 'TOHOKU',
	山形県: 'TOHOKU',
	福島県: 'TOHOKU',
	新潟県: 'TOHOKU',
	茨城県: 'TEPCO',
	栃木県: 'TEPCO',
	群馬県: 'TEPCO',
	埼玉県: 'TEPCO',
	千葉県: 'TEPCO',
	東京都: 'TEPCO',
	神奈川県: 'TEPCO',
	山梨県: 'TEPCO',
	静岡県: 'TEPCO',
	富山県: 'HOKUDEN',
	石川県: 'HOKUDEN',
	福井県: 'HOKUDEN',
	長野県: 'CHUBU',
	岐阜県: 'CHUBU',
	愛知県: 'CHUBU',
	三重県: 'CHUBU',
	大阪府: 'KEPCO',
	京都府: 'KEPCO',
	兵庫県: 'KEPCO',
	奈良県: 'KEPCO',
	滋賀県: 'KEPCO',
	和歌山県: 'KEPCO',
	鳥取県: 'CHUGOKU',
	島根県: 'CHUGOKU',
	岡山県: 'CHUGOKU',
	広島県: 'CHUGOKU',
	山口県: 'CHUGOKU',
	徳島県: 'YONDEN',
	香川県: 'YONDEN',
	愛媛県: 'YONDEN',
	高知県: 'YONDEN',
	福岡県: 'KYUDEN',
	佐賀県: 'KYUDEN',
	長崎県: 'KYUDEN',
	熊本県: 'KYUDEN',
	大分県: 'KYUDEN',
	宮崎県: 'KYUDEN',
	鹿児島県: 'KYUDEN',
	沖縄県: 'OEPC',
};

/**
 * Grid operators in Japan
 * https://ja.wikipedia.org/wiki/日本の電力会社#日本の電気事業者
 */
export const GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME = {
	HEPCO: '01',
	TOHOKU: '02',
	TEPCO: '03',
	CHUBU: '04',
	HOKUDEN: '05',
	KEPCO: '06',
	CHUGOKU: '07',
	YONDEN: '08',
	KYUDEN: '09',
	OEPC: '10',
} as const;

export type GridOperatorName =
	keyof typeof GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME;

export const GRID_OPERATOR_NAMES: GridOperatorName[] = Object.keys(
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME
) as GridOperatorName[];

export type GridOperatorCode =
	(typeof GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME)[GridOperatorName];

export const GRID_OPERATOR_CODES: GridOperatorCode[] = Object.values(
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME
);

export const GRID_OPERATOR_NAME_BY_GRID_OPERATOR_CODE = Object.fromEntries(
	Object.entries(GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME).map(
		([gridOperatorName, gridOperatorCode]) => [
			gridOperatorCode,
			gridOperatorName,
		]
	)
) as Record<GridOperatorCode, GridOperatorName>;

export type GridAreaName =
	| '関西'
	| '中部'
	| '中国'
	| '四国'
	| '東京'
	| '東北'
	| '北海道'
	| '北陸'
	| '九州'
	| '沖縄';

export const GRID_AREA_NAME_BY_GRID_OPERATOR_CODE: Record<
	GridOperatorCode,
	GridAreaName
> = {
	'01': '北海道',
	'02': '東北',
	'03': '東京',
	'04': '中部',
	'05': '北陸',
	'06': '関西',
	'07': '中国',
	'08': '四国',
	'09': '九州',
	'10': '沖縄',
};
export const AREAS = [
	'北海道',
	'東北',
	'関東',
	'中部',
	'北陸',
	'関西',
	'中国',
	'四国',
	'九州',
	'沖縄',
] as const;
export type Area = (typeof AREAS)[number];

export const SUPPORTED_AREAS = [
	'北海道',
	'東北',
	'関東',
	'中部',
	'北陸',
	'関西',
	'中国',
	'四国',
	'九州',
] as const;
export type SupportedArea = (typeof SUPPORTED_AREAS)[number];

export const GridOperatorNameByArea: Record<SupportedArea, GridOperatorName> = {
	北海道: 'HEPCO',
	東北: 'TOHOKU',
	関東: 'TEPCO',
	中部: 'CHUBU',
	北陸: 'HOKUDEN',
	関西: 'KEPCO',
	中国: 'CHUGOKU',
	四国: 'YONDEN',
	九州: 'KYUDEN',
};

export const GridOperatorCodeByArea: Record<SupportedArea, GridOperatorCode> = {
	北海道: '01',
	東北: '02',
	関東: '03',
	中部: '04',
	北陸: '05',
	関西: '06',
	中国: '07',
	四国: '08',
	九州: '09',
};

// In some cases the operator code 03 should map to Kanto, not Tokyo
export const AREA_BY_GRID_OPERATOR_CODE: Record<
	GridOperatorCode,
	(typeof AREAS)[number]
> = {
	'01': '北海道',
	'02': '東北',
	'03': '関東',
	'04': '中部',
	'05': '北陸',
	'06': '関西',
	'07': '中国',
	'08': '四国',
	'09': '九州',
	'10': '沖縄',
};

export enum GridOperatorTier {
	NonTiered = 'NON_TIERED',
	None = 'NONE',
	Tiered = 'TIERED',
}

export const CONTRACT_CAPACITY_OPTIONS_BY_GRID_OPERATOR_TIER: Record<
	GridOperatorTier,
	ContractCapacityOption[]
> = {
	NON_TIERED: [
		ContractCapacityOption.amperage,
		ContractCapacityOption.kva,
		ContractCapacityOption.dontKnow,
	],
	TIERED: [
		ContractCapacityOption.lowVoltage,
		ContractCapacityOption.kva,
		ContractCapacityOption.dontKnow,
	],
	NONE: [ContractCapacityOption.OEPC],
};

export const DEFAULT_CONTRACT_CAPACITY_OPTION_BY_GRID_OPERATOR_TIER: Record<
	GridOperatorTier,
	ContractCapacityOption
> = {
	NON_TIERED: ContractCapacityOption.amperage,
	TIERED: ContractCapacityOption.lowVoltage,
	NONE: ContractCapacityOption.OEPC,
};

export const GRID_OPERATOR_TIER_BY_GRID_OPERATOR_NAME: Record<
	GridOperatorName,
	GridOperatorTier
> = {
	HEPCO: GridOperatorTier.NonTiered,
	TOHOKU: GridOperatorTier.NonTiered,
	TEPCO: GridOperatorTier.NonTiered,
	CHUBU: GridOperatorTier.NonTiered,
	HOKUDEN: GridOperatorTier.NonTiered,
	KEPCO: GridOperatorTier.Tiered,
	CHUGOKU: GridOperatorTier.Tiered,
	YONDEN: GridOperatorTier.Tiered,
	KYUDEN: GridOperatorTier.NonTiered,
	OEPC: GridOperatorTier.None,
};

export const GRID_OPERATOR_TIER_BY_GRID_OPERATOR_CODE: Record<
	GridOperatorCode,
	GridOperatorTier
> = {
	'01': GridOperatorTier.NonTiered,
	'02': GridOperatorTier.NonTiered,
	'03': GridOperatorTier.NonTiered,
	'04': GridOperatorTier.NonTiered,
	'05': GridOperatorTier.NonTiered,
	'06': GridOperatorTier.Tiered,
	'07': GridOperatorTier.Tiered,
	'08': GridOperatorTier.Tiered,
	'09': GridOperatorTier.NonTiered,
	'10': GridOperatorTier.None,
};

export const gridOperatorTierFromGridOperatorCode = (
	gridOperatorCode: GridOperatorCode
): GridOperatorTier =>
	GRID_OPERATOR_TIER_BY_GRID_OPERATOR_CODE[gridOperatorCode];

export const doesGridOperatorTierSupportContractCapacity = (
	gridOperatorTier: GridOperatorTier,
	contractCapacity: ContractCapacityOption
): boolean =>
	CONTRACT_CAPACITY_OPTIONS_BY_GRID_OPERATOR_TIER[gridOperatorTier].includes(
		contractCapacity
	);

const POSTCODE_REGEX_GRID_OPERATOR_CODE_TUPLES: [RegExp, GridOperatorCode][] = [
	[/^(42|43)/, GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.CHUBU],
	[/^(519-4|917|919)/, GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.KEPCO],
	[/^(761-4|794)/, GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.CHUGOKU],
];

export const gridOperatorCodeFromPostalArea = ({
	postcode,
	prefecture,
}: Pick<PostalAreaFragment, 'postcode' | 'prefecture'>): GridOperatorCode =>
	POSTCODE_REGEX_GRID_OPERATOR_CODE_TUPLES.find(([regex]) =>
		regex.test(postcode)
	)?.[1] ?? gridOperatorCodeFromPrefectureName(prefecture as PrefectureName);

export const gridOperatorCodeFromPrefectureName = (
	prefectureName: PrefectureName
): GridOperatorCode =>
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME[
		GRID_OPERATOR_NAME_BY_PREFECTURE_NAME[prefectureName]
	] ?? GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.TEPCO;

export function validateSupportedGridOperatorNames(
	gridOperatorNames: GridOperatorName[],
	{ shouldAllowEmptyList }: { shouldAllowEmptyList?: boolean } = {}
): void {
	if (!gridOperatorNames?.length && !shouldAllowEmptyList) {
		throw new Error('There are no supported GridOperatorNames');
	}

	const invalidGridOperatorNames = gridOperatorNames.filter(
		(gridOperatorName) => !GRID_OPERATOR_NAMES.includes(gridOperatorName)
	);
	if (invalidGridOperatorNames.length) {
		throw new Error(
			`Invalid GridOperatorNames: ${invalidGridOperatorNames.join(',')}`
		);
	}
}

export const getSupportedGridOperatorNames = (
	supportedGridOperatorNames: string
): GridOperatorName[] =>
	supportedGridOperatorNames
		? (supportedGridOperatorNames.split(',') as GridOperatorName[])
		: [];

export const getSupportedPrefectureNames = (
	gridOperatorNames: GridOperatorName[]
): PrefectureName[] =>
	Object.entries(GRID_OPERATOR_NAME_BY_PREFECTURE_NAME)
		.filter(([, gridOperatorName]) =>
			gridOperatorNames.includes(gridOperatorName)
		)
		.map(([prefectureName]) => prefectureName as PrefectureName);

/**
 * ev-octopus journey
 */

const SUPPORTED_EV_OCTOPUS_GRID_OPERATOR_NAMES = getSupportedGridOperatorNames(
	process.env.NEXT_PUBLIC_SUPPORTED_EV_OCTOPUS_GRID_OPERATOR_NAMES ?? ''
);
validateSupportedGridOperatorNames(SUPPORTED_EV_OCTOPUS_GRID_OPERATOR_NAMES);

export const SUPPORTED_EV_OCTOPUS_PREFECTURE_NAMES =
	getSupportedPrefectureNames(SUPPORTED_EV_OCTOPUS_GRID_OPERATOR_NAMES);

/**
 * solar-octopus journey
 */

const SUPPORTED_SOLAR_TARIFF_GRID_OPERATOR_NAMES =
	getSupportedGridOperatorNames(
		process.env.NEXT_PUBLIC_SUPPORTED_SOLAR_TARIFF_GRID_OPERATOR_NAMES ?? ''
	);
validateSupportedGridOperatorNames(SUPPORTED_SOLAR_TARIFF_GRID_OPERATOR_NAMES, {
	shouldAllowEmptyList: true,
});

export const SUPPORTED_SOLAR_TARIFF_PREFECTURE_NAMES =
	getSupportedPrefectureNames(SUPPORTED_SOLAR_TARIFF_GRID_OPERATOR_NAMES);

/**
 * FiT journey
 */
const SUPPORTED_FIT_GRID_OPERATOR_NAMES = getSupportedGridOperatorNames(
	process.env.NEXT_PUBLIC_SUPPORTED_FIT_GRID_OPERATOR_NAMES ?? ''
);
validateSupportedGridOperatorNames(SUPPORTED_FIT_GRID_OPERATOR_NAMES, {
	shouldAllowEmptyList: true,
});

export const SUPPORTED_FIT_PREFECTURE_NAMES = getSupportedPrefectureNames(
	SUPPORTED_FIT_GRID_OPERATOR_NAMES
);

/**
 * Simple journey
 */
export const SUPPORTED_SIMPLE_GRID_OPERATOR_NAMES: GridOperatorName[] = [
	'HEPCO',
	'TOHOKU',
	'TEPCO',
	'CHUBU',
	'HOKUDEN',
	'KEPCO',
	'CHUGOKU',
	'YONDEN',
	'KYUDEN',
];

validateSupportedGridOperatorNames(SUPPORTED_SIMPLE_GRID_OPERATOR_NAMES, {
	shouldAllowEmptyList: true,
});

export const SUPPORTED_SIMPLE_PREFECTURE_NAMES = getSupportedPrefectureNames(
	SUPPORTED_SIMPLE_GRID_OPERATOR_NAMES
);

/**
 * default journey
 */

const SUPPORTED_GRID_OPERATOR_NAMES = getSupportedGridOperatorNames(
	process.env.NEXT_PUBLIC_SUPPORTED_GRID_OPERATOR_NAMES ?? ''
);
validateSupportedGridOperatorNames(SUPPORTED_GRID_OPERATOR_NAMES);

export const SUPPORTED_PREFECTURE_NAMES = getSupportedPrefectureNames(
	SUPPORTED_GRID_OPERATOR_NAMES
);

export const SUPPORTED_GRID_OPERATOR_CODES = SUPPORTED_GRID_OPERATOR_NAMES.map(
	(gridOperatorName) =>
		GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME[gridOperatorName]
);

export const SPIN_THIRD_DIGIT_HIGH_VOLTAGE_SIGNIFIER = '1';

const TIERED_CAPACITY_GRID_OPERATOR_CODES: GridOperatorCode[] = [
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.CHUGOKU,
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.KEPCO,
	GRID_OPERATOR_CODE_BY_GRID_OPERATOR_NAME.YONDEN,
];

/**
 *  @note
 *      OEPC (Okinawa) is not included in the concept of Tiered or Non-Tiered.
 *      Because OEPC does not require to choose kva or amperage.
 *      In other words, grid operators can be divided like below under the concept.
 *          - Tiered Capacity Grid Operator
 *          - Non Tiered Capacity Grid Operator
 *          - OEPC
 * */
export const isTieredCapacityGridOperator = (
	gridOperatorCode: GridOperatorCode
): boolean => TIERED_CAPACITY_GRID_OPERATOR_CODES.includes(gridOperatorCode);

export const GRID_OPERATOR_TO_MAXIMUM_BUILDING_NAME_LENGTH: Record<
	GridOperatorCode,
	number
> = {
	'01': 36,
	'02': 36,
	'03': 36,
	'04': 30,
	'05': 18,
	'06': 36,
	'07': 26,
	'08': 36,
	'09': 36,
	'10': 36,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isGridOperatorCode = (x: any): x is GridOperatorCode =>
	GRID_OPERATOR_CODES.includes(x);

export const getGridOperatorMaximumBuildingNameLengthFromGridOperatorCode = (
	gridOperatorCode: string
): number => {
	if (isGridOperatorCode(gridOperatorCode)) {
		return GRID_OPERATOR_TO_MAXIMUM_BUILDING_NAME_LENGTH[gridOperatorCode];
	} else {
		return GRID_OPERATOR_TO_MAXIMUM_BUILDING_NAME_LENGTH['03']; // fallback to TEPCO maxlen
	}
};

/**
 * Tokyo consists of 23 special wards and 39 other, ordinary municipalities (cities, towns, and villages).
 * https://en.wikipedia.org/wiki/Special_wards_of_Tokyo
 *
 * A PostalArea "city" corresponds to a Tokyo "ward"
 */
export const TOKYO_23_WARDS = [
	'千代田区',
	'中央区',
	'港区',
	'新宿区',
	'文京区',
	'台東区',
	'墨田区',
	'江東区',
	'品川区',
	'目黒区',
	'大田区',
	'世田谷区',
	'渋谷区',
	'中野区',
	'杉並区',
	'豊島区',
	'北区',
	'荒川区',
	'板橋区',
	'練馬区',
	'足立区',
	'葛飾区',
	'江戸川区',
];

export const isTokyo23Ward = (city: PostalArea['city']): boolean =>
	TOKYO_23_WARDS.includes(city);
