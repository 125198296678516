import { isBrowser } from '@/utils/browser/isBrowser';

export const axeDevTools = (): void => {
	if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' && isBrowser()) {
		Promise.all([
			import('@axe-core/react'),
			import('react'),
			import('react-dom'),
		]).then(([axe, React, ReactDOM]) => {
			axe.default(React.default, ReactDOM.default, 1000);
		});
	}
};
