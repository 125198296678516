'use client';
import { Button } from '@krakentech/coral';
import { Footer } from '@krakentech/coral-organisms';
import {
	IconFacebook,
	IconInstagram,
	IconLine,
	IconX,
} from '@krakentech/icons';
import { FC } from 'react';

import { THIS_YEAR } from '@/constants/constants';
import { LINE_COMPANY_ID, TWITTER_HANDLE } from '@/constants/marketing';
import { PAGES } from '@/constants/pages';
import { copy } from '@/copy';

const menuLeft = [
	{ text: copy.companyOverview, href: '/about-us' },
	{ text: copy.termsAndConditions, href: '/terms' },
	{
		text: copy.aboutUsingThisSite,
		href: '/terms#website-terms-and-conditions',
	},
	{ text: copy.handlingOfPersonalInformation, href: '/privacy' },
	{ text: copy.fuelMix, href: '/fuel-mix-standard' },
	{ text: copy.companyProfile, href: '/terms#company-profile' },
];

const menuCenter = [
	{ text: copy.contactUs, href: '/contact-us' },
	{ text: copy.careerInformation, href: '/career' },
	{ text: copy.frequentlyAskedQuestions, href: '/faqs' },
	{ text: copy.tariffs, href: PAGES.tariffs },
	{ text: copy.english, href: PAGES.englishOBJ },
];

const socialLinks: Array<{
	href: string;
	icon: React.ReactNode;
	label: string;
}> = [
	{
		icon: <IconLine size={44} />,
		label: copy.lineLogoLabel,
		href: `https://lin.ee/${LINE_COMPANY_ID}`,
	},
	{
		icon: <IconFacebook size={44} />,
		label: copy.facebookLogoLabel,
		href: 'https://www.facebook.com/octopusenergyjp',
	},
	{
		icon: <IconX size={44} />,
		label: copy.twitterLogoLabel,
		href: `https://twitter.com/${TWITTER_HANDLE}`,
	},
	{
		icon: <IconInstagram size={44} />,
		label: copy.instagramLogoLabel,
		href: 'https://www.instagram.com/octopusenergyjp/',
	},
];

const FooterWithCoral: FC = () => (
	<Footer maxWidth="lg">
		<Footer.ListContainer variant="left">
			<Footer.List>
				{menuLeft.map((menuItem, i) => (
					<Footer.ListItem key={i}>
						<Button color="tertiary" variant="text" href={menuItem.href}>
							{menuItem.text}
						</Button>
					</Footer.ListItem>
				))}
			</Footer.List>
		</Footer.ListContainer>
		<Footer.ListContainer variant="center">
			<Footer.List>
				{menuCenter.map((menuItem, i) => (
					<Footer.ListItem key={i}>
						<Button color="tertiary" variant="text" href={menuItem.href}>
							{menuItem.text}
						</Button>
					</Footer.ListItem>
				))}
			</Footer.List>
		</Footer.ListContainer>
		<Footer.ListContainer variant="right">
			<Footer.SocialList>
				{socialLinks.map((link, i) => (
					<Footer.SocialLink
						href={link.href}
						icon={link.icon}
						label={link.label}
						key={i}
					/>
				))}
			</Footer.SocialList>
			<Footer.CountryList currentCountry="JP" omit />
		</Footer.ListContainer>
		<section className="mt-16">
			<div className="flex flex-col space-y-2 text-sm leading-tight text-gray-200 md:flex-row md:gap-4 md:space-y-0">
				© {THIS_YEAR} TGオクトパスエナジー株式会社 Octopus Energy Group Limited
				All Rights Reserved.
			</div>
		</section>
	</Footer>
);

export { FooterWithCoral };
