import * as Sentry from '@sentry/nextjs';
import { FC, useEffect, useState } from 'react';

import {
	ParticleFall,
	ParticleFallProps,
} from '@/components/shared/animations/ParticleFall';

const SakuraPetalImageSources = [
	'/images/sakura/Sakura-1.svg',
	'/images/sakura/Sakura-2.svg',
	'/images/sakura/Sakura-3.svg',
	'/images/sakura/Sakura-4.svg',
	'/images/sakura/Sakura-5.svg',
	'/images/sakura/Sakura-6.svg',
];

export const Sakura: FC = () => {
	const [images, setImages] = useState<HTMLImageElement[]>();
	const particalProps: ParticleFallProps = {
		count: 25,
		color: 'pink',
		wind: [2.0, 4.0],
		speed: [2.55, 4.25],
		images,
		radius: [20, 40],
		rotationSpeed: [0, 0],
	};

	useEffect(() => {
		const imagePromises = SakuraPetalImageSources.map((src) => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = () => resolve(img);
				img.onerror = reject;
				img.src = src;
			});
		});
		Promise.all(imagePromises)
			.then((images) => {
				setImages(images as HTMLImageElement[]);
			})
			.catch((error) => {
				Sentry.captureMessage(
					'There was an error loading the Sakura petal svgs',
					{
						extra: { error },
					}
				);
			});
	}, []);

	return images ? <ParticleFall {...particalProps} /> : null;
};
