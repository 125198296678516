import { FC } from 'react';

const JCBCardLogo: FC = () => {
	return (
		<svg
			width="26"
			height="18"
			viewBox="0 0 26 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="JCB-card-logo"
		>
			<title id="JCB-card-logo" lang="ja">
				{/* JCB card logo */}
				JCBカードのロゴ
			</title>
			<rect width="26" height="18" rx="2" fill="#F2FFED" />
			<path
				d="M16.5792 9.21594C16.9608 9.22432 17.3445 9.19893 17.7246 9.22913C18.1095 9.30163 18.2022 9.88991 17.8603 10.0826C17.627 10.2095 17.3498 10.1299 17.0969 10.1522H16.5792V9.21594ZM17.9452 8.15618C18.03 8.4583 17.7415 8.72956 17.4533 8.68796H16.5792C16.5853 8.40304 16.5672 8.0938 16.588 7.82389C16.9382 7.83385 17.2916 7.80358 17.6398 7.83972C17.7894 7.87766 17.9146 8.00182 17.9452 8.15618ZM20.049 3.6758C20.0652 4.25278 20.0513 4.86025 20.056 5.44893C20.0549 7.84229 20.0583 10.2356 20.0541 12.6291C20.0388 13.526 19.2514 14.3054 18.3691 14.3233C17.486 14.327 16.6027 14.3239 15.7195 14.3249V10.7066C16.6818 10.7016 17.6447 10.7168 18.6066 10.699C19.0529 10.6708 19.5417 10.3735 19.5624 9.87768C19.615 9.37973 19.15 9.03531 18.7085 8.98091C18.5388 8.97646 18.5438 8.93096 18.7085 8.91112C19.1296 8.81924 19.4602 8.37924 19.3363 7.93859C19.2308 7.47512 18.7234 7.29574 18.3014 7.29663C17.4409 7.29073 16.5802 7.29581 15.7197 7.29409C15.7252 6.61861 15.7081 5.9424 15.7289 5.26741C15.7971 4.38663 16.6043 3.66027 17.4741 3.67603C18.3325 3.6759 19.1908 3.6759 20.049 3.67587V3.6758Z"
				fill="url(#paint0_linear_485_17352)"
			/>
			<path
				d="M5.9953 5.35782C6.01728 4.46227 6.808 3.68927 7.68914 3.67618C8.56901 3.67344 9.449 3.67578 10.3289 3.67499C10.3265 6.6713 10.3336 9.66795 10.3252 12.6641C10.2914 13.5488 9.50925 14.307 8.63771 14.3227C7.75618 14.3259 6.87455 14.3232 5.99298 14.324V10.5838C6.84927 10.788 7.74723 10.875 8.6208 10.7395C9.14297 10.6547 9.71429 10.3958 9.89108 9.84884C10.0212 9.38099 9.94793 8.88861 9.96729 8.40843V7.29328H8.4555C8.44871 8.03081 8.46941 8.76963 8.44456 9.50627C8.40381 9.95906 7.95968 10.2467 7.53671 10.2314C7.01215 10.237 5.97267 9.84756 5.97267 9.84756C5.97006 8.46573 5.98789 6.7352 5.9953 5.35795V5.35782Z"
				fill="url(#paint1_linear_485_17352)"
			/>
			<path
				d="M10.8927 7.74325C10.8131 7.76029 10.8767 7.46958 10.8563 7.3593C10.8617 6.66202 10.845 5.96399 10.8656 5.2672C10.9335 4.3827 11.7469 3.65453 12.6203 3.67524H15.1924C15.19 6.67155 15.1971 9.66819 15.1888 12.6643C15.1549 13.549 14.3727 14.3072 13.5011 14.3229C12.6196 14.3263 11.7379 14.3235 10.8563 14.3243V10.2264C11.4584 10.7252 12.2767 10.8028 13.0269 10.8041C13.5924 10.8039 14.1546 10.7159 14.7037 10.5842V9.83349C14.0848 10.1449 13.3573 10.3427 12.6713 10.1638C12.1927 10.0435 11.8453 9.57657 11.853 9.07793C11.7976 8.55937 12.0986 8.0119 12.6034 7.85774C13.23 7.65967 13.913 7.81116 14.5004 8.06868C14.6263 8.1352 14.754 8.21772 14.7037 8.00534V7.41525C13.7213 7.17926 12.6758 7.09242 11.6888 7.34914C11.4031 7.43051 11.1247 7.55391 10.8927 7.74331V7.74325Z"
				fill="url(#paint2_linear_485_17352)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_485_17352"
					x1="15.6899"
					y1="8.77266"
					x2="20.0286"
					y2="8.77266"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#58B03A" />
					<stop offset="1" stopColor="#55B330" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_485_17352"
					x1="5.90073"
					y1="9.10149"
					x2="10.2578"
					y2="9.10149"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0F6EB6" />
					<stop offset="1" stopColor="#006DBA" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_485_17352"
					x1="10.8499"
					y1="8.87525"
					x2="15.1916"
					y2="8.87525"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DE0D3D" />
					<stop offset="1" stopColor="#E30138" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export { JCBCardLogo };
