/**
 * @note
 * Custom PII extra data scrubbing configuration for known PII variable keys.
 *
 * Sentry detects PII automatically and filters response strings.
 * Values added to this array should be removed _before_ being sent to Sentry.
 * See: https://develop.sentry.dev/pii/
 */
const PIIFields = [
	'accountHolder',
	'addressLine1',
	'addressLine2',
	'area',
	'bankAccountBankCode',
	'bankAccountBranchCode',
	'bankAccountHolder',
	'bankAccountHolderName',
	'bankAccountNumber',
	'bankAccountToken',
	'bankAccountType',
	'bankCode',
	'branchCode',
	'billingAddressLine1',
	'billingAddressLine2',
	'billingPostcode',
	'cardCVC',
	'cardExpiryDate',
	'cardNumber',
	'city',
	'contractFamilyName',
	'contractFamilyNameKana',
	'contractGivenName',
	'contractGivenNameKana',
	'contractNumber',
	'creditCardToken',
	'currentSupplierCode',
	'email',
	'familyName',
	'familyNameKana',
	'firstName',
	'firstNameKatakana',
	'givenName',
	'givenNameKana',
	'lastName',
	'lastNameKatakana',
	'mobile',
	'paymentType',
	'postcode',
	'prefecture',
	'quoteCode',
	'supplyPointId',
];

export const PIIRegExp = new RegExp(
	`((${PIIFields.join('|')})(.*?)?):(.*?)(,|})`,
	'g'
);

export const scrubPII = <T>(data: T): T | string =>
	PIIRegExp.test(JSON.stringify(data))
		? JSON.stringify(data).replace(PIIRegExp, '[redacted]')
		: data;
