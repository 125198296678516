import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { GetAffiliateLinkQuery } from '@/services/typed-graphql-sdk';
import { noop } from '@/utils/noop';

export type AffiliateContextValue = {
	affiliateContent: GetAffiliateLinkQuery | null;
	isAffiliatesReady: boolean;
	isServersideError?: boolean;
	salesChannel: string;
	setAffiliateContent: (affiliateContent: GetAffiliateLinkQuery | null) => void;
	setIsAffiliatesReady: (isReady: boolean) => void;
	setIsError: (isError?: boolean) => void;
	setSalesChannel: (channel: string) => void;
};

export const AffiliatesContext = createContext<AffiliateContextValue>({
	affiliateContent: null,
	setAffiliateContent: noop,
	isServersideError: undefined,
	setIsError: noop,
	isAffiliatesReady: false,
	setIsAffiliatesReady: noop,
	salesChannel: '',
	setSalesChannel: noop,
});

type Props = {
	children: ReactNode;
};

export const AffiliateProvider: FC<Props> = ({ children }) => {
	const [isAffiliatesReady, setIsAffiliatesReady] = useState(false);
	const [salesChannel, setSalesChannel] = useState('');
	const [isServersideError, setIsError] = useState<boolean | undefined>(
		undefined
	);
	const [affiliateContent, setAffiliateContent] =
		useState<GetAffiliateLinkQuery | null>(null);

	return (
		<AffiliatesContext.Provider
			value={{
				setAffiliateContent,
				affiliateContent,
				isServersideError,
				setIsError,
				isAffiliatesReady,
				setIsAffiliatesReady,
				salesChannel,
				setSalesChannel,
			}}
		>
			{children}
		</AffiliatesContext.Provider>
	);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAffiliatesContext = () => useContext(AffiliatesContext);
