import { EventObject } from 'xstate';

/*
 * Type Guard which narrows down event type to given type
 *
 * Throws error if unexpected event
 *
 * Context: Any action in `actions` assume that any event can be sent to it,
 * therefore we have to explicitly guard against unwanted events
 *
 * https://github.com/davidkpiano/xstate/discussions/1591#discussioncomment-111941
 */
export function assertEventType<
	TEvent extends EventObject,
	TType extends TEvent['type'],
>(event: TEvent, eventType: TType): asserts event is TEvent & { type: TType } {
	if (event.type !== eventType) {
		throw new Error(
			`Invalid event: expected "${eventType}", got "${event.type}"`
		);
	}
}

export function assertIsNumber(value: unknown): asserts value is number {
	if (typeof value !== 'number') {
		throw `${value} is not a number`;
	}
}

export function assertIsString(value: unknown): asserts value is string {
	if (typeof value !== 'string') {
		throw `${value} is not a string`;
	}
}
