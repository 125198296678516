import { random } from '@/utils/random';

export interface ReadingEntry {
	english: string;
	hiragana: string;
	japanese: string;
	katakana: string;
}
export type Phoneme = keyof typeof RADIOTELEPHONY_BY_PHONEME;
export type BrowserId = `${string} ${string} ${string} ${string}`;

const NUM_TOKENS_IN_BROWSER_ID = 5;

/**
 * A dictionary of the Japanese radiotelephony alphabet
 *
 * @see https://en.wikipedia.org/wiki/Japanese_radiotelephony_alphabet
 */
export const RADIOTELEPHONY_BY_PHONEME = {
	A: { hiragana: 'あ', katakana: 'ア', english: 'Asahi', japanese: '朝日' },
	I: { hiragana: 'い', katakana: 'イ', english: 'Iroha', japanese: 'いろは' },
	U: { hiragana: 'う', katakana: 'ウ', english: 'Ueno', japanese: '上野' },
	E: { hiragana: 'え', katakana: 'エ', english: 'Eigo', japanese: '英語' },
	O: { hiragana: 'お', katakana: 'オ', english: 'Ōsaka', japanese: '大阪' },
	KA: { hiragana: 'か', katakana: 'カ', english: 'Kawase', japanese: '為替' },
	KI: { hiragana: 'き', katakana: 'キ', english: 'Kitte', japanese: '切手' },
	KU: { hiragana: 'く', katakana: 'ク', english: 'Kurabu', japanese: 'クラブ' },
	KE: { hiragana: 'け', katakana: 'ケ', english: 'Keshiki', japanese: '景色' },
	KO: { hiragana: 'こ', katakana: 'コ', english: 'Kodomo', japanese: '子供' },
	SA: { hiragana: 'さ', katakana: 'サ', english: 'Sakura', japanese: '桜' },
	SHI: { hiragana: 'し', katakana: 'シ', english: 'Shinbun', japanese: '新聞' },
	SU: { hiragana: 'す', katakana: 'ス', english: 'Suzume', japanese: 'すずめ' },
	SE: { hiragana: 'せ', katakana: 'セ', english: 'Sekai', japanese: '世界' },
	SO: {
		hiragana: 'そ',
		katakana: 'ソ',
		english: 'Soroban',
		japanese: 'そろばん',
	},
	TA: { hiragana: 'た', katakana: 'タ', english: 'Tabako', japanese: '煙草' },
	CHI: { hiragana: 'ち', katakana: 'チ', english: 'Chidori', japanese: '千鳥' },
	TSU: {
		hiragana: 'つ',
		katakana: 'ツ',
		english: 'Tsurukame',
		japanese: 'つるかめ',
	},
	TE: { hiragana: 'て', katakana: 'テ', english: 'Tegami', japanese: '手紙' },
	TO: { hiragana: 'と', katakana: 'ト', english: 'Tōkyō', japanese: '東京' },
	NA: { hiragana: 'な', katakana: 'ナ', english: 'Nagoya', japanese: '名古屋' },
	NI: { hiragana: 'に', katakana: 'ニ', english: 'Nippon', japanese: '日本' },
	NU: { hiragana: 'ぬ', katakana: 'ヌ', english: 'Numazu', japanese: '沼津' },
	NE: { hiragana: 'ね', katakana: 'ネ', english: 'Nezumi', japanese: 'ねずみ' },
	NO: { hiragana: 'の', katakana: 'ノ', english: 'Nohara', japanese: '野原' },
	HA: { hiragana: 'は', katakana: 'ハ', english: 'Hagaki', japanese: 'はがき' },
	HI: { hiragana: 'ひ', katakana: 'ヒ', english: 'Hikōki', japanese: '飛行機' },
	FU: {
		hiragana: 'ふ',
		katakana: 'フ',
		english: 'Fujisan',
		japanese: '富士山',
	},
	HE: { hiragana: 'へ', katakana: 'ヘ', english: 'Heiwa', japanese: '平和' },
	HO: { hiragana: 'ほ', katakana: 'ホ', english: 'Hoken', japanese: '保険' },
	MA: { hiragana: 'ま', katakana: 'マ', english: 'Matchi', japanese: 'マッチ' },
	MI: { hiragana: 'み', katakana: 'ミ', english: 'Mikasa', japanese: '三笠' },
	MU: { hiragana: 'む', katakana: 'ム', english: 'Musen', japanese: '無線' },
	ME: { hiragana: 'め', katakana: 'メ', english: 'Meiji', japanese: '明治' },
	MO: { hiragana: 'も', katakana: 'モ', english: 'Momiji', japanese: 'もみじ' },
	YA: { hiragana: 'や', katakana: 'ヤ', english: 'Yamato', japanese: '大和' },
	YU: { hiragana: 'ゆ', katakana: 'ユ', english: 'Yumiya', japanese: '弓矢' },
	YO: { hiragana: 'よ', katakana: 'ヨ', english: 'Yoshino', japanese: '吉野' },
	RA: { hiragana: 'ら', katakana: 'ラ', english: 'Rajio', japanese: 'ラジオ' },
	RI: { hiragana: 'り', katakana: 'リ', english: 'Ringo', japanese: 'りんご' },
	RU: { hiragana: 'る', katakana: 'ル', english: 'Rusui', japanese: '留守居' },
	RE: { hiragana: 'れ', katakana: 'レ', english: 'Renge', japanese: 'れんげ' },
	RO: { hiragana: 'ろ', katakana: 'ロ', english: 'Rōma', japanese: 'ローマ' },
	WA: { hiragana: 'わ', katakana: 'ワ', english: 'Warabi', japanese: 'わらび' },
	WO: { hiragana: 'を', katakana: 'ヲ', english: 'Wowari', japanese: '尾張' },
} as const;

/**
 * Returns a ReadingEntry of the given browserId
 *
 * The key/value pairs of the returned object represent different ways to pronounce the browser key.
 *
 * @example
 * // returns {
 * //   english: 'Rajio Renge Chidori Suzume Chidori',
 * //   hiragana: 'ら れ ち す ち',
 * //   japanese: 'ラジオ れんげ 千鳥 すずめ 千鳥',
 * //   katakana: 'ラ レ チ ス チ'
 * // }
 * browserIdToReadingEntry("RA RE CHI ZU CHI");
 */
export const browserIdToReadingEntry = (browserId: BrowserId): ReadingEntry => {
	const browserIdTokens = browserId.split(' ') as Phoneme[];
	return {
		english: browserIdTokens
			.map((token) => RADIOTELEPHONY_BY_PHONEME[token].english)
			.join(' '),
		hiragana: browserIdTokens
			.map((token) => RADIOTELEPHONY_BY_PHONEME[token].hiragana)
			.join(' '),
		japanese: browserIdTokens
			.map((token) => RADIOTELEPHONY_BY_PHONEME[token].japanese)
			.join(' '),
		katakana: browserIdTokens
			.map((token) => RADIOTELEPHONY_BY_PHONEME[token].katakana)
			.join(' '),
	};
};

/**
 * Generates a random browserId
 *
 * The returned browserId is a space delimited string of phonemes,
 * which can be easily spoken over a phone.
 *
 * @example
 * // returns "RA RE CHI ZU CHI"
 * generateRandomBrowserId();
 */
export const generateRandomBrowserId = (): BrowserId => {
	const phonemes = Object.keys(RADIOTELEPHONY_BY_PHONEME) as Phoneme[];
	return Array.from(
		{ length: NUM_TOKENS_IN_BROWSER_ID },
		() => phonemes[random(0, phonemes.length)]
	).join(' ') as BrowserId;
};

export const getBrowserId = (): BrowserId => {
	const browserId: BrowserId = window.localStorage.getItem('browserId')
		? JSON.parse(window.localStorage.getItem('browserId') as string)
		: generateRandomBrowserId();
	if (!window.localStorage.getItem('browserId')) {
		window.localStorage.setItem('browserId', JSON.stringify(browserId));
	}
	return browserId;
};
