import Head from 'next/head';
import { FC } from 'react';

import {
	DESCRIPTION,
	DESCRIPTION_SOCIAL,
	KEYWORDS,
	TITLE,
	TITLE_SOCIAL,
	TWITTER_HANDLE,
} from '@/constants/marketing';

export const HeadAndMeta: FC = () => {
	return (
		<Head>
			<title>{TITLE}</title>
			<meta name="description" content={DESCRIPTION} />
			<meta key="keywords" property="keywords" content={KEYWORDS} />
			{/* Prevent ios to treat each number that looks like a phone number, as a call link */}
			<meta name="format-detection" content="telephone=no" />
			{/* Open Graph */}
			<meta
				key="og:description"
				property="og:description"
				content={DESCRIPTION_SOCIAL}
			/>
			<meta key="og:site_name" property="og:site_name" content={TITLE_SOCIAL} />
			<meta key="og:title" property="og:title" content={TITLE_SOCIAL} />
			<meta key="og:type" property="og:type" content="website" />
			<meta
				key="og:url"
				property="og:url"
				content="https://octopusenergy.co.jp"
			/>
			<meta
				key="og:image"
				property="og:image"
				content={`${process.env.NEXT_PUBLIC_CDN_HOST}/oejp/static/social-share.jpg`}
			/>
			{/* Twitter */}
			<meta
				key="twitter:card"
				name="twitter:card"
				content="summary_large_image"
			/>
			<meta key="twitter:title" name="twitter:title" content={TITLE_SOCIAL} />
			<meta
				key="twitter:description"
				name="twitter:description"
				content={DESCRIPTION_SOCIAL}
			/>
			<meta
				key="twitter:image"
				name="twitter:image"
				content={`${process.env.NEXT_PUBLIC_CDN_HOST}/oejp/static/social-share.jpg`}
			/>

			<meta
				key="twitter:site"
				name="twitter:site"
				content={`@${TWITTER_HANDLE}`}
			/>
			<meta
				key="twitter:creator"
				name="twitter:creator"
				content={`@${TWITTER_HANDLE}`}
			/>
			<meta
				name="facebook-domain-verification"
				content={process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION_ID}
			/>
		</Head>
	);
};
