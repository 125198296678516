import { FC, useEffect, useState } from 'react';

import {
	ParticleFall,
	ParticleFallProps,
} from '@/components/shared/animations/ParticleFall';

export const Snowfall: FC = () => {
	const [images, setImages] = useState<HTMLImageElement[]>();
	const particalProps: ParticleFallProps = {
		images,
		radius: [10, 20],
		count: 25,
	};

	useEffect(() => {
		const image = document.createElement('img');
		image.src = `/images/snowflake.png`;

		setImages([image]);
	}, []);

	return images ? <ParticleFall {...particalProps} /> : null;
};
