// adapted from https://github.com/cahilfoley/react-snowfall

import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';

import { lerp, randomElement } from '@/components/shared/animations/utils';
import { random } from '@/utils/random';

export interface ParticleProps {
	/**
	 * The frequency in frames that the wind and speed values
	 * will update.
	 *
	 * The default value is 200.
	 */
	changeFrequency: number;
	/** The color of the particle, can be any valid CSS color. */
	color: string;
	/**
	 * An array of images that will be rendered as the particles instead
	 * of the default circle shapes.
	 */
	images?: CanvasImageSource[];
	/**
	 * The minimum and maximum radius of the particle, will be
	 * randomly selected within this range.
	 *
	 * The default value is `[0.5, 3.0]`.
	 */
	radius: [number, number];
	/**
	 * The minimum and maximum rotation speed of the particle (in degrees of
	 * rotation per frame).
	 *
	 * The rotation speed determines how quickly the particle rotates when
	 * an image is being rendered.
	 *
	 * The values will be randomly selected within this range.
	 *
	 * The default value is `[-1.0, 1.0]`.
	 */
	rotationSpeed: [number, number];
	/**
	 * The minimum and maximum speed of the particle.
	 *
	 * The speed determines how quickly the particle moves
	 * along the y axis (vertical speed).
	 *
	 * The values will be randomly selected within this range.
	 *
	 * The default value is `[1.0, 3.0]`.
	 */
	speed: [number, number];
	/**
	 * The minimum and maximum wind of the particle.
	 *
	 * The wind determines how quickly the particle moves
	 * along the x axis (horizontal speed).
	 *
	 * The values will be randomly selected within this range.
	 *
	 * The default value is `[-0.5, 2.0]`.
	 */
	wind: [number, number];
}

export type ParticleConfig = Partial<ParticleProps>;

export const defaultConfig: ParticleProps = {
	color: '#dee4fd',
	radius: [0.5, 4.0],
	speed: [1.0, 3.0],
	wind: [-0.5, 2.0],
	changeFrequency: 200,
	rotationSpeed: [-1.0, 1.0],
};

interface ParticleParams {
	nextRotationSpeed: number;
	nextSpeed: number;
	nextWind: number;
	radius: number;
	rotation: number;
	rotationSpeed: number;
	speed: number;
	wind: number;
	x: number;
	y: number;
}

/**
 * An individual particle that will update its location every call to `update`
 * and draw itself to the canvas every call to `draw`.
 */
class Particle {
	static offscreenCanvases = new WeakMap<
		CanvasImageSource,
		Record<number, HTMLCanvasElement>
	>();

	private config!: ParticleProps;
	private params: ParticleParams;
	private framesSinceLastUpdate: number;
	private image?: CanvasImageSource;

	public constructor(canvas: HTMLCanvasElement, config: ParticleConfig = {}) {
		// Set custom config
		this.updateConfig(config);

		// Setting initial parameters
		const { radius, wind, speed, rotationSpeed } = this.config;

		this.params = {
			x: random(0, canvas.offsetWidth),
			y: random(-canvas.offsetHeight, 0),
			rotation: random(0, 360),
			radius: random(...radius),
			speed: random(...speed),
			wind: random(...wind),
			rotationSpeed: random(...rotationSpeed),
			nextSpeed: random(...wind),
			nextWind: random(...speed),
			nextRotationSpeed: random(...rotationSpeed),
		};

		this.framesSinceLastUpdate = 0;
	}

	private selectImage() {
		if (this.config.images && this.config.images.length > 0) {
			this.image = randomElement(this.config.images);
		} else {
			this.image = undefined;
		}
	}

	public updateConfig(config: ParticleConfig): void {
		const previousConfig = this.config;
		this.config = { ...defaultConfig, ...config };
		this.config.changeFrequency = random(
			this.config.changeFrequency,
			this.config.changeFrequency * 1.5
		);

		// Update the radius if the config has changed, it won't gradually update on it's own
		if (this.params && !isEqual(this.config.radius, previousConfig?.radius)) {
			this.params.radius = random(...this.config.radius);
		}

		if (!isEqual(this.config.images, previousConfig?.images)) {
			this.selectImage();
		}
	}

	private updateTargetParams(): void {
		this.params.nextSpeed = random(...this.config.speed);
		this.params.nextWind = random(...this.config.wind);
		if (this.image) {
			this.params.nextRotationSpeed = random(...this.config.rotationSpeed);
		}
	}

	public update(canvas: HTMLCanvasElement, framesPassed = 1): void {
		const {
			x,
			y,
			rotation,
			rotationSpeed,
			nextRotationSpeed,
			wind,
			speed,
			nextWind,
			nextSpeed,
			radius,
		} = this.params;

		// Update current location, wrapping around if going off the canvas
		this.params.x =
			(x + wind * framesPassed) % (canvas.offsetWidth + radius * 2);
		if (this.params.x > canvas.offsetWidth + radius) {
			this.params.x = -radius;
		}
		this.params.y =
			(y + speed * framesPassed) % (canvas.offsetHeight + radius * 2);
		if (this.params.y > canvas.offsetHeight + radius) {
			this.params.y = -radius;
		}

		// Apply rotation
		if (this.image) {
			this.params.rotation = (rotation + rotationSpeed) % 360;
		}

		// Update the wind, speed and rotation towards the desired values
		this.params.speed = lerp(speed, nextSpeed, 0.01);
		this.params.wind = lerp(wind, nextWind, 0.01);
		this.params.rotationSpeed = lerp(rotationSpeed, nextRotationSpeed, 0.01);

		if (this.framesSinceLastUpdate++ > this.config.changeFrequency) {
			this.updateTargetParams();
			this.framesSinceLastUpdate = 0;
		}
	}

	private getImageOffscreenCanvas(
		image: CanvasImageSource,
		size: number
	): CanvasImageSource {
		if (image instanceof HTMLImageElement && image.loading) {
			return image;
		}
		let sizes = Particle.offscreenCanvases.get(image);

		if (!sizes) {
			sizes = {};
			Particle.offscreenCanvases.set(image, sizes);
		}

		if (!(size in sizes)) {
			const canvas = document.createElement('canvas');
			canvas.width = size;
			canvas.height = size;
			canvas.getContext('2d')?.drawImage(image, 0, 0, size, size);
			sizes[size] = canvas;
		}

		return sizes[size] ?? image;
	}

	public draw(ctx: CanvasRenderingContext2D): void {
		if (this.image) {
			// ctx.save()
			// ctx.translate(this.params.x, this.params.y)
			ctx.setTransform(1, 0, 0, 1, this.params.x, this.params.y);

			const radius = Math.ceil(this.params.radius);
			ctx.rotate((this.params.rotation * Math.PI) / 180);
			ctx.drawImage(
				this.getImageOffscreenCanvas(this.image, radius),
				-Math.ceil(radius / 2),
				-Math.ceil(radius / 2),
				radius,
				radius
			);

			// ctx.restore()
		} else {
			ctx.beginPath();
			ctx.arc(this.params.x, this.params.y, this.params.radius, 0, 2 * Math.PI);
			ctx.fillStyle = this.config.color;
			ctx.closePath();
			ctx.fill();
		}
	}
}

/**
 * A utility function to create a collection of particles
 * @param canvasRef A ref to the canvas element
 * @param amount The number of particles
 * @param config The configuration for each particle
 */
const createParticles = (
	canvasRef: React.RefObject<HTMLCanvasElement>,
	amount: number,
	config: ParticleConfig
): Particle[] => {
	if (!canvasRef.current) {
		return [];
	}

	const particles: Particle[] = [];

	for (let i = 0; i < amount; i++) {
		particles.push(new Particle(canvasRef.current, config));
	}

	return particles;
};

/**
 * A utility hook to manage creating and updating a collection of particles
 * @param canvasRef A ref to the canvas element
 * @param amount The number of particles
 * @param config The configuration for each particle
 */
export const useParticles = (
	canvasRef: React.RefObject<HTMLCanvasElement>,
	amount: number,
	config: ParticleConfig
): Particle[] => {
	const [particles, setParticles] = useState<Particle[]>([]);

	// Handle change of amount
	useEffect(() => {
		setParticles((particles: Particle[]) => {
			const sizeDifference = amount - particles.length;

			if (sizeDifference > 0) {
				return [
					...particles,
					...createParticles(canvasRef, sizeDifference, config),
				];
			}

			if (sizeDifference < 0) {
				return particles.slice(0, amount);
			}

			return particles;
		});
	}, [amount, canvasRef, config]);

	// Handle change of config
	useEffect(() => {
		setParticles((particles: Particle[]) =>
			particles.map((particle) => {
				particle.updateConfig(config);
				return particle;
			})
		);
	}, [config]);

	return particles;
};
