import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import {
	NODE_LI,
	NODE_OL,
	NODE_UL,
	render,
} from 'storyblok-rich-text-react-renderer';

import styles from '@/components/storyblok/Index.module.css';
import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
	RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
	textAlignMap,
} from '@/components/storyblok/utils/constants';

type RichTextCustomListsProps = {
	blok: {
		background: 'primary' | 'secondary';
		list_style: 'blue' | 'white';
		no_padding?: boolean;
		rich_text: unknown;
		text_align: 'left' | 'right' | 'center';
	};
	props: any;
};

export const RichTextCustomLists: FC<RichTextCustomListsProps> = ({
	blok: { background, list_style, rich_text, text_align, no_padding = false },
	props,
}) => {
	return (
		<div
			className={clsx('w-full', textAlignMap[text_align], {
				'py-3 px-4': !no_padding,
				'bg-primary': background === 'primary',
				'bg-secondary': background === 'secondary',
			})}
		>
			<section className="mx-auto max-w-3xl break-words text-lg leading-10">
				{render(rich_text, {
					...RICH_TEXT_CONSTANTS(props),
					nodeResolvers: {
						...RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS(text_align),
						...RICH_TEXT_NODE_RESOLVERS_HEADING,
						// @ts-expect-error because children is typed as ReactNode but we need ReactNode[]
						[NODE_UL]: (children: ReactNode[] | null) => (
							<ul className="my-4 list-none pl-8">
								{children?.map((child, idx) => (
									<li
										className={clsx(
											'flex list-none items-start pl-8 [&>*]:m-0 [&>*]:mb-[30px]',
											styles[`octopus-${list_style}`]
										)}
										key={`${child}-${idx}`}
									>
										{child}
									</li>
								))}
							</ul>
						),
						// @ts-expect-error because children is typed as ReactNode but we need ReactNode[]
						[NODE_OL]: (children: ReactNode[] | null) => (
							<ol
								className={clsx(
									'my-4 flex list-none flex-col items-start pl-16 [&>li>*]:m-0',
									styles['ordered-list-pink']
								)}
							>
								{children?.map((child, idx) => (
									<li className="mb-12 pl-12" key={`${child}-${idx}`}>
										{child}
									</li>
								))}
							</ol>
						),
						// Removes unstyleable <li> element preventing Storyblok from generating automatically nested <li>s.
						// @ts-expect-error because signature is [NODE_LI]?: (children: ReactNode) => JSX.Element | null; but we return a ReactNode
						[NODE_LI]: (children: ReactNode) => children,
					},
				})}
			</section>
		</div>
	);
};
