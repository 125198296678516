var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6a2bf36273ca4f3938a3149ab3ee50e797fe3beb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable no-restricted-imports */

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { PROJECT_ID } from './sentry.common.config';
import { scrubPII } from './src/constants/logging';
import { env, service } from './src/services/datadog';
import { getBrowserId } from './src/utils/browserId';

const browserId = getBrowserId();

Sentry.init({
	beforeSend(event) {
		event.tags = {
			...event.tags,
			correlatedDatadogLogs: `https://app.datadoghq.eu/logs?${new URLSearchParams(
				{ query: `service:${service} @browserId:"${browserId}" env:${env}` }
			).toString()}`,
			correlatedSentryLogs: `https://kraken-tech.sentry.io/issues/?${new URLSearchParams(
				{ project: PROJECT_ID, query: `browser_id:"${browserId}"` }
			)}`,
		};
		if (event.extra) {
			try {
				event.extra = { data: scrubPII(event.extra) };
			} catch {
				// no/op
			}
		}
		return event;
	},
	denyUrls: [
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
	],
	dsn:
		process.env.SENTRY_DSN ||
		process.env.NEXT_PUBLIC_SENTRY_DSN ||
		`https://f094d44ccf4f4089a76a165ca2b7e06f@o28796.ingest.sentry.io/${PROJECT_ID}`,
	ignoreErrors: [
		// Ignore third party (google analytics) errors
		// i.e: "Can't find variable: al_onPoststitialDismiss"
		'al_',
		// i.e: "Can't find variable: MyApp_RemoveAllHighlights"
		'MyApp_',
		// i.e: "Can't find variable: WeixinJSBridge"
		'WeixinJSBridge',
		// Backend returns an error if user updated their details too recently, but it is not an error for the client, just context to the user
		'It looks like you want to transfer your account to another user or add a new user to your account. Please contact us to arrange this.',
		// harmless warnign coming from Edge on iOs https://stackoverflow.com/a/69576781
		'instantSearchSDKJSBridgeClearHighlight',
		// i.e: "Can't find variable: removeAllHighlightsForSB"
		'removeAllHighlightsForSB',
		// Recruiting console log
		'Hello there, we have open developer roles',
		// Script tools like tampermonkey use this https://github.com/Tampermonkey/tampermonkey/issues/412
		// it's not coming from our app and don't harm our users
		'unsafeWindow',
		// iOS browser specific error that can be ignored
		// https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
		'__gCrWeb',
	],
	integrations: [new Sentry.Replay({ maskAllText: true, blockAllMedia: true })],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	maxValueLength: 1000,
});

try {
	Sentry.setTag('browser_id', browserId);
} catch (error) {
	/** Do nothing. Just don't throw to the browser. */
}
