import { FC } from 'react';

import { LINELogo } from '@/components/svgs/LINELogo';

export const ReferralLineShareIcon: FC<{
	shareLink: string;
}> = ({ shareLink }) => (
	<>
		{/* https://developers.line.biz/ja/docs/line-social-plugins/install-guide/using-line-share-buttons/#using-custom-icons */}
		<a
			href={`https://social-plugins.line.me/lineit/share?url=${shareLink}`}
			target="_blank"
			aria-label="LINEでシェアする" // Share to LINE
			rel="noreferrer noopener"
		>
			<LINELogo className="max-w-full" />
		</a>
	</>
);
