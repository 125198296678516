import { isSameDay } from 'date-fns';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { AutumnLeaves } from '@/components/shared/animations/AutumnLeaves';
import { Sakura } from '@/components/shared/animations/Sakura';
import { Snowfall } from '@/components/shared/animations/Snowfall';

type SeasonalDateName =
	| 'MARCH_EQUINOX'
	| 'JUNE_SOLSTICE'
	| 'SEPTEMBER_EQUINOX'
	| 'DECEMBER_SOLSTICE';

// https://ja.wikipedia.org/wiki/春分の日
// https://ja.wikipedia.org/wiki/夏至
// https://ja.wikipedia.org/wiki/冬至
export const datesBySeasonalDateName: Record<SeasonalDateName, string[]> = {
	MARCH_EQUINOX: [
		'2024-03-18',
		'2024-03-19',
		'2024-03-20',
		'2024-03-21',
		'2024-03-22',
		'2024-03-23',
		'2024-03-24',
		'2024-03-25',
		'2025-03-20',
		'2026-03-20',
		'2027-03-21',
		'2028-03-20',
		'2029-03-20',
		'2030-03-20',
		'2031-03-21',
		'2032-03-20',
		'2033-03-20',
		'2034-03-20',
		'2035-03-21',
		'2036-03-20',
	],
	JUNE_SOLSTICE: [
		'2022-06-21',
		'2023-06-21',
		'2024-06-21',
		'2025-06-21',
		'2026-06-21',
		'2027-06-21',
		'2028-06-21',
		'2029-06-21',
		'2030-06-21',
		'2031-06-21',
		'2032-06-21',
		'2033-06-21',
		'2034-06-21',
		'2035-06-21',
		'2036-06-21',
	],
	SEPTEMBER_EQUINOX: [
		'2022-09-23',
		// 2023
		'2023-09-23',
		'2023-09-24',
		'2023-09-25',
		'2023-09-26',
		'2023-09-27',
		'2023-09-28',
		// '2023-09-29',

		'2024-09-22',
		'2025-09-23',
		'2026-09-23',
		'2027-09-23',
		'2028-09-22',
		'2029-09-23',
		'2030-09-23',
		'2031-09-23',
		'2032-09-22',
		'2033-09-23',
		'2034-09-23',
		'2035-09-23',
		'2036-09-22',
	],
	DECEMBER_SOLSTICE: [
		'2022-12-22',
		'2023-12-22',
		'2024-12-21',
		'2025-12-22',
		'2026-12-22',
		'2027-12-22',
		'2028-12-21',
		'2029-12-21',
		'2030-12-22',
		'2031-12-22',
		'2032-12-21',
		'2033-12-21',
		'2034-12-22',
		'2035-12-22',
		'2036-12-21',
	],
};

const ComponentBySeasonalDateName: Record<SeasonalDateName, FC> = {
	MARCH_EQUINOX: Sakura,
	JUNE_SOLSTICE: () => null,
	SEPTEMBER_EQUINOX: AutumnLeaves,
	DECEMBER_SOLSTICE: Snowfall,
};

const PATHS_TO_INCLUDE_FOR_ANIMATION = ['/'];

export const SeasonalAnimation: FC<{
	dateString: string;
}> = ({ dateString }) => {
	const router = useRouter();

	const targetDate = new Date(dateString);

	const isVisible = PATHS_TO_INCLUDE_FOR_ANIMATION.includes(router.pathname);

	const targetSeasonalDateName = Object.entries(datesBySeasonalDateName).find(
		([, dateStrings]) =>
			dateStrings.some((seasonalDateDateString) =>
				isSameDay(targetDate, new Date(seasonalDateDateString))
			)
	)?.[0] as SeasonalDateName;

	const ComponentToRender = targetSeasonalDateName
		? ComponentBySeasonalDateName[targetSeasonalDateName]
		: () => null;

	return isVisible ? <ComponentToRender /> : null;
};
