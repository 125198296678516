import { FC } from 'react';

export type AnchorProps = {
	blok: {
		id: string;
	};
};

const Anchor: FC<AnchorProps> = ({ blok }) => (
	// This anchor is _just_ for the purpose of custom URLs with #id links
	// @todo: meet this eslint rule - possibly by allowing nested content in the anchor
	// eslint-disable-next-line jsx-a11y/anchor-has-content
	<a className="relative -top-12 md:-top-24" aria-hidden={true} id={blok.id} />
);

export { Anchor };
