import { FC } from 'react';

type ConstantineShapeProps = {
	fill?: string;
	height?: number;
	width?: number;
};

const ConstantineShape: FC<ConstantineShapeProps> = ({
	fill = '#F0FFFF',
	height = 30,
	width = 26,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="constantine-shape-icon"
		>
			<title id="constantine-shape-icon" lang="ja">
				タコのかたち {/* shape of an octopus */}
			</title>
			<path
				d="M22.3739 20.6864C22.7422 18.8923 25.3201 16.3326 25.6432 12.814C26.0641 8.19722 23.7944 0 12.7164 0C1.6384 0 0 8.46788 0 12.002C0 16.727 3.37451 19.1707 3.21668 20.8488C3.23171 20.8565 3.24674 20.872 3.26177 20.8797C3.34444 22.697 1.48809 22.8053 1.59331 23.9266C1.67598 24.8005 4.08097 25.017 5.74192 24.089C5.47887 24.6071 5.11061 25.0788 4.65216 25.3418C3.79538 25.829 3.10394 26.0687 3.10394 26.61C3.10394 27.1514 5.67428 28.5279 8.31226 27.19C9.09388 26.7956 9.65755 26.3703 10.0859 25.9527C10.101 26.0455 10.1085 26.1306 10.1235 26.2079C10.2513 26.9812 10.6872 29.1388 12.5886 29.1388C14.4901 29.1388 14.8734 27.2519 15.189 26.1306C15.2116 26.0378 15.2416 25.945 15.2642 25.8522C15.6926 26.3084 16.2938 26.7724 17.1431 27.2055C19.7811 28.5433 22.3514 27.1668 22.3514 26.6255C22.3514 26.0842 21.66 25.8522 20.8032 25.3572C20.3147 25.0788 19.9314 24.5762 19.6683 24.0194C21.3293 25.0556 23.8846 24.8546 23.9673 23.9498C24.0725 22.8053 22.1259 22.7202 22.3063 20.7714C22.3213 20.725 22.3439 20.7096 22.3739 20.6864Z"
				fill={fill}
			/>
		</svg>
	);
};

export { ConstantineShape };
