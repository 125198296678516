import { FC } from 'react';

interface Props {
	blok: {
		table: {
			tbody: {
				body: { value: string }[];
			}[];
			thead: {
				value: string;
			}[];
		};
	};
}

export const Table: FC<Props> = ({ blok }) => {
	return (
		<div className="">
			<table className="w-full">
				<thead>
					<tr>
						{blok.table.thead?.map((row: { value: string }) => (
							<th key={row.value}>{row.value}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{blok.table.tbody?.map(
						(body: { body: { value: string }[] }, bodyIndex: number) => (
							<tr className="grid grid-flow-col" key={`row-${bodyIndex}`}>
								{body.body.map((row) => (
									<td className="border p-4" key={row.value}>
										{row.value}
									</td>
								))}
							</tr>
						)
					)}
				</tbody>
			</table>
		</div>
	);
};
