export const copy = {
	next: '次へ',
	next_Payment: 'NEXT：お支払い方法',
	next_ApplicationCompletion: 'NEXT：申し込み内容の確認',
	back: '👈 戻る',
	close: '閉じる',
	saving: '保存中...',
	save: '保存',
	register: '登録',
	amount: '金額',
	description: '特記事項',
	pending: '保留中',
	morning: '午前',
	afternoon: '午後',
	/** Credit card */
	creditCard: 'クレジットカード',
	/** Current Retailer */
	currentRetailer: '現在ご契約中の電力会社',
	/** Power company to which power is currently sold */
	currentGenerationPurchasor: '現在の売電先の電力会社',
	/** Please select the company you currently have a contract with */
	pleaseProvideYourCurrentRetailer: 'ご契約中の電力会社を選択してください',
	/** Current Supplier Contract Number */
	currentSupplierContractNumber: 'ご契約中の電力会社のお客さま番号',
	/** Current Contract Number (Generation) */
	currentGenerationContractNumber: '現在の売電先のお客さま番号',
	/** Mail list */
	messageList: 'メッセージ一覧',
	/** Password */
	password: 'パスワード',
	/** Postcode */
	postcode: '郵便番号',
	/** District, street number, room number, etc. */
	addressLine1: '丁目、番地、号など',
	/** Building name, room number, etc. */
	addressLine2: '建物名、部屋番号など',
	/** Please tell me the current contracted capacity of the place of application. */
	whatIsYourContractCapacity:
		'お申し込み場所の現在の契約容量等を教えてください。',
	/** Contract curent (amps) */
	currentContractAmps: '契約電流（アンペア）',
	/** Contract capacity (kVA) */
	contractCapacitykVA: '契約容量（kVA）',
	/** I don't know the amperage (choose the most common, 40A) */
	iDontKnowMyAmperage:
		'アンペア数がわからない（最も一般的とされる40Aを適用します）',
	/** I don't know the kVA (choose the most common, 6kVA) */
	iDontKnowMyKVA:
		'契約値がわからない（標準的なご家庭のお客さまに多い6kVA未満を適用します）',
	/** Full Name */
	fullName: 'お名前',
	/** Contract holder's name */
	contractHoldersName: 'お名前',
	/** Last Name */
	lastName: '姓',
	/** First Name */
	firstName: '名',
	/** Last Name (Kana) */
	lastNameKana: 'セイ',
	/** First Name (Kana) */
	firstNameKana: 'メイ',
	/** Mobile number */
	mobileNumber: '携帯番号',
	/** Bank */
	bank: '銀行',
	/** Bank account holder name */
	bankAccountHolderName: '口座名義（カナ）',
	/** Bank account number */
	bankAccountNumber: '口座番号',
	/** Bank code */
	bankCode: '統一金融機関コード',
	/** Bank code */
	pleaseSelectABank: '銀行を選択してください',
	/** Japan Post bank book number */
	bankBookNumber: '記号番号',
	/** Branch code */
	branchCode: '支店番号',
	/** Bank account type */
	bankAccountType: '口座種別',
	/** Ordinary Type Bank Account */
	bankAccountTypeOrdinary: '普通',
	/** Current Type Bank Account */
	bankAccountTypeCurrent: '当座',
	/** Saving Type Bank Account */
	bankAccountTypeSaving: '貯蓄',
	/** Please close the browser window. */
	pleaseCloseTheWindow: 'ウィンドウを閉じてください。',
	/** Card number */
	creditCardNumber: 'カード番号',
	/** Card expiry date */
	cardExpiryDate: '有効期限 月/年',
	/* Confirm payment number and payment method */
	stripeConbiniLinkText: '支払い番号、支払い方法を確認する',
	/** Security code */
	securityCode: 'セキュリティコード',
	/** Use home address as billing address */
	useHomeAddressAsBillingAddress: '請求先住所は上記住所と同じです。',
	/** Error Messages */
	/** Sorry, we could not find your postal area. */
	weCouldNotFindYourPostalArea:
		'申し訳ありませんが、サービス対象エリア内にあなたの郵便番号がみかりません',
	/** Sorry, we do not support your postal area. */
	weDoNotSupportYourPostalArea:
		'申し訳ありませんが、現在あなたの郵便番号はサービス対象エリア外です',
	/** This postal area is invalid. */
	thisPostcodeIsInvalid: 'この郵便番号は無効です。',
	tryAgain: 'もう一度試す',
	/** Oops, looks like something went wrong. Try again later. */
	somethingWentWrong:
		'おっと、何か問題があるようです。少し時間をおいてから、またアクセスしてみてくださいね。',
	/** Oops */
	oops: 'おっと',
	/** Looks like something went wrong */
	looksLikeSomethingWentWrong: '何か問題があるようです',
	/** Please try again later */
	looksLikeSomethingWentWrongTryAgainLater:
		'私たちの方で何かが壊れているようです。少し時間をおいてから、またアクセスしてみてくださいね。何度かこの現象が発生した場合は、メールでお知らせください。',
	/** Email us */
	emailUs: 'メールでご連絡',
	/* The URL you used is invalid. */
	invalidUrl: 'このURLは有効期限切れです。',
	/* After 15 seconds, you will be automatically redirected to a regular URL with no benefits.
	If the automatic transition does not occur, we apologize for the inconvenience, but please proceed from the button below. */
	invalidAffiliateLink:
		'15秒後に特典付与のない通常ページに移動します。ページが切替わらない場合は以下のボタンからお進みください。',
	loading: 'ローディングしています',
	/** yes */
	yes: 'はい',
	/** no */
	no: 'いいえ',
	/** Monthly usage */
	monthlyUsage: '月あたりのご使用量（kWh）',
	/** Select a month */
	pleaseSelectAMonth: '月を選択してください',
	/** Select an amperage */
	pleaseSelectAnAmperage: 'アンペアを選択してください',
	/** Select a kVA */
	pleaseSelectAKVA: 'kVAを選択してください',
	/** This page could not be found */
	pageNotFound: 'ページが見つかりません。',
	/** Go back to the home page */
	goBackToHomePage: '〈 ホームページに戻る',
	/** The unit price shown is tentative. The final unit price will be displayed after the information is entered. */
	priceIsTentative:
		'表示されている単価は仮のものです。最終的な単価は情報入力後に表示されます。',
	/** Step headings */
	/** First, check the plan and price that suit your residence */
	estimationStepHeading: 'まずは、お住まいに合うプランと価格をチェック！',
	estimationStepHeadingMimo: 'まず、お引越し先の料金プランを算出します',
	/** Application */
	personalDetailsHeading: 'カンタンお申し込み',
	/** Select your product */
	productSelectionHeading: 'ご希望の料金プランをお選びください',
	/** Please confirm the details of the price plan, and if you are satisfied, please proceed to the application from the pink button. */
	productSelectionHeadingSingleProduct:
		'料金プランの内容をご確認いただき、よろしければ、ピンクのボタンからお申し込みへお進み下さい',
	/** Almost done! */
	finalStepHeading: 'お申し込み内容をご確認ください',
	/** Customer Information */
	customerInformation: 'お客さま情報',
	/** Payment "way" or "type" */
	paymentDetailsHeading: 'お支払い方法をご登録ください',
	/** Make a payment */
	makePayment: '支払いを行う',
	/** Direct Debit */
	directDebit: '口座振替',
	/** Transfer */
	bankTransfer: '銀行振込',
	/** Apply with this content */
	applyWithThisContent: 'この内容で申し込む',
	applyWithThisContent2: 'この内容で申請', // for moving out page
	/** To promote paperless offices, we will send important documents and various information to the following e-mail address. */
	toPromotePaperlessOffices:
		'ペーパーレス促進のため、重要書類の交付・各種ご案内は下記Eメールアドレスへお送りいたします。',
	/** Please enter the electricity usage start date.*/
	enterMoveInDate: '電気の供給開始日',
	/** Supply Point Identification Number */
	SPIN: '供給地点特定番号 (22桁ハイフンなし)',
	/** Generation Point Identification Number */
	GPIN: '受電地点特定番号 (22桁ハイフンなし)',
	supplyPointIdentificationNumber: '供給地点特定番号',
	calculatingMoveInDate: '計算中...',
	weHaveYourSpinAndMoveInDateIsEarlierLabel:
		'供給地点特定番号をお調べしたところ、以降のご入居でも受付可能です！',
	/** Please re-enter a valid supply point identification number. If the problem persists, please contact us. */
	errorInvalidGPIN:
		'有効な受電地点特定番号を再度入力してください。問題が解消しない場合はお問い合わせください。',
	/** Sorry, Please confirm your GPIN number again. */
	errorOffSupplyGPIN:
		'申し訳ございません、もう一度受電地点特定番号をご確認ください。',
	/** Please re-enter a valid supply point identification number. If the problem persists, please contact us. */
	errorInvalidSPIN:
		'有効な供給地点特定番号を再度入力してください。問題が解消しない場合はお問い合わせください。',
	/** Sorry, we don’t supply your type of meter at the moment. */
	errorCanNotSupplySPIN:
		'申し訳ございません、当該タイプの契約には現在対応しておりません。',
	/** Sorry, Please confirm your SPIN number again. */
	errorOffSupplySPIN:
		'申し訳ございません、もう一度供給地点特定番号をご確認ください。',
	/** We are sorry, but this area is currently out of the transmission and distribution area.*/
	errorSPINAreaNotSupported:
		'申し訳ありません、現在、こちらの地域は送配電地域外となっております。',
	/** Sorry, we do not currently support high voltage meters. */
	errorHighVoltageSPINNotSupported:
		'申し訳ありませんが、現在、高圧および特別高圧のため、ご契約いただけません。',
	/** There was an error processing your postcode. */
	errorProcessingPostcode: '郵便番号の処理中にエラーが発生しました。',
	/** Send a copy to your inbox */
	sendACopyToYourInbox: 'お見積結果をメールで送る',
	/** Apply Now */
	applyNow: '今すぐ申し込む',
	/** Sending */
	sendingQuote: '送信中。。。',
	/** Email it to yourself or someone else, and get back to your quote at any time. */
	youCanCheckYourQuoteAnytime:
		'お見積結果をメールで自分や誰かにシェアしておけば、いつでもこのページに戻れます。',
	/** You are now logged in as {{email}} */
	youAreMasqueradingAs: (email: string): string =>
		`あなたは現在 ${email} としてログイン中です`,
	/** You are now logged in as a customer */
	youAreMasqueradingAsAUser: 'あなたは現在お客様としてログイン中です',
	/** Yes, send me my quote */
	yesSendMeMyQuote: '送信する',
	/** No thanks, just show me my quote */
	noThanksShowMeMyQuote: 'いいえ、結構です',
	/** Calculating quote */
	calculatingQuote: 'ただ今、電気料金を計算中...',
	/** Handling of Personal Information */
	handlingOfPersonalInformation: '個人情報の取り扱いについて',
	/** About using this site */
	aboutUsingThisSite: 'サイトのご利用について',
	/** Consent to the personal information handling policy */
	agreeToPrivacyPolicyLabel: '個人情報の取り扱いの同意',
	/** Registered Information */
	registeredInformation: 'ご登録情報',
	contractorInformation: 'ご契約者情報',
	/** Change payment method */
	changePaymentMethod: 'お支払い方法の変更',
	/** Electricity Usage */
	electricityUsage: '電気使用量',
	/** Usage History */
	usageHistory: 'ご利用履歴',
	/** Wallet History */
	walletHistory: '請求履歴',
	/** Customer information successfully updated. */
	customerInformationSuccessfullyUpdated: 'お客さま情報の変更が完了しました。',
	/** Register with bank transfer */
	registerWithBankTransfer: '銀行振込のご登録',
	/** Register with direct debit */
	registerWithDirectDebit: '口座振替のご登録',
	/** Register with credit card */
	registerWithCreditCard: 'クレジットカードのご登録',
	/** Register with convenience store */
	registerWithConvenienceStore: 'コンビニのご登録',
	/** The registration of the account information for the refund has been completed */
	refundDetailsSuccessfullyRegistered: '返金用口座情報の登録が完了しました',
	/** There was an error processing the request. */
	thereWasAnErrorProcessingTheRequest: '要求の処理でエラーがありました。',
	/** Choose this one */
	chooseThisOne: 'お申し込み手続きへ',
	/** How are my monthly costs calculated? */
	howAreMyMonthlyCostsCalculated: '見積額に含まれているものは？',
	/** Estimated usage may include our estimates. In addition, the quoted price includes the fuel cost adjustment amount, renewable energy power generation promotion levy, and 10% consumption tax for the month in which the quote is made. Various discounts are not included. Actual electricity charges will vary depending on the amount of electricity used by the customer and fuel cost adjustments. */
	monthlyCostCalculationExplanation:
		'想定使用量には当社の推定を含む場合があります。また、お見積り料金には、お見積りを行った月の燃料費調整額、再生可能エネルギー発電促進賦課金、および消費税10%が含まれています。各種割引は含まれておりません。実際の電気料金は、お客さまの電気使用量や燃料費調整額等によって変動します。',
	/** This fee applies to all electricity used in the contracted residence, in addition to the electricity used for EV charging. */
	evOctopusMonthlyCostCalculationExplanationAddition:
		'本料金は、EV充電での電気使用以外にも、ご契約されるお住まいでご使用される全ての電気に適用されます。',
	/** Quote for purchasing energy is calculated based on our original self-consumption rate assumption. */
	fitGenerationPurchaseCalculationExplanationAddition:
		'買取料金は、当社独自の想定自家消費率を用いてお見積りしています。',
	/** More details */
	moreDetails: 'もっと詳しく',
	/** Your address will be displayed after the process has been completed*/
	addressWillBeDisplayedLater:
		'ご住所情報が反映されるまで今しばらくお待ちください',
	verifying: '検証中。。。',
	/** Your contract is currently being processed. */
	contractBeingProcessed: 'お客様のご契約は、現在お手続き中です。',
	emailWhenComplete:
		'全ての確認と電力会社切替え処理が完了次第、メールでお知らせします',
	/** Confirming contract information */
	confirmingContractInformation: '契約情報確認中',
	/** Contract in progress */
	contractInProgress: '契約手続中',
	/** Switching in progress */
	switchingInProgress: '切替え手続き中',
	/** Moving */
	moving: 'お引越手続中',
	/** "On Supply" */
	onSupply: '電力供給中',
	/** Canceled */
	canceled: 'ご解約済み',
	/** Cancelling in progress */
	cancelling: 'ご解約手続き中',
	/** Move in is in progress */
	movingInProgress: '引越し手続き中',
	/** "On Generation" */
	onGeneration: '電力売電中',
	/** Forecasted supply start date */
	forcastedSupplyStartDate: 'ご契約開始予定日',
	/** The expected date may vary depending on the status of your application. */
	startDateMayVary: '※手続状況により予定日は前後します',
	sorry: 'ごめんなさい。',
	/** We think that you may have more than one meterpoint at your property. Currently, we're only able to supply one meterpoint for each customer. We're sorry about this! */
	multiSpinDetected:
		'申し訳ございませんが、お客さまのお住まいには2つ以上の電力メーターが設置されており、お選びいただいた料金メニューのお申し込みを受け付けることができません。ご不明点ございましたらカスタマーサポートまでお問い合わせ下さい。',
	/** If the other supply point identification number (electric meter) is low-voltage power (using power), you cannot make a contract. */
	cantSupplyLowVoltage:
		'もう一つの供給地点特定番号（電気メーター）が低圧電力（動力をご利用）の場合、ご契約いただけません。',
	/** Career Information */
	careerInformation: '採用情報',
	/** If you want to proceed with the procedure only for the supply point identification number (electric meter) that you entered this time, please proceed to the next step as it is. */
	continueWithMeterpoint:
		'今回ご入力いただいた供給地点特定番号（電気メーター）のみ、お手続きを進めてよろしければ、このまま次のステップにお進み下さい。',
	/** Contact Us */
	contactUs: 'お問い合わせ',
	/** Try inputting again */
	inputAgain: '再度入力する',
	/** We cant service this postal area currently */
	cantServicePostalArea: '現在サービスエリア対象外となります',
	/** Sign up for email notifications */
	signUpForEmailNotifications: 'お知らせメールにご登録下さい',
	/** Service areas are being expanded one by one! We will send you information when it becomes available in your area. Please take this opportunity to register your email address. */
	serviceAreasAreExpanding:
		'順次エリアを拡大中です！お住まいのエリアでご利用可能となりましたらご案内をお送りさせていただきます。ぜひこの機会にメールアドレスをご登録ください😊',
	/** Thank you for registering your email address! */
	thanksRegisteringEmail:
		'メールアドレスをご登録いただきありがとうございました！',
	/** We will send you information when it becomes available in your area. */
	emailWhenAvailable:
		'お住まいのエリアでご利用可能となりましたらご案内をお送りさせていただきます。',
	/** Setup */
	setup: '設定をする',
	/** Specified Commercial Transactions Law */
	specifiedCommercialTransactionLaw: '特定商取引法に基づく表示',
	/** Link Kraken account */
	integrateAccount: 'アカウント連携する',
	/** Unlink Kraken account */
	unIntegrateAccount: 'アカウント連携を外す',
	/** Return to MyPage */
	returnToMyPage: 'マイページに戻ります。',
	/** Redirecting you... */
	redirecting: 'リダイレクト中です。',
	/** Your account is already linked to LINE. */
	accountAlreadyLinkedToLINE:
		'こちらのアカウントは既にLINEアカウントと紐づけられています。',
	/** That url doesn't look correct. Please try again. */
	urlDoesntLookCorrectPleaseTryAgain:
		'URLが正しくありません。お確かめの上、もう一度お試しください。',
	welcomeToOctopus: 'オクトパスにようこそ！',
	/** Your application is complete! You will shortly receive a welcome email containing your application and terms and conditions. */
	completeProcessAndSendWelcomeEmail:
		'お申し込み完了！まもなく、お申し込み内容や約款類を記載したウェルカムメールをお届けします。',
	optional: '任意',
	/** To update your password, please confirm your old password then enter your new password twice so we can verify you typed it in correctly.*/
	toUpdateYourOldPasswordPleaseConfirmYourOldPassword:
		'現在のパスワードを入力後、新しいパスワードをご入力ください',
	passwordUpdated: 'パスワードを変更しました。',
	goBackToMyPage: 'マイページに戻る',
	/** Set your My Page password! */
	setYourMyPagepassword: 'マイページのパスワードを設定しましょう！',
	/** "My Page" is a very convenient page that allows you to check the progress of your switchover procedure, check your bill, update your registration information, and perform various other procedures online anytime you like. */
	explanationOfWhyMypageisUseful:
		'「マイページ」は、切替え手続きの進捗や請求書の確認、登録情報の更新、その他さまざまな手続きをオンラインでいつでも好きな時に行える、とっても便利なページです。',
	/** Save password */
	savePassword: 'パスワードを保存する',
	/** Passwords you should avoid*/
	passwordsYouShouldAvoid: '避けて頂きたいパスワード',
	/** Passwords of 6 Charactres or less */
	passwordsOf6CharactersOrLess: '8文字未満のパスワード',
	/** Passwords using simple words such as "password" or "football" */
	exampleOfEasyPasswords:
		'"password" や "football" などの簡単な言葉を使ったパスワード',
	/** Password based on your name or e-mail address */
	passwordsBasedOnCustomerInformation:
		'お客さまのお名前やEメールアドレスに基づいたパスワード',
	/** We will send you information about your electricity, tips for saving electricity, gift campaigns, and other notices that we hope you will enjoy. You can change this setting at any time. */
	detailsOfNewsFromOctopus:
		'お使いの電気に関する情報、節電のコツや、プレゼントキャンペーンなど、お客さまに喜んでもらえるようなお知らせを随時お届けしています。こちらの設定はいつでも変更可能です。',
	/** Frequently asked questions */
	frequentlyAskedQuestions: 'よくある質問',
	/** List of tariff plans */
	tariffs: '料金プラン一覧',
	evOctopus: 'EV オクトパス',
	/** Company Overview */
	companyOverview: '企業概要',
	/** MyPage */
	myPage: 'マイページ',
	/** Get my quote */
	getMyQuote: '料金を見る',
	/** Go to application */
	goToApplication: '申し込みへ',
	/** Octopus Energy's Philosophy */
	octopusEnergyPhilosophy: 'オクトパスエナジーの理念',
	/** Easy and simple switch */
	easyAndSimpleSwitch: 'カンタン＆シンプルなお手続き',
	/** Enter your electricity usage details */
	enterYourElectricityUsageDetails: '電気ご利用内容の入力',
	/** Check price */
	checkPrice: '料金を確認',
	/** Complete your application in a few minutes */
	completeYourApplicationInAFewMinutes: '数分でお申し込み完了',
	/** More than 3 million customers worldwide are satisfied with our prices, service and environmental commitment */
	worldwideUsageAndCustomerSatisfaction:
		'世界中で300万人を超えるお客さまが、価格やサービス、環境への取り組みにご満足いただいています',
	/** Back to my page */
	backToMyPage: 'マイページに戻る',
	/** Information on your new location */
	moveInInformation: 'お引越先の情報',
	/** Day of moving out from current location */
	moveOutInformation: '現住所からの退去日',
	/** It appears that you have another (or past) subscription registered with the same email address. */
	registerSameEmailAddressBefore:
		'同じメールアドレスで別の(あるいは過去の)ご契約も登録されているようです。',
	/** And please read the official Octopus blog! */
	pleaseReadTheOfficialBlog: 'オクトパスの公式ブログも是非ご一読あれ！',
	/** Request accepted */
	resetPasswordSuccess: '受付完了',
	/** This negative balance will be removed once the payment is completed */
	negativeBalanceDescription:
		'このマイナス残高は、お支払いが完了すると解消されます',
	/** The current balance will be applied to the next electricity bill */
	positiveBalanceDescription: '現在の残高は、次の電気代に充当されます',
	/** Initiate MoveOut*/
	initiateMoveOut: '引越し手続きへ',
	/** Are you ready? */
	areYouReady: '準備はOK？',
	/** ご契約情報*/
	contractInfo: 'ご契約情報',
	/** Display all */
	displayAll: '全文を表示',
	/** Terms and Conditions */
	termsAndConditions: 'お知らせ•約款類',
	/** Terms of Use */
	termsOfUse: '利用規約',
	/** Secure Transactions */
	SecureTransactions: '安全なお取引',
	/** Payment Method */
	paymentMethod: 'お支払い方法',
	/** No 'payment form' for convenience stores will be sent by post. **/
	paperlessSystem: 'コンビニ用の「お支払い用紙」は郵送されません。',
	/** Instead of sending you a paper payment slip we’ll send an email every month with instructions on how to pay your bill to the email address provided. */
	paperlessPaymentEmail:
		'毎月のお支払いに必要な情報を、メールでご案内いたします。',
	/** Convenience store */
	convenienceStore: 'コンビニ',
	/** Convenience store electronic payment */
	convenienceStoreElectronicPayment: 'コンビニ決済',
	/** Convenience store payment: handling charge 220 yen (incl. tax) */
	convenienceStoreElectronicPaymentWithFee: 'コンビニ決済:手数料２２０円(税込)',
	menu: 'メニュー',
	/** Make a one-off payment */
	oneOffPayment: '対象の方のみ: 一時払いはコチラ',
	/** Read more */
	readMore: 'もっと読む',
	/** Show more */
	showMore: 'もっと表示する',
	/** Current contract information */
	currentContractInformation: '現在のご契約情報',
	/** Please select your postal area*/
	selectYourPostalArea: 'ご住所をお選びください',
	tariffPlan: '料金プラン',
	/** Edit */
	edit: '編集',
	billingAddress: '請求先住所',
	/** Actual usage */
	actualUsage: '実際の使用量で計算する',
	/** Somewhat low */
	lowUsageTier: '少なめ',
	/** Average */
	mediumUsageTier: '平均的',
	/** High */
	highUsageTier: '多め',
	/** Estimate with this content */
	estimateWithThisContent: 'この内容で価格をチェック',
	viewMore: 'もっと見る',
	viewLess: '表示を減らす',
	referralReward: '紹介特典',
	send: '送信',
	statusWillBeUpdatedShortly: 'まもなくステータスが更新されます',
	changeSetting: '設定を変更する',
	mail: 'メール',
	line: 'LINE',
	lineAndEmail: 'LINE & メール',
	notificationsOff: '通知なし',
	doNotWantNotifications: '通知はいらない',
	unableToUpdateSettings: '設定を変更できませんでした。',
	blogBack: 'ブログ一覧に戻る',
	relatedArticles: '他の関連記事',
	purchaseUnitPrice: '買取単価',
	estimatedUsage: '想定使用量',
	/** An error occurred while registering your payment details. Please try again or if the problem persists */
	errorSubmittingPaymentDetails:
		'お支払い情報の登録中にエラーが発生しました。もう一度お試しいただくか、問題が解決しない場合は',
	// What is the fuel cost adjustment amount on the bill?
	blogCardTitle: '請求書にある燃料費調整額（燃調費）って、一体何のこと？',
	logInAndRequestChange: 'ログインして変更を申請する',
	enrollInFixedFuelCostTariff: '「固定燃調」オプションを申し込む',
	agreeToFixedFuelCostTariffTerms:
		'「固定燃調」オプション付帯メニュー定義書に同意する',
	evOctopusTitle: 'EVオクトパス',
	forgotPassword: 'パスワードを忘れた方はコチラ',
	/* Click here to set your password */
	setPassword: 'パスワードの設定はコチラ',
	accountList: 'アカウントリスト',
	/** Switch accounts */
	switchAccounts: '表示切替',
	/** Load more */
	loadMore: 'もっと読み込む',
	/** Moving out */
	applyForNewHousing: '新しいお住まいの申し込みへ進む',
	submitting: '送信中...',
	movingDay: 'お引越し日',
	/** Start your switch now? */
	checkThePricesHere: '料金チェック👉切り替え',
	/** I'm not looking */
	imNotLooking: '見てないよ〜',
	/** Check price plans */
	checkPricePlans: '料金プランをチェック！',
	/** For Regular Households */
	forEveryday: '一般的なご家庭向け',
	/** For EV Households */
	forEVHouseholds: 'EV所有者向け',
	/** For Solar Households */
	forSolarHouseholds: 'ソーラーパネル設置住宅向け',
	movingApplication: 'お引越し先の情報を入力ください',
	movingApplication2: 'お引越し先の住所を入力ください',
	/* Email notifications setup */
	emailSettings: 'お知らせメールの受信設定',
	/* Exclusive offers from Octopus Energy */
	specialOffersFromOctopus: 'オクトパスからの耳寄り情報メール',
	/* As your current balance is positive, you do not need to make a payment here */
	positiveBalanceAlert:
		'現在の残高はプラスなので、ここで支払う必要はありません',
	/* You have a scheduled payment which will put you in a positive balance, no need to make a payment here */
	willBePositiveBalanceAlert:
		'残高がプラスになる予定のお支払いがあるため、ここで支払いを行う必要はありません',
	unregistered: '未登録',
	returnToPersonalDetails: 'お客様のご登録情報に戻る',
	seeAllPlans: '料金プラン一覧を見る',
	contractCustomerInformation: 'ご契約者情報',
	ofgemRetailersExplainer:
		"*2023年12月時点 Ofgem'Retail Market Indicators'調べ。OctopusEnergyとShell Energy Retailの合計契約件数",
	login: 'ログイン',
	emailAddress: 'メールアドレス',
	monthlyCostCalculationExplanationSimple:
		'想定使用量には当社の推定を含む場合があります。また、お見積り料金には、再生可能エネルギー発電促進賦課金、および消費税10%が含まれています。各種割引は含まれておりません。実際の電気料金は、お客さまの電気使用量によって変動します。',
	fuelMix: '電源構成',
	companyProfile: '特商法に関する表示',
	english: 'English',
	lineLogoLabel: 'LINE オクトパスエナジー',
	facebookLogoLabel: 'フェイスブック　オクトパスエナジー',
	instagramLogoLabel: 'インスタグラム　オクトパスエナジー',
	twitterLogoLabel: 'ツイッター　オクトパスエナジー',
};
