import Head from 'next/head';
import { FC } from 'react';

export type MetaProps = { blok: { content: string; name: string } };

const Meta: FC<MetaProps> = ({ blok }) => (
	<Head>
		<meta
			content={blok.content}
			name={blok.name}
			// https://nextjs.org/docs/api-reference/next/head
			// To avoid duplicate tags in your head you can use the key property
			key={blok.name}
		/>
	</Head>
);

export { Meta };
