import { Alert } from '@krakentech/coral';
import { useTranslation } from 'next-i18next';

import { Layout } from '@/components/layouts/landingPage/Layout';

export const FullPageError: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Layout>
			<div
				data-testid="full-page-error"
				className="mt-20 flex items-center justify-center p-10"
			>
				<Alert inline severity="error">
					{t('common:errors.general-error')}
				</Alert>
			</div>
		</Layout>
	);
};
