import { Accordion } from '@krakentech/coral';
import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import {
	AccordionType,
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
	RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
} from '@/components/storyblok/utils/constants';
import { sendFaqClickAnalytics } from '@/utils/googleAnalytics';
import { memoize } from '@/utils/memoize';

type FAQProps = {
	blok: {
		answer: string;
		question: string;
		theme?: 'dark' | 'mid';
	};
	type?: AccordionType;
};

const memoizedSendFaqClickAnalytics = memoize((faq_name: string) =>
	sendFaqClickAnalytics({ faq_name })
);

export const FAQ: FC<FAQProps> = ({
	blok: { question, answer, theme = 'dark' },
	type,
}) => (
	<div className="my-10 max-w-3xl px-4 text-lg leading-10 md:mx-auto">
		<Accordion
			title={question}
			onChange={(e) => {
				if (e.currentTarget.ariaExpanded === 'false' && type === 'faq') {
					memoizedSendFaqClickAnalytics(question);
				}
			}}
			theme={theme}
		>
			{render(answer, {
				...RICH_TEXT_CONSTANTS(),
				nodeResolvers: {
					...RICH_TEXT_NODE_RESOLVERS_HEADING,
					...RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
				},
			})}
		</Accordion>
	</div>
);
