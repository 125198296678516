import { useEffect, useState } from 'react';

import {
	TAILWIND_DEFAULT_LG_BREAKPOINT_PX,
	TAILWIND_DEFAULT_MD_BREAKPOINT_PX,
	TAILWIND_DEFAULT_SM_BREAKPOINT_PX,
	TAILWIND_DEFAULT_XL_BREAKPOINT_PX,
} from '@/constants/constants';
import { isBrowser } from '@/utils/browser/isBrowser';

export const useBreakpoint = (): {
	breakpoint: TailwindBreakpoint;
} => {
	const getBreakpoint = (windowInnerWidth: number) => {
		return windowInnerWidth < TAILWIND_DEFAULT_SM_BREAKPOINT_PX
			? 'sm'
			: windowInnerWidth < TAILWIND_DEFAULT_MD_BREAKPOINT_PX
				? 'md'
				: windowInnerWidth < TAILWIND_DEFAULT_LG_BREAKPOINT_PX
					? 'lg'
					: windowInnerWidth < TAILWIND_DEFAULT_XL_BREAKPOINT_PX
						? 'xl'
						: '2xl';
	};

	const [breakpoint, setBreakpoint] = useState<TailwindBreakpoint>(
		isBrowser() ? getBreakpoint(window.innerWidth) : 'sm'
	);

	useEffect(() => {
		const updateBreakpoint = () =>
			setBreakpoint(getBreakpoint(window.innerWidth));

		window.addEventListener('resize', updateBreakpoint);
		return () => window.removeEventListener('resize', updateBreakpoint);
	}, []);

	return { breakpoint };
};
