import { FC } from 'react';
interface Props {
	height?: string;
	width?: string;
}

export const FacebookLogo: FC<Props> = ({
	width = '49',
	height = '49',
}: Props) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 44 44"
		fill="none"
		aria-labelledby="facebook-logo"
	>
		<title id="facebook-logo" lang="ja">
			Facebookのロゴ {/* Facebook logo */}
		</title>
		<path
			d="M44 22C44 9.85065 34.1493 0 22 0C9.85065 0 0 9.85065 0 22C0 32.9799 8.04388 42.0818 18.5628 43.7339V28.3608H12.9754V22H18.5628V17.152C18.5628 11.6389 21.8484 8.59148 26.8727 8.59148C29.2797 8.59148 31.7981 9.02152 31.7981 9.02152V14.4357H29.0229C26.2911 14.4357 25.4372 16.1311 25.4372 17.8729V22H31.5382L30.5636 28.3608H25.4372V43.7339C35.9561 42.0849 44 32.983 44 22Z"
			fill="#1977F3"
		/>
		<path
			d="M30.5636 28.3607L31.5381 21.9998H25.4372V17.8727C25.4372 16.134 26.288 14.4355 29.0229 14.4355H31.798V9.02135C31.798 9.02135 29.2797 8.59131 26.8727 8.59131C21.8484 8.59131 18.5627 11.6356 18.5627 17.1519V21.9998H12.9753V28.3607H18.5627V43.7338C19.6827 43.9101 20.8305 43.9999 22 43.9999C23.1694 43.9999 24.3172 43.907 25.4372 43.7338V28.3607H30.5636Z"
			fill="#FEFEFE"
		/>
	</svg>
);
