import { FC } from 'react';

import styles from '@/components/shared/ConstantineLoader.module.css';
import { ConstantineShape } from '@/components/svgs/ConstantineShape';

type Props = {
	/* Colour of the Shape, in hexadecimal */
	color?: string;
};

const ConstantineLoader: FC<Props> = ({ color }) => (
	<span
		className={`space-x-4 text-center opacity-75 ${styles['enlarging-dot']}`}
	>
		<ConstantineShape fill={color} />
		<ConstantineShape fill={color} />
		<ConstantineShape fill={color} />
	</span>
);

export { ConstantineLoader };
