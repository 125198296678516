import Link from 'next/link';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

type QuickLinksProps = {
	blok: {
		links: {
			items: {
				text: string;
				url: string;
			}[];
		};
	};
};

export const QuickLinks: FC<QuickLinksProps> = ({ blok }) => (
	<Container className="my-10">
		<ul className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
			{blok?.links?.items.map((link) => (
				<Link href={link.url} key={link.url} legacyBehavior>
					<a className="flex items-center justify-center rounded-lg border-2 border-soholights bg-secondary py-6 px-8 text-lg font-bold transition-colors hover:bg-button hover:text-black">
						{link.text}
					</a>
				</Link>
			))}
		</ul>
	</Container>
);
