import { useQuery, UseQueryResult } from '@tanstack/react-query';

import graphqlClient from '@/services/graphql-client';
import { DemandResponseQuery } from '@/services/typed-graphql-sdk';

export const useDemandResponse = ({
	accountNumber,
}: {
	accountNumber: string;
	onSuccess?: (account: DemandResponseQuery) => void;
}): UseQueryResult<DemandResponseQuery> =>
	useQuery({
		queryKey: ['demandResponse', accountNumber],
		queryFn: async () => {
			return await graphqlClient.demandResponse({
				accountNumber: accountNumber ?? '',
			});
		},
		enabled: Boolean(accountNumber),
		refetchOnWindowFocus: false,
	});
