import { FC, ReactNode } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { CountDown } from '@/components/shared/CountDown';

export type CMSCountDownProps = {
	blok: {
		end_date: string;
		stay_tuned_text: unknown;
	};
};

const CMSCountDown: FC<CMSCountDownProps> = ({
	blok: { stay_tuned_text, end_date },
}) => {
	const stayTunedRichTextNode: ReactNode = render(stay_tuned_text);
	return (
		<CountDown
			endDate={end_date}
			stayTunedRichTextNode={stayTunedRichTextNode}
		/>
	);
};

export { CMSCountDown };
