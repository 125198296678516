/* eslint-disable no-restricted-imports */
import { GraphQLClientProvider } from '@krakentech/blueprint-utils';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import {
	QueryClientProvider as QueryClientProviderV5,
	QueryClient as QueryClientV5,
} from '@tanstack/react-query';
import { SpeedInsights } from '@vercel/speed-insights/next';
import type { AppProps } from 'next/app';
import { FC } from 'react';
import '../../styles/globals.css';

import { AccountProvider } from '@/components/contexts/account';
import { AffiliateProvider } from '@/components/contexts/affiliates';
import { AnalyticsProvider } from '@/components/contexts/analytics';
import { AuthProvider } from '@/components/contexts/auth';
import { TranslationProvider } from '@/components/contexts/translation';
import { AnalyticsScripts } from '@/components/shared/AnalyticsScripts';
import { ErrorBoundary } from '@/components/shared/ErrorBoundary';
import { HeadAndMeta } from '@/components/shared/HeadAndMeta';
import { SeasonalAnimation } from '@/components/shared/SeasonalAnimation';
import { Components } from '@/components/storyblok';
import { getDefaultQueryClientOptions } from '@/constants/graphql-client';
import { XStateDevTools } from '@/machines/hooks';
import { initializeDatadogLogger } from '@/services/datadog';
import { axeDevTools } from '@/utils/axeDevTools';
import { isBrowser } from '@/utils/browser/isBrowser';
import { prodLog, RECRUIT_EASTER_EGG } from '@/utils/logger';

storyblokInit({
	accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
	components: Components,
	use: [apiPlugin],
});

/**
 * XState Dev Tools
 * Use to inspect state machines 🤖
 */
XStateDevTools();

/**
 * Axe Dev Tools
 * Use to analyze DOM accessibility issues ♿️
 */
axeDevTools();

if (isBrowser()) {
	initializeDatadogLogger();
	prodLog(RECRUIT_EASTER_EGG);
}

const queryClientV5 = new QueryClientV5({
	defaultOptions: getDefaultQueryClientOptions(),
});

const MyApp: FC<AppProps> = ({ Component, pageProps, router }) => (
	<>
		<AnalyticsProvider>
			<AnalyticsScripts />
			<HeadAndMeta />
			<QueryClientProviderV5 client={queryClientV5}>
				<AuthProvider>
					<GraphQLClientProvider
						apiRoute={process.env.NEXT_PUBLIC_GRAPHQL_API_URL || ''}
					>
						<ErrorBoundary>
							<SeasonalAnimation dateString={new Date().toISOString()} />
							<AffiliateProvider>
								<AccountProvider>
									<TranslationProvider
										Component={Component}
										pageProps={pageProps}
										router={router}
									/>
								</AccountProvider>
							</AffiliateProvider>
						</ErrorBoundary>
					</GraphQLClientProvider>
				</AuthProvider>
			</QueryClientProviderV5>
		</AnalyticsProvider>
		<SpeedInsights />
	</>
);

export default MyApp;
