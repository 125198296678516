import { PostalAreaFragment } from '@/services/typed-graphql-sdk';

/**
 * Regex to capture invalid records from the jpn_postcodes_postalarea table, taken from Japan Post.
 *
 * When the record did not include a postal area, we get a couple different types of messages:
 * '長崎県西海市大島町の次に番地がくる場合' - "When the house number comes after XXX, YYY"
 * '以下に掲載がない場合' - "If not listed below"
 *
 * see discussion: https://github.com/octoenergy/tg-oe-consumer-site/pull/3998/files#r1144190641
 */
const postalAreaNotListedRegex = /場合/;

/**
 * Prunes invalid postal areas from Japan Post data
 * @param postalArea
 * @returns postalArea with invalid postalArea.area names replaced by an empty string
 */
export const removeInvalidPostalAreas = (
	postalArea: PostalAreaFragment
): PostalAreaFragment => {
	if (postalAreaNotListedRegex.test(postalArea.area)) {
		postalArea.area = '';
	}
	return postalArea;
};
