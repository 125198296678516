import { FC } from 'react';

import { useAccountContext } from '@/components/contexts/account';
import { Link } from '@/components/shared/Link';
import { ButtonProps } from '@/components/storyblok/bloks/Button';
import { Container } from '@/components/storyblok/bloks/Container';
import { memoize } from '@/utils/memoize';

type CMSLinkProps = {
	blok: {
		color?: 'primary' | 'secondary';
		has_account_id?: boolean;
	};
} & ButtonProps;

const createUrlWithAccount = (url: string, accountNumber: string) => {
	return `${url}#kid=${accountNumber}`;
};

const memoizeCreateUrlWithAccount = memoize(
	(url: string, accountNumber: string) => {
		return createUrlWithAccount(url, accountNumber);
	}
);

export const CMSLink: FC<CMSLinkProps> = ({
	blok: {
		url,
		color = 'primary',
		has_account_id,
		text = '',
		background_color = 'bg-primary',
		size = 'standard',
	},
}) => {
	const { account } = useAccountContext();

	const urlWithAccount =
		has_account_id && account?.number
			? memoizeCreateUrlWithAccount(url, account.number)
			: url;

	return (
		<div className={`w-full ${background_color}`}>
			<Container className="flex w-full items-center justify-center py-4">
				<Link
					isButton
					color={color}
					href={urlWithAccount}
					target="_blank"
					fullWidth={size === 'full-width'}
				>
					{text}
				</Link>
			</Container>
		</div>
	);
};
