import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Sparkles } from '@/components/shared/Sparkles';

export type CMSSparklesProps = {
	blok: {
		rich_text: unknown;
		sparkle_color?: string;
	};
};

export const CMSSparkles: FC<CMSSparklesProps> = ({
	blok: { rich_text, sparkle_color },
}) => {
	return <Sparkles color={sparkle_color}>{render(rich_text)}</Sparkles>;
};
