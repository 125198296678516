import { NextRouter } from 'next/router';

export const setNextPageSearchParam = ({
	url,
	redirect,
}: {
	redirect: string;
	url: URL;
}): void => {
	url.searchParams.set('redirect', encodeURI(redirect));
};

export const sanitizeUrl = (url: string): string => {
	return url.replace(/['";<>()){}\\]/g, '');
};

const isValidUrl = (url: string) => {
	// Regex patterns to match a valid relative and absolute URL
	const relativeUrlPattern = /^\/?[a-zA-Z0-9\-\\/?&=]*$/;
	const absoluteUrlPattern = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;

	return relativeUrlPattern.test(url) || absoluteUrlPattern.test(url);
};

/**
 * Redirects to the next page if the nextPage query param is valid by checking if it's either a relative or absolute URL.
 * If the nextPage query param is invalid, it will redirect to the fallback URL.
 */
export const redirectToNextPage = ({
	router,
	fallback = '/',
	redirectType = 'replace',
}: {
	/**
	 * The fallback URL to redirect to if the redirect query param is invalid.
	 */
	fallback?: string;
	redirectType?: 'replace' | 'push';
	router: NextRouter;
}): void => {
	const { query } = router;

	const redirectPath =
		typeof query.redirect === 'string'
			? query.redirect.replace(/%2F/g, '/') // Replace encoded forward slashes with regular forward slashes
			: undefined;

	const redirectUrl =
		redirectPath && isValidUrl(redirectPath) ? redirectPath : fallback;

	router[redirectType](redirectUrl);
};
