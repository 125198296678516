import { FC } from 'react';

const UnknownCardLogo: FC = () => {
	return (
		<svg
			aria-labelledby="Unknown-card-logo"
			width="25"
			height="18"
			viewBox="0 0 25 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="25" height="18" rx="2" fill="#5840FF" />
			<rect x="2" y="5" width="6" height="5" rx="0.5" fill="#6675F6" />
			<rect y="12" width="25" height="1" fill="#6675F6" />

			<title id="Unknown-card-logo" lang="ja">
				その他のカードのロゴ {/* Unknown card logo */}
			</title>
		</svg>
	);
};

export { UnknownCardLogo };
