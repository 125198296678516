import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

type PageHeaderProps = {
	blok: {
		text: string;
	};
};

export const PageHeader: FC<PageHeaderProps> = ({ blok }) => (
	<Container>
		<h1 className="my-16 text-3xl font-bold md:text-4xl">{blok.text}</h1>
	</Container>
);
