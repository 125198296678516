/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';
import { ScopeContext } from '@sentry/types';

export const RECRUIT_EASTER_EGG = `
                                           *(((((((((*      
                 .(((((((((((            ((((((((((((((((,  
             .((((((((((((((((          (((((*/,    ,**(((( 
           /(((((((((((((((((((        ((((/           */(( 
         (((((((((((((((((((((((      (((((.            *(((
       .(((((((((((((((((((((((((     (((((.   .       ./(( 
      /((((((((((((((((((((((((((.    ((((((    ((///*/((*  
     *((((((((((((((((((((((((((((     ((((((               
    ,((((((((((((((((((((((((((((((     (((((.              
    ,((((((((((((((((((((((((((((((.    /(((((/             
    ,((((((((((((((((((((((((((((((.     /(((((*            
    ,(((((((((((((((((((((((((((((((     *((((((*           
    ,(((((((((((((((((((((((((((((((      /(((((((          
     (((((((((((((((((((((((((((((((      *(((((((.         
      (((((((((((//, /#  @%(((((((((      /((((((((((     
       ((((((((@@&.  .,#@@((((((((((     *(((((((((((      
         ((((((((&@@@@@%(((((((((((/   /(((((((((((        
          /((((((((((((((((((((((((   /(((((((((((          
            ./(((((((((((((((((((((/(((((((((((,            
                .(((((((((((((((((((((((((((                
                     */(((((((((((((((/                     
                                                            
Hello there, we have open developer roles: https://jobs.lever.co/octoenergy?location=Tokyo%2C%20JP`;

/**
 * Log messages only in development mode.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const devLog = (...messages: any): void => {
	if (process.env.NODE_ENV === 'development') {
		console.debug('%c DEVLOG ⚙️', 'color: lime', ...messages);
	}
};

/**
 * Log warnings only in development mode.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const devWarn = (...messages: any): void => {
	if (process.env.NODE_ENV === 'development') {
		console.warn(...messages);
	}
};

export const logError = (message: string, error: Error, context = {}): void => {
	console.error(message, error);

	// Use "info" log level for network issues to keep them separate from actionable exceptions.
	if (error.message === 'Network request failed') {
		logMessage(message, { error, context });
	}

	Sentry.captureException(message, { extra: { error, context } });
};

export const logMessage = (
	message: string,
	extra?: ScopeContext['extra'],
	level?: ScopeContext['level'],
	tags?: ScopeContext['tags']
): void => {
	Sentry.captureMessage(message, { extra, level, tags });
};

/**
 * Log messages only in production mode.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const prodLog = (...messages: any): void => {
	if (process.env.NODE_ENV === 'production') {
		console.log(...messages);
	}
};
