import { AmexCardLogo } from '@/components/svgs/AmexCardLogo';
import { DinersClubCardLogo } from '@/components/svgs/DinersClubCardLogo';
import { DiscoverCardLogo } from '@/components/svgs/DiscoverCardLogo';
import { JCBCardLogo } from '@/components/svgs/JCBCardLogo';
import { MastercardLogo } from '@/components/svgs/MastercardLogo';
import { UnknownCardLogo } from '@/components/svgs/UnknownCardLogo';
import { VisaCardLogo } from '@/components/svgs/VisaCardLogo';
import { SalesChannelChoices } from '@/services/typed-graphql-sdk';

export const KOUFURI_START_FORM_HTML = (
	actionUrl: string
): string => ` <html lang="ja">
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            </head>
            <body>
                <form name="koufuriStartForm" id="koufuriStartForm" action="${actionUrl}" method="POST"></form>
                <script type="text/javascript">
                    var form = document.getElementById("koufuriStartForm");
                    form.submit();
                </script>
            </body>
        </html>`;

export enum CardType {
	AMEX,
	VISA,
	MASTER,
	DISCOVER,
	DINERS,
	JCB,
	OTHER,
}

export const cardNameByType: Record<CardType, string> = {
	[CardType.AMEX]: 'アメックスカード' /* AMEX card  */,
	[CardType.VISA]: 'ビザカード' /* Visa card */,
	[CardType.MASTER]: 'マスターカード' /* Mastercard */,
	[CardType.DISCOVER]: 'ディカバーカード' /* Discover card */,
	[CardType.DINERS]: 'ダイナーズクラブカード' /* Diners club card */,
	[CardType.JCB]: 'JCBカード' /* JCB card */,
	[CardType.OTHER]: 'その他のカード' /* other cards */,
};

export const cardIconByType: Record<CardType, React.ComponentType> = {
	[CardType.AMEX]: AmexCardLogo,
	[CardType.VISA]: VisaCardLogo,
	[CardType.MASTER]: MastercardLogo,
	[CardType.DISCOVER]: DiscoverCardLogo,
	[CardType.DINERS]: DinersClubCardLogo,
	[CardType.JCB]: JCBCardLogo,
	[CardType.OTHER]: UnknownCardLogo,
};

/**
 * Affiliate Sales Channels associated with the Navigator app
 */
export const navigatorSalesChannels: SalesChannelChoices[] = [
	SalesChannelChoices.FieldSales,
];
