import { FC } from 'react';

export type AnchorProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary';
		height?: number;
	};
};

/**
 *
 * @note Set to pixels for user friendliness in Storyblok
 *
 */

const Spacer: FC<AnchorProps> = ({ blok }) => {
	return (
		<div
			style={{ height: `${blok.height}px` }}
			className={`w-full ${blok.background_color}`}
		></div>
	);
};

export { Spacer };
