import { isAfter, isBefore } from 'date-fns';

import {
	QuotedElectricityProduct,
	SalesChannelChoices,
} from '@/services/typed-graphql-sdk';
import { logMessage } from '@/utils/logger';
import { readSessionStorageOnce } from '@/utils/readSessionStorageOnce';

export type PrizeDrawingContestant = Record<
	| 'account_number'
	| 'kana_given_name'
	| 'num_entries'
	| 'postcode'
	| 'referral_code',
	string
>;

if (!process.env.NEXT_PUBLIC_ABSOLUTE_URI) {
	throw new Error('NEXT_PUBLIC_ABSOLUTE_URI environment variable not provided');
}
/**
 * The fully qualified absolute URI of this deployment.
 *
 * We need this so that links to this deployment (from outside the application itself)
 * can point to the correct deployment.
 *
 * Note: all Preview branches will point to the same Preview URL.
 * If you need a specific URL for a specific branch, use "Environments Variables per Git branch".
 */
export const ABSOLUTE_URI = process.env.NEXT_PUBLIC_ABSOLUTE_URI;

export type HasAccumulator = 'has-accumulator' | 'does-not-have-accumulator';

/** Total global households estimate for Octopus Energy customers in 10s of thousands (万) */
export const OCTOPUS_HOUSEHOLDS_ESTIMATE = 770;

/**
 * Title and Description metadata constants
 */

//  Default Pages
export const TITLE = '【公式】オクトパスエナジー | グリーンな電気、もっと安く';

export const TITLE_BLOGS_RSS = '公式ブログ | オクトパスエナジー RSS フィード';
export const TITLE_BLOGS = '公式ブログ | オクトパスエナジー';
export const TITLE_BLOGS_SOCIAL = 'オクトパスエナジーの🐙公式ブログ';
export const TITLE_SOCIAL = 'オクトパスエナジー | 新時代のエネルギー';
export const TITLE_FAQ_PAGE = 'よくあるご質問 | オクトパスエナジー';
export const TITLE_TARIFFS = '料金プラン一覧 | オクトパスエナジー';
export const TITLE_FRIEND_REFERRAL =
	'オクトパスエナジー |  友達紹介割で愛とパワーをシェア';

export const DESCRIPTION = `未来に良いでんき、もっと安く。家計に優しい実質再エネプラン申し込み受付中!カンタン＆シンプルなお手続きと、親身なカスタマーサービスで、世界${OCTOPUS_HOUSEHOLDS_ESTIMATE}万近くの世帯に選ばれています。`;
export const DESCRIPTION_TARIFFS =
	'郵便番号を入力するだけで、お住まいのエリアで選べるオクトパスの料金プランをササッと一覧で表示します🐙';
export const DESCRIPTION_BLOGS_SOCIAL = `未来に良いでんき、もっと安く。家計に優しい実質再エネプラン申し込み受付中!カンタン＆シンプルなお手続きと、親身なカスタマーサービスで、世界${OCTOPUS_HOUSEHOLDS_ESTIMATE}万近くの世帯に選ばれています。`;
export const DESCRIPTION_SOCIAL = `未来に良いでんき、もっと安く。家計に優しい実質再エネプラン申し込み受付中!カンタン＆シンプルなお手続きと、親身なカスタマーサービスで、世界${OCTOPUS_HOUSEHOLDS_ESTIMATE}万近くの世帯に選ばれています。`;
export const DESCRIPTION_FRIEND_REFERRAL =
	'コチラのURLからお申し込みで、お友達と1万円の割引を半分こ。地球にもお財布にも優しいグリーンな電気へスイッチしよう!';
export const DESCRIPTION_FAQ_PAGE =
	'お客さまからよく頂くご質問をまとめています。気になるご質問の回答もこちらで見つかるかも!';

// My Page
export const TITLE_MY_PAGE_ACCOUNT = 'マイページ | オクトパスエナジー';
export const TITLE_MY_PAGE_USAGE = '電気使用量グラフ | オクトパスエナジー';
export const TITLE_MY_PAGE_TRANSACTIONS = '請求履歴 | オクトパスエナジー';
export const TITLE_MY_PAGE_EMAIL_HISTORY =
	'メッセージ一覧 | オクトパスエナジー';
export const TITLE_MY_PAGE_CHANGE_PAYMENT =
	'支払い方法の変更 | オクトパスエナジー';

export const DESCRIPTION_MY_PAGE =
	'契約内容や毎月の電気代、使用量の確認や、引っ越しなど様々なお手続きもマイページから!';

export const KEYWORDS = 'Octopus Energy Japan 電力エネルギー供給会社';
export const TWITTER_HANDLE = 'OctopusJP';
export const LINE_COMPANY_ID = 'LBkZaS2';

export enum JourneyVariantByPath {
	allElectric = 'all-electric',
	default = '',
	ev = 'ev',
	fit = 'kaitori',
	minatoMirai = 'minatomirai-octopus',
	simple = 'simple-octopus',
	solar = 'solar-octopus',
}

export const WIN_BACK_AFFILIATE_LINK_SUBDOMAIN = 'win-back-8888';

export const MINATOMIRAI_OCTOPUS_AFFILIATE_LINK_SUBDOMAIN =
	'minatomirai-octopus';

export const JourneyVariantByAffiliate = new Map<string, JourneyVariantByPath>([
	[
		MINATOMIRAI_OCTOPUS_AFFILIATE_LINK_SUBDOMAIN,
		JourneyVariantByPath.minatoMirai,
	],
]);

// Reverse map key/values of JourneyVariantByAffiliate
export const AffiliateByJourneyVariant = new Map<JourneyVariantByPath, string>(
	Object.entries(Object.fromEntries(JourneyVariantByAffiliate)).map(
		([key, value]) => [value, key]
	)
);

// For some onboarding journey variants, we don't want to send
// a follow-up email with a competing offer.
export const JOURNEYS_TO_SKIP_EMAIL_QUOTE = [
	JourneyVariantByPath.ev,
	JourneyVariantByPath.solar,
	JourneyVariantByPath.fit,
	JourneyVariantByPath.default,
	JourneyVariantByPath.allElectric,
];

export const JOURNEYS_TO_SEE_ESTIMATION_CONTRACT_CAPACITY = [
	JourneyVariantByPath.ev,
	JourneyVariantByPath.solar,
	JourneyVariantByPath.fit,
];

export const JOURNEYS_WITHOUT_SIMPLE_OCTOPUS = [
	JourneyVariantByPath.ev,
	JourneyVariantByPath.solar,
	JourneyVariantByPath.fit,
	JourneyVariantByPath.minatoMirai,
	JourneyVariantByPath.allElectric,
];

export const AFFILIATE_LINKS_TO_SKIP_EMAIL_QUOTE = [
	WIN_BACK_AFFILIATE_LINK_SUBDOMAIN,
	MINATOMIRAI_OCTOPUS_AFFILIATE_LINK_SUBDOMAIN,
];

export const MINATO_MIRAI_OCTOPUS_TARIFF_ELIGIBLE_POSTCODE = '220-0012';

export const JOURNEY_VARIANTS_WITH_TIME_BASED_TARIFFS: (JourneyVariantByPath | null)[] =
	[JourneyVariantByPath.ev, JourneyVariantByPath.solar];

/**
 * Affiliate Organisations assigned these sales channels have been configured to
 * display the default D2C reward when no Referral Scheme is associated to them.
 */
export const SALES_CHANNELS_THAT_RECEIVE_D2C_REFERRAL_REWARD: SalesChannelChoices[] =
	[SalesChannelChoices.Direct];

const SHOULD_DISPLAY_SIMPLE_ORGANIZATION_NAMES = [
	'In-House(ES・Simple)',
	'In-House(ES・UR・US・Simple)',
	'In-House(FS・Simple)',
	'In-House(IS・email・Simple)',
	'In-House(IS・Moving・Simple)',
	'In-House(IS・Simple)',
	'New Port(ES・Simple)',
	'New Port(FS・Simple)',
	'FLC Premium(Simple)',
	'Selectra(simple)',
	'TG COM(Simple)',
	`エヌツー株式会社`,
	'株式会社航建設',
	'NextHome株式会社',
	'インセプティア合同会社',
	'合同会社ティーエルシー',
	'株式会社Bestlect',
	'株式会社SEABASS（FS）',
	'木戸啓修',
	'合同会社SOFI（FS)',
	'合同会社SOFI（Event）',
	'合同会社ホップ',
	'株式会社ハーツ',
	'株式会社CLITAD',
	'FLC Premium',
	'T-GAIA',
	'FLC Premium(ES・New Green Octopus)',
	'株式会社E-TEC',
	'reck株式会社',
	'Pigro株式会社',
];

export const ENECHANGE_PCW_ORGANIZATIONS = [
	'EneChange',
	'EneChange-alldenka',
	'Enechange-chubu',
	'Enechange-chugoku',
	'Enechange-hokkaido',
	'Enechange-hokuriku',
	'Enechange-kansai',
	'Enechange-kyusyu',
	'Enechange-shikoku',
	'EneChange-Simple',
	'Enechange-tohoku',
	'Enechange-tokyo',
];

/**
 * Enechange PCW updates their website to link to the consumer-site
 * Different products are advertised there and expected to be quoted from this date
 */
export const ENECHANGE_PCW_PRODUCT_UPDATES_START_DATE = new Date(
	process.env.NEXT_PUBLIC_ENECHANGE_PCW_PRODUCT_UPDATES_START_DATE as string
);

export const FF_DEC_24_PRODUCT_START_DATE = new Date(
	process.env.NEXT_PUBLIC_FF_DEC_24_PRODUCT_START_DATE as string
);

export const getShouldDisplaySimpleOrganisationNames = (): string[] => {
	const shouldDisplayForEnechange = isAfter(
		new Date(),
		ENECHANGE_PCW_PRODUCT_UPDATES_START_DATE
	);
	if (shouldDisplayForEnechange) {
		return [
			...SHOULD_DISPLAY_SIMPLE_ORGANIZATION_NAMES,
			...ENECHANGE_PCW_ORGANIZATIONS,
		];
	} else {
		return SHOULD_DISPLAY_SIMPLE_ORGANIZATION_NAMES;
	}
};

export const getGenerationPointParamsInput = (
	electricityGeneratedLastYear: string,
	hasAccumulator: HasAccumulator
): Partial<{
	generationPointParams: {
		electricityGeneratedLastYear: number;
		hasAccumulator: boolean;
	};
}> => {
	const isFiTJourney =
		readSessionStorageOnce('user-journey') === JourneyVariantByPath.fit;

	return isFiTJourney
		? {
				generationPointParams: {
					electricityGeneratedLastYear: Number(electricityGeneratedLastYear),
					hasAccumulator: hasAccumulator === 'has-accumulator',
				},
			}
		: {};
};

/**
 * Get the estimated monthly net cost for a customer with both:
 * - A Solar generation product which Octopus energy purchases
 * - A regular Electricity supply product which Octopus energy sells
 */
export const getCombinedFITProductCostEstimate = (
	quotedElectricityProduct: QuotedElectricityProduct,
	quotedGenerationProduct: QuotedElectricityProduct
): number => {
	const {
		estimatedCosts: {
			quotedMonth: { cost },
		},
	} = quotedElectricityProduct;

	const {
		estimatedCosts: {
			quotedMonth: { cost: fitCost },
		},
	} = quotedGenerationProduct;

	const supplyCost = Number(cost);
	const absoluteFitCost = Math.abs(Number(fitCost));
	return supplyCost - absoluteFitCost;
};

export const computeNumEntries = (
	numReferrals: number,
	maxEntries: number
): number => (numReferrals >= 2 ? maxEntries : numReferrals === 1 ? 1 : 0);

/**
 * @param fullName Japanese full name in the space separated `{lastName} {firstName}` format
 * @returns {string | undefined} firstName
 */
export const getFirstName = (fullName: string): string | undefined =>
	fullName.split(' ')[1];

export const CURRENT_DR_CAMPAIGN_NAME =
	process.env.NEXT_PUBLIC_DR_CAMPAIGN_NAME;
export const CURRENT_DR_CAMPAIGN_OPT_IN_PAGE_NAME =
	process.env.NEXT_PUBLIC_DR_CAMPAIGN_OPT_IN_PAGE_NAME;

export const getMetiDiscountPrice = (month: string): number => {
	if (isBefore(new Date(), new Date('2024-11-01T00:00:00+09:00'))) {
		switch (month) {
			case '8':
			case '9':
				return 4;
			case '10':
				return 2.5;
			default:
				return 0;
		}
	}
	return 0;
};

export const getEstimatedPriceAfterMetiDiscount = (
	cost: number,
	usageAmount: number,
	metiDiscountPrice: number
): number => {
	const price = Math.round(cost - usageAmount * metiDiscountPrice);

	if (isNaN(price)) {
		logMessage(
			'Estimated price is not a number.',
			{
				cost,
				usageAmount,
				metiDiscountPrice,
			},
			'info'
		);
		return 0;
	} else {
		return price;
	}
};

export const DEFAULT_FOLLOW_UP_QUOTE_EMAIL_DELAY_IN_SECONDS = 10 * 60; // 10 minutes

const HEPCO_STANDING_DAILY_CHARGE = '12.06451613';
const MARCH_2024_DAYS = '31';
const HEPCO_FUEL_ADJUSTMENT_MARCH_2024 = '-5.06';
const HEPCO_TIER_ONE_CHARGE = '35.44';
const HEPCO_TIER_TWO_CHARGE = '41.73';
const HEPCO_TIER_THREE_CHARGE = '45.45';
const HEPCO_FIT_CHARGE_MULTIPLIER = 1.4;

export const calculateTieredUsageAmount = (
	usageAmount: string
): {
	tierOneUsage: number;
	tierThreeUsage: number;
	tierTwoUsage: number;
} => {
	// Tier 3 range
	if (Number(usageAmount) >= 280) {
		return {
			tierOneUsage: 120,
			tierTwoUsage: 160,
			tierThreeUsage: Number(usageAmount) - 280,
		};
		// Tier 2 range
	} else if (Number(usageAmount) > 120 && Number(usageAmount) < 280) {
		return {
			tierOneUsage: 120,
			tierTwoUsage: Number(usageAmount) - 120,
			tierThreeUsage: 0,
		};
		// Tier 1 range
	} else if (Number(usageAmount) <= 120) {
		return {
			tierOneUsage: Number(usageAmount),
			tierTwoUsage: 0,
			tierThreeUsage: 0,
		};
	}
	return {
		tierOneUsage: 0,
		tierTwoUsage: 0,
		tierThreeUsage: 0,
	};
};

export const calculateTieredUsageFee = (usageAmount: string): number => {
	const tieredUsageAmount = calculateTieredUsageAmount(usageAmount);

	const tierOneChargeAndAdjustment =
		Number(HEPCO_TIER_ONE_CHARGE) + Number(HEPCO_FUEL_ADJUSTMENT_MARCH_2024);
	const tierTwoChargeAndAdjustment =
		Number(HEPCO_TIER_TWO_CHARGE) + Number(HEPCO_FUEL_ADJUSTMENT_MARCH_2024);
	const tierThreeChargeAndAdjustment =
		Number(HEPCO_TIER_THREE_CHARGE) + Number(HEPCO_FUEL_ADJUSTMENT_MARCH_2024);

	const tieredUsageFee = Math.floor(
		tieredUsageAmount.tierOneUsage * tierOneChargeAndAdjustment +
			tieredUsageAmount.tierTwoUsage * tierTwoChargeAndAdjustment +
			tieredUsageAmount.tierThreeUsage * tierThreeChargeAndAdjustment
	);

	return tieredUsageFee;
};

export const calculateHepcoRates = ({
	usageAmount,
	ampere = 30,
}: {
	ampere?: number;
	usageAmount: string;
}): number | void => {
	const basicRate = Math.round(
		Number(MARCH_2024_DAYS) *
			(ampere / 10) *
			Number(HEPCO_STANDING_DAILY_CHARGE)
	);

	const billableConsumptionRate = calculateTieredUsageFee(usageAmount);

	const renewableEnergyFee = Math.floor(
		HEPCO_FIT_CHARGE_MULTIPLIER * Number(usageAmount)
	);

	const hepcoTotalTaxIncludedFees = Math.floor(
		basicRate + billableConsumptionRate + renewableEnergyFee
	);

	return hepcoTotalTaxIncludedFees;
};

export const MOVING_OUT_3000_REFERRAL_CODE = 'REWARD-MOMI-3000';
