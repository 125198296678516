import { FC } from 'react';

interface Props {
	className?: string;
	height?: string;
	width?: string;
}

export const LINELogo: FC<Props> = ({
	width = '49',
	height = '49',
	className,
}: Props) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 49 49"
		fill="none"
		aria-labelledby="line-logo"
		className={className}
	>
		<title id="line-logo" lang="ja">
			LINEのロゴ {/* LINE logo */}
		</title>
		<g clipPath="url(#clip0_367_958)">
			<path
				d="M37.7601 0.311035H11.4021C5.42578 0.311035 0.581055 5.15576 0.581055 11.132V37.49C0.581055 43.4663 5.42578 48.311 11.4021 48.311H37.7601C43.7363 48.311 48.5811 43.4663 48.5811 37.49V11.132C48.5811 5.15576 43.7363 0.311035 37.7601 0.311035Z"
				fill="#06C755"
			/>
			<path
				d="M40.58 22.049C40.58 14.888 33.401 9.06204 24.5765 9.06204C15.752 9.06204 8.573 14.888 8.573 22.049C8.573 28.469 14.267 33.845 21.9575 34.862C22.478 34.9745 23.1875 35.2055 23.3675 35.651C23.5295 36.056 23.4725 36.689 23.42 37.0985C23.42 37.0985 23.2325 38.228 23.192 38.468C23.1215 38.873 22.871 40.0505 24.578 39.3305C26.2865 38.6105 33.794 33.9035 37.151 30.0395C39.47 27.4955 40.5815 24.9155 40.5815 22.049H40.58Z"
				fill="white"
			/>
			<path
				d="M35.2551 26.1845H30.7596C30.5901 26.1845 30.4536 26.048 30.4536 25.8785V25.874V18.902V18.8945C30.4536 18.725 30.5901 18.5885 30.7596 18.5885H35.2551C35.4231 18.5885 35.5611 18.7265 35.5611 18.8945V20.03C35.5611 20.1995 35.4246 20.336 35.2551 20.336H32.1996V21.515H35.2551C35.4231 21.515 35.5611 21.653 35.5611 21.821V22.9565C35.5611 23.126 35.4246 23.2625 35.2551 23.2625H32.1996V24.4415H35.2551C35.4231 24.4415 35.5611 24.5795 35.5611 24.7475V25.883C35.5611 26.0525 35.4246 26.189 35.2551 26.189V26.1845Z"
				fill="#06C755"
			/>
			<path
				d="M18.6245 26.1845C18.7925 26.1845 18.9305 26.048 18.9305 25.8785V24.743C18.9305 24.575 18.7925 24.437 18.6245 24.437H15.569V18.893C15.569 18.725 15.431 18.587 15.263 18.587H14.1275C13.958 18.587 13.8215 18.7235 13.8215 18.893V25.8725V25.8785C13.8215 26.048 13.958 26.1845 14.1275 26.1845H18.623H18.6245Z"
				fill="#06C755"
			/>
			<path
				d="M21.3306 18.5885H20.1966C20.0276 18.5885 19.8906 18.7255 19.8906 18.8945V25.8785C19.8906 26.0475 20.0276 26.1845 20.1966 26.1845H21.3306C21.4996 26.1845 21.6366 26.0475 21.6366 25.8785V18.8945C21.6366 18.7255 21.4996 18.5885 21.3306 18.5885Z"
				fill="#06C755"
			/>
			<path
				d="M29.057 18.5885H27.9215C27.752 18.5885 27.6155 18.725 27.6155 18.8945V23.042L24.4205 18.7265C24.413 18.716 24.404 18.7055 24.3965 18.695C24.3965 18.695 24.3965 18.695 24.395 18.6935C24.389 18.6875 24.383 18.68 24.377 18.674C24.3755 18.6725 24.3725 18.671 24.371 18.6695C24.365 18.665 24.3605 18.6605 24.3545 18.656C24.3515 18.6545 24.3485 18.6515 24.3455 18.65C24.341 18.6455 24.335 18.6425 24.329 18.6395C24.326 18.638 24.323 18.635 24.32 18.6335C24.314 18.6305 24.3095 18.6275 24.3035 18.6245C24.3005 18.623 24.2975 18.6215 24.2945 18.62C24.2885 18.617 24.2825 18.614 24.2765 18.6125C24.2735 18.6125 24.2705 18.6095 24.266 18.6095C24.26 18.608 24.254 18.605 24.248 18.6035C24.245 18.6035 24.2405 18.602 24.2375 18.6005C24.2315 18.6005 24.2255 18.5975 24.2195 18.596C24.215 18.596 24.2105 18.596 24.206 18.5945C24.2 18.5945 24.1955 18.593 24.1895 18.593C24.1835 18.593 24.179 18.593 24.173 18.593C24.17 18.593 24.1655 18.593 24.1625 18.593H23.0345C22.8665 18.593 22.7285 18.7295 22.7285 18.899V25.883C22.7285 26.051 22.865 26.189 23.0345 26.189H24.17C24.3395 26.189 24.476 26.0525 24.476 25.883V21.7355L27.6755 26.057C27.698 26.0885 27.725 26.114 27.755 26.1335C27.755 26.1335 27.758 26.135 27.758 26.1365C27.764 26.141 27.77 26.144 27.7775 26.1485C27.7805 26.15 27.7835 26.1515 27.7865 26.153C27.791 26.156 27.797 26.1575 27.8015 26.1605C27.806 26.1635 27.812 26.165 27.8165 26.1665C27.8195 26.1665 27.8225 26.1695 27.8255 26.1695C27.833 26.1725 27.839 26.174 27.8465 26.1755C27.8465 26.1755 27.8495 26.1755 27.851 26.1755C27.8765 26.1815 27.9035 26.186 27.9305 26.186H29.0585C29.2265 26.186 29.3645 26.0495 29.3645 25.88V18.896C29.3645 18.728 29.228 18.59 29.0585 18.59L29.057 18.5885Z"
				fill="#06C755"
			/>
		</g>
		<defs>
			<clipPath id="clip0_367_958">
				<rect
					width="48"
					height="48"
					fill="white"
					transform="translate(0.581055 0.311035)"
				/>
			</clipPath>
		</defs>
	</svg>
);
