import { Alert, Button, Container, Skeleton } from '@krakentech/coral';
import { FC, SetStateAction, useState } from 'react';

import { isBrowser } from '@/utils/browser/isBrowser';

/**
 *
 * @todo Add extra SEO stats.
 * Update page title content to analyze h2s and h3s.
 * Update keyword content to analyze number of keywords and recommend increase if only mentioned unfrequently
 * Consider Google SEO check scores incorporation
 */

type CMSSeoAnalyzerProps = {
	blok: { keyword?: string };
};

type PageTitleProps = {
	isKeywordInTitle?: boolean;
	isSingleTitle?: boolean;
	pageTitles?: HTMLHeadingElement[];
};

type InternalLinkProps = {
	multipleLinks?: boolean;
};

type BodyTextProps = {
	isKeywordInBody?: boolean;
};

const checkPageTitleContent = ({
	keyword,
	setPageTitleState,
}: {
	keyword?: string;
	setPageTitleState: (value: SetStateAction<PageTitleProps>) => void;
}) => {
	if (!keyword) {
		return;
	}

	const h1ElementsArr = isBrowser()
		? Array.from(
				document
					.getElementById('storyblok-main-content')
					?.getElementsByTagName('h1') ?? []
			)
		: [];

	const isSingleTitle = h1ElementsArr.length === 1;
	const isKeywordInTitle = Boolean(
		h1ElementsArr.length && h1ElementsArr[0].innerText.includes(keyword)
	);

	setPageTitleState({
		pageTitles: h1ElementsArr,
		isSingleTitle,
		isKeywordInTitle,
	});
};

/**
 *
 * @note We generally don't link externally so I'm not checking if links are actually internal.
 */
const checkInternalLinkContent = ({
	setInternalLinkState,
}: {
	setInternalLinkState: (value: SetStateAction<InternalLinkProps>) => void;
}) => {
	const linkElementsArr = isBrowser()
		? Array.from(
				document
					.getElementById('storyblok-main-content')
					?.getElementsByTagName('a') ?? []
			)
		: [];

	setInternalLinkState({
		multipleLinks: Boolean(linkElementsArr.length),
	});
};

const checkKeywordsContent = ({
	keyword,
	setBodyTextState,
}: {
	keyword?: string;
	setBodyTextState: (value: SetStateAction<BodyTextProps>) => void;
}) => {
	const mainContent = isBrowser()
		? document.getElementById('storyblok-main-content')?.innerText
		: '';

	const isKeywordInBody = Boolean(keyword && mainContent?.includes(keyword));

	setBodyTextState({
		isKeywordInBody,
	});
};

export const CMSSeoAnalyzer: FC<CMSSeoAnalyzerProps> = ({ blok }) => {
	const [isSeoAnalyzed, setIsSeoAnalyzed] = useState(false);

	const [pageTitleState, setPageTitleState] = useState<PageTitleProps>({
		isSingleTitle: undefined,
		isKeywordInTitle: undefined,
		pageTitles: undefined,
	});

	const [internalLinkState, setInternalLinkState] = useState<InternalLinkProps>(
		{ multipleLinks: undefined }
	);

	const [bodyTextState, setBodyTextState] = useState<BodyTextProps>({
		isKeywordInBody: undefined,
	});

	return (
		<Container paddingX="md" paddingY="md">
			<div>SEO Analysis</div>
			{isSeoAnalyzed ? (
				<div className="space-y-4 pb-4">
					Current H1 Title(s):
					{pageTitleState?.pageTitles?.map((title, index) => (
						<p className="py-2 text-2xl" key={index}>
							{title.innerText}
						</p>
					))}
					<Alert severity={pageTitleState.isSingleTitle ? 'success' : 'error'}>
						Is Single H1 Title:{' '}
						{pageTitleState.isSingleTitle
							? 'Yes'
							: 'You have multiple h1 titles on this page. Please make sure there is only 1 h1 title present.'}
					</Alert>
					<Alert
						severity={pageTitleState.isKeywordInTitle ? 'success' : 'error'}
					>
						Is Keyword in H1 Title:{' '}
						{pageTitleState.isKeywordInTitle
							? 'Yes'
							: `Please add the keyword to the page h1 title.`}
					</Alert>
					<Alert
						severity={internalLinkState.multipleLinks ? 'success' : 'error'}
					>
						Are multiple Internal Links present?:{' '}
						{internalLinkState.multipleLinks
							? 'Yes'
							: 'Please add at least one more internal link for SEO optimization.'}
					</Alert>
					<Alert severity={bodyTextState.isKeywordInBody ? 'success' : 'error'}>
						Is Keyword in Text Body:{' '}
						{bodyTextState.isKeywordInBody
							? 'Yes'
							: `Please use the keyword in the body text of this page.`}
					</Alert>
				</div>
			) : (
				<Skeleton variant="rectangular" height={300} />
			)}
			<Button
				onClick={() => {
					checkPageTitleContent({
						keyword: blok?.keyword,
						setPageTitleState,
					});
					checkInternalLinkContent({
						setInternalLinkState,
					});
					checkKeywordsContent({ keyword: blok?.keyword, setBodyTextState });
					setIsSeoAnalyzed(true);
				}}
			>
				Click to Analyze Page SEO
			</Button>
		</Container>
	);
};
