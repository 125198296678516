// adapted from https://github.com/cahilfoley/react-snowfall

import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import isEqual from 'react-fast-compare';

import { isBrowser } from '@/utils/browser/isBrowser';

/**
 * Linear interpolation function to gradually step towards a target value
 * @param start The current value
 * @param end The target value
 * @param normal The rate of change between 0 and 1 (0 = no change, 1 = instant)
 */
export function lerp(start: number, end: number, normal: number): number {
	return (1 - normal) * start + normal * end;
}

/**
 * Selects a random item from an array of inputs.
 *
 * @param items The array of items to choose from
 * @returns A random item selected from the array
 */
export function randomElement<T>(items: T[]): T {
	const index = Math.floor(Math.random() * items.length);
	return items[index];
}

/**
 * Gets the height and width of the provided HTML element
 * @param element The html element to measure
 */
function getSize(element?: HTMLElement | null) {
	if (!element) {
		return { height: 0, width: 0 };
	}

	return {
		height: element.offsetHeight,
		width: element.offsetWidth,
	};
}

export const useComponentSize = (
	ref: React.RefObject<HTMLElement>
): { height: number; width: number } => {
	const [size, setSize] = useState(getSize(ref.current));

	const resizeHandler = useCallback(() => {
		if (ref.current) {
			setSize(getSize(ref.current));
		}
	}, [ref]);

	if (isBrowser()) {
		useLayoutEffect(() => {
			const { ResizeObserver } = window;

			if (!ref.current) {
				return;
			}
			resizeHandler();

			if (typeof ResizeObserver === 'function') {
				const resizeObserver = new ResizeObserver(resizeHandler);
				resizeObserver.observe(ref.current);

				return () => resizeObserver.disconnect();
			} else {
				window.addEventListener('resize', resizeHandler);

				return () => window.removeEventListener('resize', resizeHandler);
			}
		}, [ref, resizeHandler]);
	}

	return size;
};

/**
 * Same as `React.useEffect` but uses a deep comparison on the dependency array. This should only
 * be used when working with non-primitive dependencies.
 *
 * @param effect Effect callback to run
 * @param deps Effect dependencies
 */
export function useDeepCompareEffect(
	effect: React.EffectCallback,
	deps: React.DependencyList
): void {
	const ref = useRef<React.DependencyList>(deps);

	// Only update the current dependencies if they are not deep equal
	if (!isEqual(deps, ref.current)) {
		ref.current = deps;
	}

	return useEffect(effect, ref.current);
}

/**
 * Utility hook to stabilize a reference to a value, the returned value will always match the input value
 * but (unlike an inline object) will maintain [SameValueZero](http://ecma-international.org/ecma-262/7.0/#sec-samevaluezero)
 * equality until a change is made.
 *
 * @example
 *
 * const obj = useDeepMemo({ foo: 'bar', bar: 'baz' }) // <- inline object creation
 * const prevValue = usePrevious(obj) // <- value from the previous render
 * console.log(obj === prevValue) // <- always logs true until value changes
 */
export function useDeepMemo<T>(value: T): T {
	const [state, setState] = useState(value);

	useDeepCompareEffect(() => setState(value), [value]);

	return state;
}
