// Google Analytics related constants have the GA prefix. Other analytics (Yahoo, etc) will have different ones :)
export const GA_EVENT = 'event';
export const GA_EVENT_TYPE_SIGN_UP = 'sign_up'; // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
export const GA_EVENT_TYPE_CLICK_APPLY_PRODUCT_PAGE = 'select_product';
export const GA_EVENT_ADD_EMAIL_PRODUCT_PAGE = 'add_email_product_page';
export const GA_EVENT_SUBMIT_PAYMENT_PAGE_TRY = 'submit_payment_page_try';
export const GA_EVENT_SUBMIT_PAYMENT_PAGE_ERROR = 'submit_payment_page_error';
export const GA_EVENT_CLICK_QUOTE_HOMEPAGE = 'click_quote_homepage';
export const GA_EVENT_CLICK_QUOTE_TARIFFS = 'click_quote_tariffs';
export const GA_EVENT_CLICK_QUOTE_BLOG = 'click_quote_blog';
export const GA_EVENT_LOGIN = 'login'; //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
export const GA_EVENT_MOVE_IN_SIGN_UP = 'move_in_sign_up';
export const GA_EVENT_MOVE_IN_SELECT = 'move_in_select';
export const GA_EVENT_GENERATE_QUOTE_ESTIMATION = 'generate_quote_estimation';
export const GA_EVENT_CLICK_FAQ = 'click_faq';
export const GA_EVENT_SUBMIT_FINAL_STEP = 'submit_final_step';
export const GA_EVENT_MOVE_OUT_CANCEL = 'move_out_cancel';
export const GA_EVENT_MOVE_OUT_LOST_OBJ_CTA = 'move_out_lost_obj_cta';
export const GA_EVENT_TYPE_SHORTCUT_SIGN_UP = 'shortcut_sign_up';
export const GA_EVENT_SHORTCUT_TYPE_CLICK_APPLY_TARIFF_PAGE =
	'shortcut_select_tariff';
export const GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_TRY =
	'shortcut_payment_page_try';
export const GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_ERROR =
	'shortcut_payment_page_error';
export const GA_EVENT_SHORTCUT_SUBMIT_FINAL_STEP = 'shortcut_submit_final_step';
export const GA_EVENT_TOP_PRODUCT_CARD_CLICK = 'top_product_card_click';
export const GA_EVENT_TYPE_STANDARD_PRODUCT_CARD_HOMEPAGE =
	'top_product_standard_plan_button';
export const GA_EVENT_TYPE_SOLAR_PRODUCT_CARD_HOMEPAGE =
	'top_product_solar_plan_button';
export const GA_EVENT_TYPE_EV_PRODUCT_CARD_HOMEPAGE =
	'top_product_ev_plan_button';
export const GA_EVENT_TYPE_FIT_PRODUCT_CARD_HOMEPAGE =
	'top_product_fit_plan_button';
export const GA_EVENT_TYPE_PRODUCT_CARD_ALL_ELECTRIC =
	'top_product_all_electric_plan_button';
export const GA_EVENT_MOVE_OUT_IN_SIGN_UP = 'move_out_in_sign_up';
export const GA_EVENT_SWITCH_IN_SIGN_UP = 'switch_in_sign_up';
export const GA_EVENT_ACCEPT_COOKIES = 'cookie_accept';
export const GA_EVENT_DECLINE_COOKIES = 'cookie_decline';
export const GA_EVENT_MOMI_SUBMIT_FINAL_STEP = 'momi_submit_final_step';
// Homepage Price-check
export const GA_EVENT_HOMEPAGE_PRICECHECK_TOKYO = 'hp-pricecheck-tokyo-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_KANSAI = 'hp-pricecheck-kansai-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_HOKKAIDO =
	'hp-pricecheck-hokkaido-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_CHUBU = 'hp-pricecheck-chubu-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_CHUGOKU =
	'hp-pricecheck-chugoku-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_SHIKOKU =
	'hp-pricecheck-shikoku-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_TOHOKU = 'hp-pricecheck-tohoku-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_HOKURIKU =
	'hp-pricecheck-hokuriku-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_KYUSHU = 'hp-pricecheck-kyushu-area';
export const GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_1 = 'hp-pricecheck-1';
export const GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_2_TO_3 =
	'hp-pricecheck-2to3';
export const GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_4_PLUS =
	'hp-pricecheck-4-and-above';
export const GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_APPLY =
	'hp-pricecheck-quote-applybtn';
export const GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_MORE_DETAILS =
	'hp-pricecheck-quote-moredetailsbtn';
// Note: Currently no button exists in the sidebar to apply with.
export const GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_DETAILS_APPLY =
	'hp-pricecheck-quotedetails-applybtn';
export const GA_EVENT_HOMEPAGE_GOOGLEREVIEW_MORE_BUTTON =
	'hp-googlereview-morebtn';

export const GA_EVENT_MOMI_SELECT_OPT1_OFFER_CTA_BTN =
	'momi-select-opt1-offer-cta-btn';
export const GA_EVENT_MOMI_SELECT_OPT2_ALREADY_ON_SUPPLY_CTA_BTN =
	'momi-select-opt2-already-on-supply-cta-btn';
export const GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_CTA_BTN =
	'momi-select-opt3-other-supplier-cta-btn';
export const GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_IN_CTA =
	'momi-select-opt1-offer-move-in-cta';
export const GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_OUT_CTA =
	'momi-select-opt1-offer-move-out-cta';
export const GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_SKIP_CTA =
	'momi-select-opt3-other-supplier-skip-cta';
export const GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_DISCOUNT_EMAIL_CTA =
	'momi-select-opt3-other-supplier-discount-email-cta';
export const GA_EVENT_SIMPLE_OCTOPUS_CALCULATOR_CTA =
	'simple-octopus-calculator-cta';
export const GA_EVENT_OBJ_SIMPLE_OCTOPUS_PRODUCT_PG =
	'OBJ-productpg-simple-octopus';
export const GA_EVENT_OBJ_GREEN_OCTOPUS_PRODUCT_PG =
	'OBJ-productpg-green-octopus';
export const GA_EVENT_YOUR_DETAILS_SPIN_AND_POSTCODE_REQUOTE =
	'your_details_spin_and_postcode_requote';
export const GA_EVENT_YOUR_DETAILS_VALIDATE_SPIN = 'your_details_validate_spin';
export const GA_EVENT_WALLET_REVERT_TO_OLD_VERSION =
	'account_wallet_revert_to_old_version_cta';

export const GA_EVENT_PV_SOLAR_LP_MAIN_CTA = 'pv_solar_lp_main_cta';
const ButtonEvents = [GA_EVENT_PV_SOLAR_LP_MAIN_CTA] as const;

// Storyblok options for Button component ga_event should match this type
export type ButtonEvent = (typeof ButtonEvents)[number];

export type DualFuelParamsType =
	| 'product_pg_signup_cta_electricity_only'
	| 'product_pg_signup_cta_gas_and_electricity';
