import * as Sentry from '@sentry/nextjs';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { FullPageError } from '@/components/shared/FullPageError';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
	state: State = {
		hasError: false,
	};

	static getDerivedStateFromError(): State {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		Sentry.captureMessage(
			`Uncaught error: ${error}, info: ${errorInfo.toString()}`,
			{ extra: { error, errorInfo } }
		);
	}

	render(): ReactNode {
		return this.state.hasError ? <FullPageError /> : this.props.children;
	}
}
