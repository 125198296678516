import { Stack } from '@krakentech/coral';
import { FC } from 'react';

interface HomePageSectionHeadingProps {
	headingText: string;
	subheadingText: string;
}

export const HomePageSectionHeading: FC<HomePageSectionHeadingProps> = ({
	headingText,
	subheadingText,
}) => (
	<header className="mb-12">
		<Stack direction="vertical-reverse" gap="md" sm={{ gap: 'lg' }}>
			<h2 className="text-3xl font-bold leading-normal text-voltage md:text-4xl">
				{headingText}
			</h2>
			<h3 className="text-base font-bold md:text-1.5xl">{subheadingText}</h3>
		</Stack>
	</header>
);
