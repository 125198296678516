import { FC, HTMLAttributes, ReactNode } from 'react';

type ContainerProps = {
	children: ReactNode;
	className?: HTMLAttributes<HTMLDivElement>['className'];
	style?: HTMLAttributes<HTMLDivElement>['style'];
};

export const Container: FC<ContainerProps> = ({
	children,
	className,
	style = {},
}) => (
	<div className={`max-w-3xl px-4 md:mx-auto ${className}`} style={style}>
		{children}
	</div>
);
