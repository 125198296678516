import { FC } from 'react';

const AmexCardLogo: FC = () => {
	return (
		<svg
			width="27"
			height="18"
			viewBox="0 0 27 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="amex-card-logo"
		>
			<title id="amex-card-logo" lang="ja">
				{/* American Express card logo */}
				アメリカン・エキスプレスカードのロゴ
			</title>
			<path
				d="M0 2C0 0.89543 0.895431 0 2 0H25C26.1046 0 27 0.895431 27 2V16C27 17.1046 26.1046 18 25 18H2C0.895431 18 0 17.1046 0 16V2Z"
				fill="#016FD0"
			/>
			<g mask="url(#mask0_485_17359)">
				<path
					d="M0 2C0 0.89543 0.895431 0 2 0H25C26.1046 0 27 0.895431 27 2V16C27 17.1046 26.1046 18 25 18H2C0.895431 18 0 17.1046 0 16V2Z"
					fill="#F1F9FF"
				/>
				<path
					d="M6.45361 13.0909V9.58887H10.2675L10.6767 10.1075L11.0995 9.58887H24.9431V12.8494C24.9431 12.8494 24.5811 13.0874 24.1623 13.0909H16.4968L16.0355 12.5388V13.0909H14.5237V12.1485C14.5237 12.1485 14.3172 12.2801 13.8707 12.2801H13.3561V13.0909H11.0671L10.6585 12.5611L10.2436 13.0909H6.45361Z"
					fill="white"
				/>
				<path
					d="M2 6.9494L2.86006 5H4.34743L4.83553 6.09197V5H6.68448L6.97504 5.78924L7.25673 5H15.5566V5.39678C15.5566 5.39678 15.9929 5 16.71 5L19.403 5.00915L19.8826 6.08683V5H21.4299L21.8558 5.61904V5H23.4173V8.50202H21.8558L21.4477 7.88097V8.50202H19.1743L18.9457 7.94997H18.3346L18.1097 8.50202H16.568C15.951 8.50202 15.5566 8.11334 15.5566 8.11334V8.50202H13.2321L12.7707 7.94997V8.50202H4.12709L3.89863 7.94997H3.28944L3.06259 8.50202H2V6.9494Z"
					fill="white"
				/>
				<path
					d="M3.16393 5.43164L2.00391 8.05384H2.75914L2.97318 7.52876H4.2175L4.43043 8.05384H5.2023L4.04338 5.43164H3.16393ZM3.59312 6.04191L3.97241 6.95946H3.21273L3.59312 6.04191Z"
					fill="#016FD0"
				/>
				<path
					d="M5.28198 8.05384V5.43164L6.35522 5.43552L6.97945 7.12615L7.58873 5.43164H8.65339V8.05384H7.97911V6.12169L7.26435 8.05384H6.67301L5.95626 6.12169V8.05384H5.28198Z"
					fill="#016FD0"
				/>
				<path
					d="M9.11523 8.05387V5.43164H11.3155V6.01819H9.79662V6.46673H11.2801V7.01878H9.79662V7.48457H11.3155V8.05387H9.11523Z"
					fill="#016FD0"
				/>
				<path
					d="M11.7056 5.43164V8.05387H12.3799V7.12229H12.6638L13.4722 8.05387H14.2962L13.409 7.08778C13.7731 7.05791 14.1487 6.75407 14.1487 6.28236C14.1487 5.73055 13.7033 5.43164 13.2061 5.43164H11.7056ZM12.3799 6.01819H13.1506C13.3355 6.01819 13.47 6.15881 13.47 6.29421C13.47 6.46842 13.2958 6.57024 13.1606 6.57024H12.3799V6.01819Z"
					fill="#016FD0"
				/>
				<path
					d="M15.1133 8.05387H14.4248V5.43164H15.1133V8.05387Z"
					fill="#016FD0"
				/>
				<path
					d="M16.7456 8.05387H16.597C15.878 8.05387 15.4414 7.50311 15.4414 6.75354C15.4414 5.98544 15.8731 5.43164 16.7811 5.43164H17.5264V6.05269H16.7539C16.3853 6.05269 16.1246 6.33236 16.1246 6.76C16.1246 7.26783 16.4226 7.48112 16.8521 7.48112H17.0295L16.7456 8.05387Z"
					fill="#016FD0"
				/>
				<path
					d="M18.2135 5.43164L17.0535 8.05384H17.8087L18.0227 7.52876H19.2671L19.48 8.05384H20.2519L19.0929 5.43164H18.2135ZM18.6427 6.04191L19.022 6.95946H18.2623L18.6427 6.04191Z"
					fill="#016FD0"
				/>
				<path
					d="M20.3296 8.05387V5.43164H21.1869L22.2815 7.07916V5.43164H22.9558V8.05387H22.1262L21.0039 6.36322V8.05387H20.3296Z"
					fill="#016FD0"
				/>
				<path
					d="M6.91528 12.6427V10.0205H9.11559V10.6071H7.59667V11.0556H9.0801V11.6076H7.59667V12.0734H9.11559V12.6427H6.91528Z"
					fill="#016FD0"
				/>
				<path
					d="M17.696 12.6427V10.0205H19.8963V10.6071H18.3774V11.0556H19.8538V11.6076H18.3774V12.0734H19.8963V12.6427H17.696Z"
					fill="#016FD0"
				/>
				<path
					d="M9.20104 12.6427L10.2724 11.3478L9.17554 10.0205H10.025L10.6783 10.841L11.3337 10.0205H12.1499L11.0675 11.3316L12.1408 12.6427H11.2914L10.6572 11.8351L10.0383 12.6427H9.20104Z"
					fill="#016FD0"
				/>
				<path
					d="M12.2202 10.0205V12.6427H12.9122V11.8147H13.622C14.2226 11.8147 14.6778 11.5049 14.6778 10.9025C14.6778 10.4035 14.3208 10.0205 13.7096 10.0205H12.2202ZM12.9122 10.6135H13.6597C13.8537 10.6135 13.9924 10.7291 13.9924 10.9154C13.9924 11.0904 13.8544 11.2173 13.6575 11.2173H12.9122V10.6135Z"
					fill="#016FD0"
				/>
				<path
					d="M14.9709 10.0205V12.6427H15.6452V11.7112H15.9291L16.7376 12.6427H17.5616L16.6744 11.6767C17.0385 11.6468 17.4141 11.3429 17.4141 10.8712C17.4141 10.3194 16.9686 10.0205 16.4715 10.0205H14.9709ZM15.6452 10.6071H16.416C16.6009 10.6071 16.7354 10.7477 16.7354 10.8831C16.7354 11.0573 16.5611 11.1591 16.426 11.1591H15.6452V10.6071Z"
					fill="#016FD0"
				/>
				<path
					d="M20.2094 12.6427V12.0734H21.5589C21.7585 12.0734 21.845 11.9685 21.845 11.8535C21.845 11.7432 21.7588 11.6318 21.5589 11.6318H20.9491C20.419 11.6318 20.1238 11.3178 20.1238 10.8464C20.1238 10.426 20.3941 10.0205 21.1818 10.0205H22.4949L22.211 10.6105H21.0753C20.8582 10.6105 20.7914 10.7213 20.7914 10.827C20.7914 10.9357 20.874 11.0556 21.0398 11.0556H21.6786C22.2695 11.0556 22.5259 11.3815 22.5259 11.8082C22.5259 12.267 22.2402 12.6427 21.6465 12.6427H20.2094Z"
					fill="#016FD0"
				/>
				<path
					d="M22.6833 12.6427V12.0734H24.0327C24.2324 12.0734 24.3189 11.9685 24.3189 11.8535C24.3189 11.7432 24.2327 11.6318 24.0327 11.6318H23.4229C22.8929 11.6318 22.5977 11.3178 22.5977 10.8464C22.5977 10.426 22.868 10.0205 23.6557 10.0205H24.9419L24.6848 10.6105H23.5492C23.3321 10.6105 23.2653 10.7213 23.2653 10.827C23.2653 10.9357 23.3479 11.0556 23.5137 11.0556H24.1525C24.7434 11.0556 24.9998 11.3815 24.9998 11.8082C24.9998 12.267 24.7141 12.6427 24.1203 12.6427H22.6833Z"
					fill="#016FD0"
				/>
			</g>
		</svg>
	);
};

export { AmexCardLogo };
