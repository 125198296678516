/**
 * In order to hyphenate a number as the user inputs it,
 * for each new character inputted, we may filter the
 * current input through a pipeline of section matchers:
 *
 *  // match and replace (XX)X -> (XX)-X
 *  sectionOne = input.replace(/^(\d{2})(\d{1})$/,'$1-$2');
 *
 *  // match and replace (XX-XXXX)X -> (XX-XXXX)-X
 *  sectionTwo = sectionOne.replace(/(^\d{2}-\d{4})(\d{1})$/, '$1-$2');
 *
 *  // match and replace (XX-XXXX-XXXX)X -> (XX-XXXX-XXXX)-X
 *  sectionThree = sectionTwo.replace(/(^\d{2}-\d{4}-\d{4})(\d{1})$/, '$1-$2');
 *
 * ...and so on.
 *
 * At every event, we are trying to match and hyphenate matching sections. The below function abstracts this process.
 *
 */

/**
 * Receives an string and a section map,
 * and recursively builds a regex to match
 * and replace each section.
 *
 * @param {string} input The user input to be hyphenated, in string form
 * @param {number[]} sections A list of sections, each section represents its number of digits ex. [2,3,4] -> "XX-XXX-XXXX"
 * @param {number} _currentSection The current section being iterated
 * @param {string} _growingFirstCaptureGroup The first capture group of the regex, which grows in each iteration. ex. (XX) grows to (XX-XXXX)
 *
 */

const numberHyphenator9000 = (
	input: string,
	sections: number[],
	separator = '-',
	_currentSection = 0,
	_growingFirstCaptureGroup = ''
): string => {
	const isFinalSection = _currentSection === sections.length - 1;
	if (isFinalSection) {
		return input;
	}

	const currentSectionDigits = sections[_currentSection];
	const maybeTrailingHyphen = _growingFirstCaptureGroup ? `${separator}` : '';
	const maybeGrowingFirstCaptureGroup = _growingFirstCaptureGroup || ``;
	const firstCaptureGroup = `${maybeGrowingFirstCaptureGroup}${maybeTrailingHyphen}\\d{${currentSectionDigits}}`;

	const regex = `(^${firstCaptureGroup})(\\d+)$`;

	const hyphenatedSection = input.replace(
		new RegExp(regex),
		`$1${separator}$2`
	);

	return numberHyphenator9000(
		hyphenatedSection,
		sections,
		separator,
		_currentSection + 1,
		firstCaptureGroup
	);
};

export { numberHyphenator9000 };
