import { Skeleton } from '@krakentech/coral';
import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { CampaignProps, StoryContent } from '@/types/storyblok';

export type CampaignVisibilityWrapperProps = {
	blok: {
		body?: StoryContent;
		is_visible_when?: string[];
	};
	props?: CampaignProps;
};

export const CampaignVisibilityWrapper: FC<CampaignVisibilityWrapperProps> = ({
	blok,
	props,
}) => {
	const isVisible = blok.is_visible_when?.includes(
		props?.participationStatus as string
	);

	return isVisible ? (
		<div className="w-full">
			{blok.body?.map((blok) =>
				props?.isLoading ? (
					<div className="flex w-full justify-center" key={blok._uid}>
						<Skeleton variant="rectangular" animation="wave">
							<DynamicComponent blok={blok} props={props} key={blok._uid} />
						</Skeleton>
					</div>
				) : (
					<DynamicComponent blok={blok} props={props} key={blok._uid} />
				)
			)}
		</div>
	) : null;
};
