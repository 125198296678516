import { IconDownload } from '@krakentech/icons';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

export type CMSImageLinkProps = {
	blok: {
		caption?: string;
		document: {
			filename: string;
		};
		label: string;
	};
};

const CMSImageLink: FC<CMSImageLinkProps> = ({
	blok: { caption, document, label },
}) => (
	<Container className="py-2">
		<div className="flex items-center space-x-2">
			<a
				href={document.filename}
				target="_blank"
				className="flex items-center space-x-4"
				rel="noopener noreferrer"
			>
				<IconDownload size={30} />
				<p className="font-bold text-soholights underline">{label}</p>
			</a>
			<p>(Image)</p>
			{caption ? <p>{caption}</p> : null}
		</div>
	</Container>
);

export { CMSImageLink };
