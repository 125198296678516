import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { StoryContent } from '@/types/storyblok';

interface Props {
	blok: {
		body: StoryContent;
	};
	props?: any;
}

export const Page: FC<Props> = ({ blok, props }) => {
	return (
		<main id="storyblok-main-content">
			{blok.body?.map((blok) => (
				<DynamicComponent blok={blok} props={props} key={blok._uid} />
			))}
		</main>
	);
};
