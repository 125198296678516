import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { StoryContent } from '@/types/storyblok';

type Props = {
	blok: {
		cards: StoryContent;
	};
};

export const ComplexInfoCardGrid: FC<Props> = ({ blok }) => {
	return (
		<div className="mt-8 flex max-w-7xl flex-wrap justify-center gap-8 px-8 md:mx-auto md:px-4">
			{blok.cards.map((blok) => (
				<DynamicComponent blok={blok} key={blok._uid} />
			))}
		</div>
	);
};
