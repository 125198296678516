import * as Sentry from '@sentry/nextjs';
import { FC, useEffect, useState } from 'react';

import {
	ParticleFall,
	ParticleFallProps,
} from '@/components/shared/animations/ParticleFall';

const AutumnLeavesImageSources = [
	'/images/leaves/Leaves-1.svg',
	'/images/leaves/Leaves-2.svg',
	'/images/leaves/Leaves-3.svg',
	'/images/leaves/Leaves-4.svg',
	'/images/leaves/Leaves-5.svg',
	'/images/leaves/Leaves-6.svg',
	'/images/leaves/Leaves-7.svg',
];

export const AutumnLeaves: FC = () => {
	const [images, setImages] = useState<CanvasImageSource[]>();
	const particalProps: ParticleFallProps = {
		count: 50,
		color: 'orange',
		wind: [2.0, 4.0],
		speed: [3.0, 5.0],
		images,
		radius: [20, 40],
		rotationSpeed: [0, 0],
	};

	useEffect(() => {
		const imagePromises = AutumnLeavesImageSources.map((src) => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = () => resolve(img);
				img.onerror = reject;
				img.src = src;
			});
		});
		Promise.all(imagePromises)
			.then((images) => {
				setImages(images as CanvasImageSource[]);
			})
			.catch((error) => {
				Sentry.captureMessage(
					'There was an error loading the AutumnLeaves svgs',
					{
						extra: { error },
					}
				);
			});
	}, []);

	return images ? <ParticleFall {...particalProps} /> : null;
};
