import { TFunction } from 'next-i18next';

import { GridOperatorName } from '@/constants/industry/gridOperator';
import { SupplyPointStatus } from '@/services/typed-graphql-sdk';

export enum ContractCapacityOption {
	OEPC = 'OEPC',
	amperage = 'amperage',
	dontKnow = 'dontKnow',
	kva = 'kva',
	lowVoltage = 'lowVoltage',
}

export type StandingChargeUnitType =
	| 'YEN_KVA_DAY'
	| 'YEN_AMPERE_DAY'
	| 'YEN_DAY'
	| 'YEN_KW_DAY';

export const STANDING_CHARGE_UNIT_TYPES: StandingChargeUnitType[] = [
	'YEN_KVA_DAY',
	'YEN_AMPERE_DAY',
	'YEN_DAY',
	'YEN_KW_DAY',
];

export const getStandingChargeUnitTranslation = (
	type: StandingChargeUnitType,
	t: TFunction
): string => {
	const standingChargeUnitTranslationKeys: Record<
		StandingChargeUnitType,
		string
	> = {
		YEN_KVA_DAY: t('yen-kva-day'),
		YEN_AMPERE_DAY: t('yen-ampere-day'),
		YEN_DAY: t('yen-day'),
		YEN_KW_DAY: t('yen-kw-day'),
	};
	return standingChargeUnitTranslationKeys[type];
};

/**
 * Monthly Usage by grid operator and usage tier
 * @see {https://docs.google.com/spreadsheets/d/1aNDlpkMP3zhM-8qZrH0WSEv7fHOfcd22hQOEgzO4AqU/edit?usp=sharing}
 * */
export const usageTiers = ['LOW', 'MEDIUM', 'HIGH'] as const;
export type UsageTier = (typeof usageTiers)[number];

export const ADJUSTED_AVERAGE_USAGE_TIERS_BY_GRID_OPERATOR_NAME: Record<
	GridOperatorName,
	// The average monthly usage is stored as arrays assuming month order by index.
	Record<UsageTier, number[]>
> = {
	HEPCO: {
		HIGH: [360, 360, 350, 310, 280, 250, 260, 310, 330, 260, 300, 330],
		MEDIUM: [240, 230, 230, 210, 190, 180, 180, 200, 210, 170, 190, 220],
		LOW: [130, 130, 130, 120, 120, 110, 100, 120, 130, 100, 110, 120],
	},
	TOHOKU: {
		HIGH: [440, 460, 420, 320, 240, 220, 260, 350, 340, 240, 250, 350],
		MEDIUM: [300, 310, 270, 200, 150, 130, 160, 220, 210, 150, 170, 230],
		LOW: [180, 180, 160, 120, 90, 90, 100, 140, 130, 100, 100, 150],
	},
	TEPCO: {
		HIGH: [420, 450, 400, 310, 250, 260, 340, 430, 410, 310, 260, 330],
		MEDIUM: [280, 310, 270, 210, 180, 180, 240, 310, 290, 210, 180, 220],
		LOW: [180, 200, 180, 140, 120, 120, 160, 210, 200, 140, 120, 150],
	},
	CHUBU: {
		HIGH: [450, 480, 420, 320, 250, 260, 330, 430, 410, 310, 270, 350],
		MEDIUM: [300, 310, 280, 220, 180, 180, 230, 300, 280, 210, 180, 230],
		LOW: [190, 200, 170, 140, 110, 120, 140, 190, 180, 140, 120, 140],
	},
	HOKUDEN: {
		HIGH: [550, 630, 580, 430, 310, 290, 370, 490, 450, 340, 350, 460],
		MEDIUM: [370, 390, 370, 260, 180, 170, 220, 330, 290, 210, 220, 290],
		LOW: [220, 260, 210, 140, 100, 110, 140, 200, 180, 110, 130, 170],
	},
	KEPCO: {
		HIGH: [420, 450, 390, 290, 240, 230, 300, 400, 390, 280, 240, 320],
		MEDIUM: [290, 300, 270, 200, 170, 160, 210, 280, 270, 200, 160, 220],
		LOW: [190, 200, 170, 130, 110, 100, 130, 190, 180, 130, 100, 140],
	},
	CHUGOKU: {
		HIGH: [390, 410, 340, 260, 200, 180, 240, 340, 350, 250, 190, 290],
		MEDIUM: [240, 250, 220, 170, 120, 120, 160, 220, 220, 160, 130, 190],
		LOW: [150, 160, 130, 100, 80, 80, 100, 150, 150, 100, 80, 120],
	},
	YONDEN: {
		HIGH: [400, 410, 330, 250, 190, 220, 300, 370, 320, 260, 220, 280],
		MEDIUM: [240, 260, 230, 160, 130, 150, 190, 260, 220, 170, 150, 190],
		LOW: [160, 180, 160, 120, 90, 100, 130, 170, 150, 120, 90, 130],
	},
	KYUDEN: {
		HIGH: [360, 350, 310, 240, 200, 200, 280, 360, 330, 250, 200, 290],
		MEDIUM: [240, 230, 200, 160, 130, 130, 180, 240, 220, 160, 130, 190],
		LOW: [160, 160, 130, 100, 80, 90, 120, 160, 150, 110, 90, 120],
	},
	OEPC: {
		HIGH: [450, 450, 450, 450, 450, 450, 450, 450, 450, 450, 450, 450],
		MEDIUM: [360, 360, 360, 360, 360, 360, 360, 360, 360, 360, 360, 360],
		LOW: [200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200, 200],
	},
};

export const DEFAULT_USAGE_TIER = 'LOW';

export const activeStatuses = [
	SupplyPointStatus.MoveInPending,
	SupplyPointStatus.MoveOutPending,
	SupplyPointStatus.MoveOutMoveInPending,
	SupplyPointStatus.OnSupply,
	SupplyPointStatus.SwitchInPending,
	SupplyPointStatus.SwitchOutPending,
];
