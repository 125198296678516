import Image from 'next/image';
import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
	RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
	textAlignMap,
} from '@/components/storyblok/utils/constants';
import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';

type CMSImageAndTextProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary';
		image: {
			alt: string;
			filename: string;
		};
		image_align: 'left' | 'right';
		rich_text: unknown;
		text_align: 'left' | 'right' | 'center';
	};
};

const imageAlignMap = {
	left: 'lg:flex-row',
	right: 'lg:flex-row-reverse',
};

export const ImageAndText: FC<CMSImageAndTextProps> = ({
	blok: {
		image,
		rich_text,
		image_align,
		text_align,
		background_color = 'bg-primary',
	},
}) => {
	if (!image.filename) {
		return <p>Please add all required fields.</p>;
	}

	const optimizedImage = optimizeStoryblokImage({
		src: image.filename,
		options: { smartCrop: false, fitIn: true },
	});

	return (
		<section className={`w-full ${background_color}`}>
			<div
				className={`flex flex-col ${textAlignMap[text_align]} ${imageAlignMap[image_align]} mx-auto max-w-6xl items-center justify-center gap-2 px-4 align-middle`}
			>
				<div className="relative h-[320px] w-[320px] max-w-full md:h-[500px] md:w-[500px]">
					<Image
						alt={image?.alt}
						src={optimizedImage}
						fill
						sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
					/>
				</div>
				<div className="mx-auto w-full break-words px-4 text-lg leading-10 lg:w-1/2">
					{render(rich_text, {
						...RICH_TEXT_CONSTANTS(),
						nodeResolvers: {
							...RICH_TEXT_NODE_RESOLVERS_HEADING,
							...RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS(text_align),
						},
					})}
				</div>
			</div>
		</section>
	);
};
