import { Alert } from '@krakentech/coral';
import type { AlertProps } from '@krakentech/coral';
import { FC } from 'react';

type CMSAlertProps = {
	blok: AlertProps & { text: string };
};

export const CMSAlert: FC<CMSAlertProps> = ({ blok }) => {
	return (
		<div className="w-full py-10 px-4">
			<Alert {...blok}>{blok.text}</Alert>
		</div>
	);
};
