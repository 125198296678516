import * as Sentry from '@sentry/nextjs';
import { GraphQLClient } from 'graphql-request';

import {
	ObtainKrakenTokenDocument,
	ObtainKrakenTokenMutation,
	SdkFunctionWrapper,
	getSdk,
} from '@/services/typed-graphql-sdk';
import { logMessage } from '@/utils/logger';

export enum ErrorCode {
	AUTHORIZATION_HEADER_NOT_PROVIDED = 'KT_CT_1112',
	GPIN_DOES_NOT_EXIST = 'KT-JP-4606',
	INCORRECT_CREDENTIALS = 'KT-CT-1138',
	INVALID_POSTCODE = 'KT-JP-4601',
	JWT_EXPIRED = 'KT-CT-1124',
	REFRESH_TOKEN_NOT_FOUND = 'KT-CT-1135',
	SPIN_DOES_NOT_EXIST = 'KT-JP-4604',
	TOO_MANY_REQUESTS = 'KT-CT-1199',
	UNAUTHORIZED = 'KT-CT-1111',
}

if (process.env.NEXT_PUBLIC_GRAPHQL_API_URL === undefined) {
	throw 'NEXT_PUBLIC_GRAPHQL_API_URL environment variable not provided';
}

const client = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHQL_API_URL);

const clientWrapper: SdkFunctionWrapper = async (
	action,
	operationName,
	operationType,
	variables
) => {
	try {
		return await action();
	} catch (error) {
		// There are 4 potential error scenarios:
		// - GQL-01: Error with recognized error code, throw error.
		// - GQL-02: Error with unrecognized error code, report to Sentry.
		// - GQL-03: Error with unrecognized error code, throw error.
		// - GQL-04: Error without error code, throw error.
		const errorCode = error?.response?.errors?.[0].extensions?.errorCode;
		const errorObject = JSON.stringify(error);
		const variablesObject = JSON.stringify(variables);
		// Attempt to obtain the kraken token if the JWT has expired.
		switch (errorCode) {
			case ErrorCode.JWT_EXPIRED: {
				const refreshToken = window.localStorage.getItem('refreshToken');
				if (refreshToken) {
					const results = await client.request<ObtainKrakenTokenMutation>(
						ObtainKrakenTokenDocument,
						{ input: { refreshToken } },
						{ authorization: '' }
					);
					// Update the header authorization with the new token.
					if (results.obtainKrakenToken?.token) {
						client.setHeader(
							'authorization',
							`JWT ${results.obtainKrakenToken.token}`
						);
					}
					// If the token is successfully obtained, attempt to make the original request again.
					return await action();
				}
				break;
			}

			// Errors that do no need to be escalated to Sentry.
			case ErrorCode.AUTHORIZATION_HEADER_NOT_PROVIDED:
			case ErrorCode.GPIN_DOES_NOT_EXIST:
			case ErrorCode.INCORRECT_CREDENTIALS:
			case ErrorCode.INVALID_POSTCODE:
			case ErrorCode.REFRESH_TOKEN_NOT_FOUND:
			case ErrorCode.SPIN_DOES_NOT_EXIST:
			case ErrorCode.TOO_MANY_REQUESTS:
			case ErrorCode.UNAUTHORIZED: {
				throw new Error(
					`GQL01:${operationName}:${errorCode}:${variablesObject}:${errorObject}`,
					{
						cause: {
							...error,
							errorCode,
							operationName,
							operationType,
							variables,
						},
					}
				);
			}
		}

		if (errorCode) {
			logMessage(
				`GQL02:${operationName}:${errorCode}:${variablesObject}:${errorObject}`,
				{ variables, response: error.response },
				'info',
				{
					'graphql.name': operationName,
					'graphql.type': operationType,
					errorCode,
				}
			);

			throw new Error(
				`GQL03:${operationName}:${errorCode}:${variablesObject}:${errorObject}`,
				{ cause: { ...error, operationName, operationType, variables } }
			);
		}

		throw new Error(
			`GQL04:${operationName}:${variablesObject}:${errorObject}`,
			{ cause: { ...error, operationName, operationType, variables } }
		);
	}
};

const graphqlClient = getSdk(client, clientWrapper);

export const setHeaderWithValidation = (
	client: GraphQLClient,
	key: string,
	value: string | undefined
): void => {
	try {
		if (value == undefined) {
			throw new Error(`"${key}" header value not provided`);
		}
		client.setHeader(key, value);
	} catch (e) {
		Sentry.captureMessage(e.message);
	}
};

export const graphqlClientWithAuthSetters = {
	...graphqlClient,
	setAuthHeader: (value: string | undefined): void => {
		setHeaderWithValidation(client, 'authorization', value);
	},
	setWAFAccessKeyHeader: (value: string | undefined): void => {
		setHeaderWithValidation(client, 'waf-access-key', value);
	},
	setWAFAccessKeySecondaryHeader: (value: string | undefined): void => {
		setHeaderWithValidation(client, 'waf-access-key-secondary', value);
	},
};

export default graphqlClient;
