import Image from 'next/image';
import { FC } from 'react';

import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';

const BANNER_IMAGE_DESKTOP_DEFAULT_SRC =
	'https://a.storyblok.com/f/122730/6250x1667/f04d1308be/good-and-bad-points-01.png';
const BANNER_IMAGE_MOBILE_DEFAULT_SRC =
	'https://a.storyblok.com/f/122730/5001x2084/56ed4e0b92/good-and-bad-points-02.png';

type BannerImageProps = {
	blok: {
		desktop_image_height?: number;
		desktop_image_width?: number;
		image_desktop: {
			alt: string;
			filename: string;
		};
		image_mobile: {
			alt: string;
			filename: string;
		};
		mobile_image_height?: number;
		mobile_image_width?: number;
	};
};

export const BannerImage: FC<BannerImageProps> = ({
	blok: {
		image_desktop,
		image_mobile,
		mobile_image_height = 500,
		mobile_image_width = 1200,
		desktop_image_height = 400,
		desktop_image_width = 1500,
	},
}) => {
	const optimizedDesktopImage = optimizeStoryblokImage({
		src: image_desktop.filename || BANNER_IMAGE_DESKTOP_DEFAULT_SRC,
		options: { smartCrop: true, fitIn: true },
	});

	const optimizedMobileImage = optimizeStoryblokImage({
		src: image_mobile.filename || BANNER_IMAGE_MOBILE_DEFAULT_SRC,
		options: { smartCrop: true, fitIn: true },
	});

	return (
		<>
			<div className="relative hidden min-w-full sm:block">
				<Image
					src={optimizedDesktopImage}
					alt={image_desktop?.alt}
					width={desktop_image_width}
					height={desktop_image_height}
					sizes="100vw"
					style={{
						width: '100%',
						height: 'auto',
					}}
				/>
			</div>
			<div className="relative min-w-full sm:hidden">
				<Image
					src={optimizedMobileImage}
					alt={image_mobile?.alt}
					width={mobile_image_width}
					height={mobile_image_height}
					sizes="100vw"
					style={{
						width: '100%',
						height: 'auto',
					}}
				/>
			</div>
		</>
	);
};
