import { FC } from 'react';

const MastercardLogo: FC = () => {
	return (
		<svg
			aria-labelledby="Mastercard-logo"
			width="26"
			height="18"
			viewBox="0 0 26 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="26" height="18" rx="2" fill="#FFF4EE" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0856 13.2014C12.2292 13.9476 11.1183 14.398 9.90442 14.398C7.19578 14.398 5 12.1554 5 9.38894C5 6.62252 7.19578 4.37988 9.90442 4.37988C11.1183 4.37988 12.2292 4.83031 13.0856 5.57645C13.942 4.83031 15.0529 4.37988 16.2669 4.37988C18.9755 4.37988 21.1713 6.62252 21.1713 9.38894C21.1713 12.1554 18.9755 14.398 16.2669 14.398C15.0529 14.398 13.942 13.9476 13.0856 13.2014Z"
				fill="#ED0006"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.0854 13.2014C14.14 12.2827 14.8087 10.9156 14.8087 9.38894C14.8087 7.86232 14.14 6.49521 13.0854 5.57645C13.9419 4.83031 15.0528 4.37988 16.2667 4.37988C18.9753 4.37988 21.1711 6.62252 21.1711 9.38894C21.1711 12.1554 18.9753 14.398 16.2667 14.398C15.0528 14.398 13.9419 13.9476 13.0854 13.2014Z"
				fill="#F9A000"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.086 13.2022C14.1405 12.2835 14.8092 10.9163 14.8092 9.38973C14.8092 7.86311 14.1405 6.49599 13.086 5.57724C12.0315 6.49599 11.3628 7.86311 11.3628 9.38973C11.3628 10.9163 12.0315 12.2835 13.086 13.2022Z"
				fill="#FF5E00"
			/>

			<title id="Mastercard-logo" lang="ja">
				マスターカードのロゴ {/* Mastercard logo */}
			</title>
		</svg>
	);
};

export { MastercardLogo };
