import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { validationRegex } from '@/components/helpers/validationRegex';
import { JAPANESE_POSTCODE_CHARACTER_LENGTH } from '@/constants/constants';

export type HookParameters = {
	postcode: string;
};

export type HookAPI = {
	postcodeValidation: PostcodeValidation;
	setPostcodeValidation: Dispatch<SetStateAction<PostcodeValidation>>;
};

export type PostcodeValidation = 'IDLE' | 'VALID' | 'INVALID';

export const usePostcodeValidation = ({
	postcode,
}: HookParameters): HookAPI => {
	const [postcodeValidation, setPostcodeValidation] =
		useState<PostcodeValidation>('IDLE');

	useEffect(() => {
		if (postcode.length >= JAPANESE_POSTCODE_CHARACTER_LENGTH) {
			const status = postcode.match(validationRegex.postcode)
				? 'VALID'
				: 'INVALID';
			setPostcodeValidation(status);
		} else {
			setPostcodeValidation('IDLE');
		}
	}, [postcode]);

	return {
		postcodeValidation,
		setPostcodeValidation,
	};
};
