import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

type YoutubeVideoProps = {
	blok: {
		src: string;
	};
};

export const YoutubeVideo: FC<YoutubeVideoProps> = ({ blok }) => (
	// Styling for responsive full-width iframe
	// http://jsfiddle.net/pj2utq4v/1
	<Container>
		<div
			className="relative my-8 h-64 w-full md:h-96"
			style={{ paddingBottom: '56.25%' }}
		>
			<iframe
				title={blok.src}
				src={blok.src}
				frameBorder="0"
				allowFullScreen
				className="absolute top-0 left-0 h-full w-full"
			></iframe>
		</div>
	</Container>
);
