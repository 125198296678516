import { FC } from 'react';

const DinersClubCardLogo: FC = () => {
	return (
		<svg
			width="26"
			height="18"
			viewBox="0 0 26 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="diners-club-card-logo"
		>
			<title id="diners-club-card-logo" lang="ja">
				{/* Diners Club card logo */}
				ダイナースクラブカードのロゴ
			</title>
			<rect width="26" height="18" rx="2" fill="#EDF8FF" />
			<path
				d="M14.1477 14.6249C17.043 14.639 19.6856 12.219 19.6856 9.27517C19.6856 6.05615 17.043 3.83083 14.1477 3.83203H11.6558C8.72601 3.83083 6.31421 6.05675 6.31421 9.27517C6.31421 12.2196 8.72601 14.639 11.6558 14.6249H14.1477Z"
				fill="#0079BE"
			/>
			<path
				d="M11.6678 4.27832C8.99027 4.27922 6.82096 6.49012 6.82007 9.2189C6.82096 11.9474 8.99027 14.1583 11.6678 14.1592C14.3456 14.1583 16.5158 11.9474 16.5161 9.2189C16.5158 6.49012 14.3459 4.27922 11.6678 4.27832ZM8.59515 9.2189C8.59459 8.58798 8.78256 7.97157 9.13449 7.45022C9.48643 6.92887 9.98596 6.52684 10.5678 6.29667V12.1408C9.98591 11.9108 9.48632 11.5089 9.13437 10.9875C8.78242 10.4662 8.59448 9.8498 8.59515 9.2189ZM12.7675 12.142V6.29637C13.3497 6.52621 13.8497 6.92821 14.2019 7.44973C14.5541 7.97125 14.7421 8.58799 14.7413 9.2192C14.742 9.85038 14.5539 10.4671 14.2017 10.9886C13.8496 11.5101 13.3497 11.9121 12.7675 12.142Z"
				fill="white"
			/>
			<path
				d="M14.1477 14.6249C17.043 14.639 19.6856 12.219 19.6856 9.27517C19.6856 6.05615 17.043 3.83083 14.1477 3.83203H11.6558C8.72601 3.83083 6.31421 6.05675 6.31421 9.27517C6.31421 12.2196 8.72601 14.639 11.6558 14.6249H14.1477Z"
				fill="#0079BE"
			/>
			<path
				d="M11.6678 4.27832C8.99027 4.27922 6.82096 6.49012 6.82007 9.2189C6.82096 11.9474 8.99027 14.1583 11.6678 14.1592C14.3456 14.1583 16.5158 11.9474 16.5161 9.2189C16.5158 6.49012 14.3459 4.27922 11.6678 4.27832ZM8.59515 9.2189C8.59459 8.58798 8.78256 7.97157 9.13449 7.45022C9.48643 6.92887 9.98596 6.52684 10.5678 6.29667V12.1408C9.98591 11.9108 9.48632 11.5089 9.13437 10.9875C8.78242 10.4662 8.59448 9.8498 8.59515 9.2189ZM12.7675 12.142V6.29637C13.3497 6.52621 13.8497 6.92821 14.2019 7.44973C14.5541 7.97125 14.7421 8.58799 14.7413 9.2192C14.742 9.85038 14.5539 10.4671 14.2017 10.9886C13.8496 11.5101 13.3497 11.9121 12.7675 12.142Z"
				fill="white"
			/>
		</svg>
	);
};

export { DinersClubCardLogo };
