import { useRouter } from 'next/router';
import { createContext, FC, ReactNode, useContext, useEffect } from 'react';
import { send } from 'xstate';

import { Context, Event, machine, StateSchema } from '@/machines/auth/machine';
import { useMachine } from '@/machines/hooks';
import { ObtainKrakenTokenMutation } from '@/services/typed-graphql-sdk';

export type AuthContextValue = {
	handleSignOut: () => void;
	isCheckingAuthentication: boolean;
	isLoggedIn: boolean;
	isMasquerading: boolean;
	login: (data: NonNullable<ObtainKrakenTokenMutation>) => void;
};

export const AuthContext = createContext<AuthContextValue>({
	isLoggedIn: false,
	isMasquerading: false,
	isCheckingAuthentication: true,
	handleSignOut: () => {
		send({ type: 'SIGN_OUT' });
	},
	login: (data) => {
		send({ type: 'LOGIN', data });
	},
});

export type AuthProviderProps = {
	children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const router = useRouter();
	const [state, send] = useMachine<Context, StateSchema, Event>(
		machine.withContext({ ...machine.context, router })
	);

	useEffect(() => {
		// When a user signs out, ensure they are signed out in in all other tabs.
		// This is done by listening to when the localStorage refresh token is set to null.
		const handleSignOut = ({ key, newValue }: StorageEvent) => {
			if (key === 'refreshToken' && newValue === null) {
				send({ type: 'SIGN_OUT' });
			}
		};

		window.addEventListener('storage', handleSignOut);

		return () => window.removeEventListener('storage', handleSignOut);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isCheckingAuthentication: state.hasTag('checkingAuthentication'),
				isLoggedIn: state.matches('loggedIn'),
				isMasquerading: state.matches('loggedIn.masquerading'),
				handleSignOut: () => send({ type: 'SIGN_OUT' }),
				login: (data) => send({ type: 'LOGIN', data }),
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);
