import { useState, useEffect } from 'react';

import { isBrowser } from '@/utils/browser/isBrowser';

type BrowserHeadlessType = 'loading' | 'headless' | 'default';

export const useCheckHeadless = (): {
	isBrowserHeadless: BrowserHeadlessType;
} => {
	const [isBrowserHeadless, setIsBrowserHeadless] =
		useState<BrowserHeadlessType>('loading');

	const isHeadlessLink =
		(isBrowser() &&
			new URLSearchParams(window.location.search).get('headless')) ||
		false;

	useEffect(() => {
		setIsBrowserHeadless(isHeadlessLink ? 'headless' : 'default');
	}, [isHeadlessLink]);

	return {
		isBrowserHeadless,
	};
};
