import {
	Machine,
	MachineConfig,
	ConditionPredicate,
	ActionFunctionMap,
} from 'xstate';

/* eslint-disable @typescript-eslint/ban-types */
export interface StateSchema {
	states: {
		checkingPrivacyCookie: {};
		closedMessage: {};
		dismissedPrivacyCookieMessage: {};
		initial: {};
		showingPrivacyCookieMessage: {};
	};
}
/* eslint-enable @typescript-eslint/ban-types */

export type Event =
	| {
			type: 'LOAD';
	  }
	| {
			type: 'DISMISS_COOKIE_MESSAGE';
	  }
	| {
			type: 'CLOSE_MESSAGE';
	  };

const machineConfig: MachineConfig<unknown, StateSchema, Event> = {
	id: 'cookies',
	initial: 'initial',
	states: {
		initial: {
			on: {
				LOAD: 'checkingPrivacyCookie',
			},
		},
		checkingPrivacyCookie: {
			always: [
				{
					target: 'dismissedPrivacyCookieMessage',
					cond: 'hasDismissedPrivacyCookie',
				},
				{
					target: 'showingPrivacyCookieMessage',
					cond: 'hasNotDismissedPrivacyCookie',
				},
			],
		},
		dismissedPrivacyCookieMessage: {
			entry: ['setCookies'],
			type: 'final',
		},
		showingPrivacyCookieMessage: {
			on: {
				DISMISS_COOKIE_MESSAGE: {
					target: 'dismissedPrivacyCookieMessage',
					actions: ['setDismissedPrivacyCookieMessage', 'setCookies'],
				},
				CLOSE_MESSAGE: 'closedMessage',
			},
		},
		closedMessage: {
			type: 'final',
		},
	},
};

const guards: Record<string, ConditionPredicate<unknown, Event>> = {
	hasNotDismissedPrivacyCookie: () => {
		try {
			return !window.localStorage.getItem('dismissedPrivacyCookieMessage');
		} catch {
			return false;
		}
	},
	hasDismissedPrivacyCookie: () => {
		try {
			return !!window.localStorage.getItem('dismissedPrivacyCookieMessage');
		} catch {
			return false;
		}
	},
};

const actions: ActionFunctionMap<unknown, Event> = {
	setDismissedPrivacyCookieMessage: () =>
		window.localStorage.setItem('dismissedPrivacyCookieMessage', '1'),
	// https://stackoverflow.com/questions/60173853/how-to-set-the-google-analytics-cookie-only-after-another-consent-cookie-is-set
	setCookies: () => {
		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`;
		const x = document.getElementById('gtag');
		if (x && x.parentNode) {
			x.parentNode.insertBefore(s, x);
		} else {
			return;
		}
		// Append the following noscript tag to the DOM
		// <noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-XXXXX"
		// height="0" width="0" style="display:none;visibility:hidden"></iframe>`}}></noscript>
		//
		// Taken from https://www.learnbestcoding.com/post/9/easiest-way-to-integrate-google-analytics-with-react-js-and-next-js
		const gtmNoscriptElement = document.createElement('noscript');
		const gtmIframe = document.createElement('iframe');
		gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`;
		gtmIframe.style.height = '0';
		gtmIframe.style.width = '0';
		gtmIframe.style.visibility = 'hidden';
		gtmIframe.style.display = 'none';
		gtmNoscriptElement.appendChild(gtmIframe);
		const gtm = document.getElementById('gtm-script');
		if (gtm && gtm.parentNode) {
			gtm.parentNode.insertBefore(gtmIframe, gtm);
		} else {
			return;
		}
	},
};

const machine = Machine(machineConfig, { guards, actions });

export { machine };
