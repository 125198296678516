import {
	CSSProperties,
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import styles from '@/components/shared/Sparkles.module.css';
import { random } from '@/utils/random';

//www.joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion/

const QUERY = '(prefers-reduced-motion: no-preference)';

const isRenderingOnServer = typeof window === 'undefined';
const getInitialState = () => {
	// For our initial server render, we won't know if the user
	// prefers reduced motion, but it doesn't matter. This value
	// will be overwritten on the client, before any animations
	// occur.
	return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches;
};
function usePrefersReducedMotion() {
	const [prefersReducedMotion, setPrefersReducedMotion] =
		useState(getInitialState);
	useEffect(() => {
		const mediaQueryList = window.matchMedia(QUERY);
		const listener = (event: MediaQueryListEvent) => {
			setPrefersReducedMotion(!event.matches);
		};
		mediaQueryList.addListener(listener);
		return () => {
			mediaQueryList.removeListener(listener);
		};
	}, []);
	return prefersReducedMotion;
}

const useRandomInterval = (
	callback: () => void,
	minDelay: number | null,
	maxDelay: number | null
) => {
	const timeoutId = useRef<number | null>(null);
	const savedCallback = useRef(callback);
	useEffect(() => {
		savedCallback.current = callback;
	});
	useEffect(() => {
		const isEnabled =
			typeof minDelay === 'number' && typeof maxDelay === 'number';
		if (isEnabled) {
			const handleTick = () => {
				const nextTickAt = random(minDelay, maxDelay);
				timeoutId.current = window.setTimeout(() => {
					savedCallback.current();
					handleTick();
				}, nextTickAt);
			};
			handleTick();
		}
		return () => {
			if (timeoutId.current !== null) {
				window.clearTimeout(timeoutId.current);
			}
		};
	}, [minDelay, maxDelay]);
	const cancel = useCallback(function () {
		if (timeoutId.current !== null) {
			window.clearTimeout(timeoutId.current);
		}
	}, []);
	return cancel;
};

const DEFAULT_COLOR = '#FFC700';
const generateSparkle = (color: string) => ({
	id: String(random(10000, 99999)),
	createdAt: Date.now(),
	color,
	size: random(10, 20),
	style: { top: random(0, 80) + '%', left: random(0, 80) + '%' },
});

type SparklesProps = {
	children: ReactNode;
	color?: string;
};

const Sparkles: FC<SparklesProps> = ({
	color = DEFAULT_COLOR,
	children,
	...delegated
}) => {
	const [sparkles, setSparkles] = useState<
		ReturnType<typeof generateSparkle>[]
	>([]);
	const prefersReducedMotion = usePrefersReducedMotion();
	useRandomInterval(
		() => {
			const now = Date.now();
			setSparkles((oldSparkles) =>
				oldSparkles
					.filter((sp) => {
						const delta = now - sp.createdAt;
						return delta < 750;
					})
					.concat(generateSparkle(color))
			);
		},
		prefersReducedMotion ? null : 50,
		prefersReducedMotion ? null : 450
	);

	return (
		<span className="relative inline-block" {...delegated}>
			{sparkles
				? sparkles.map((sparkle) => (
						<Sparkle
							key={sparkle.id}
							color={sparkle.color}
							size={sparkle.size}
							style={sparkle.style}
						/>
					))
				: null}
			<strong className="relative font-bold" style={{ zIndex: 1 }}>
				{children}
			</strong>
		</span>
	);
};

type SparkleProps = {
	color: string;
	size: number | string;
	style: CSSProperties;
};

const Sparkle: FC<SparkleProps> = ({ size, color, style }) => {
	const path =
		'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 17.9837 26.5 25.5Z';
	return (
		<span className={styles.wrapper} style={style}>
			<svg
				className={styles.svg}
				width={size}
				height={size}
				viewBox="0 0 68 68"
				fill="none"
			>
				<path d={path} fill={color} />
			</svg>
		</span>
	);
};

export { Sparkles };
