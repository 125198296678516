import { Chip as CoralChip } from '@krakentech/coral';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

export type ChipProps = {
	blok: {
		color?:
			| 'primary'
			| 'secondary'
			| 'tertiary'
			| 'success'
			| 'warning'
			| 'error';
		text: string;
	};
};

export const Chip: FC<ChipProps> = ({ blok }) => {
	const { text, color = 'primary' } = blok;

	return (
		<div className="w-full">
			<Container className="flex w-full items-center py-4">
				<CoralChip color={color}>{text}</CoralChip>
			</Container>
		</div>
	);
};

// Will add background color in future
