import { FC } from 'react';

import { PostcodeFormVariantProps } from '@/components/shared/PostcodeForm/PostcodeForm';
import { copy } from '@/copy';

export const PostcodeFormCMSVariant: FC<PostcodeFormVariantProps> = ({
	postcodeInputProps,
	postcodeButtonProps,
	postcodeButtonText,
	postcodeInputLabelText,
	postcodePlaceholderText,
	className,
}) => {
	const inputProps = {
		...postcodeInputProps,
		placeholder: postcodePlaceholderText,
	};
	return (
		<>
			<label htmlFor="postcode-input">
				<span className="break-all text-xl font-bold md:text-3xl">
					{postcodeInputLabelText}
				</span>
				<div className={`mt-8 flex flex-col ${className}`}>
					<div className="flex flex-nowrap lg:w-quote-button-standard">
						<div className="flex-1 rounded-l-lg">
							<input
								aria-label="postcode-input"
								data-testid="postcode-input"
								className="h-quote-button-standard w-full rounded-l-lg border-none p-4 text-lg text-black outline-none placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-soholights lg:p-4 xl:p-6"
								{...inputProps}
								type="search"
							/>
						</div>
						<div className="rounded-r-lg">
							<button
								data-testid="postcode-input-button"
								className="h-quote-button-standard select-none whitespace-nowrap rounded-r-lg bg-soholights p-4 text-lg font-bold text-black outline-none transition-all hover:bg-akoyaprimary hover:saturate-130 focus:ring-2 focus:ring-inset focus:ring-white disabled:opacity-50 lg:p-4 xl:p-6"
								{...postcodeButtonProps}
								type="submit"
							>
								{postcodeButtonText || copy.goToApplication}
							</button>
						</div>
					</div>
				</div>
			</label>
		</>
	);
};
