import { Skeleton } from '@krakentech/coral';
import { FC } from 'react';

import { ReferralLinkShareCardProps } from '@/components/storyblok/bloks/friend-components/ReferralLinkShareCardBlok';
import { DynamicComponent } from '@/components/storyblok/index';
import { StoryContent } from '@/types/storyblok';

type LoggedInOrOutVisibilityProps = {
	props: {
		isLoading?: string;
		loggedInStatus?: string;
	};
};

type LoggedInOrOutVisibilityWrapperProps = {
	blok: {
		body?: StoryContent;
		is_visible_when?: string[];
	};
} & LoggedInOrOutVisibilityProps &
	ReferralLinkShareCardProps;

export const LoggedInOrOutVisibilityWrapper: FC<
	LoggedInOrOutVisibilityWrapperProps
> = ({ blok, props }) => {
	const isVisible = blok.is_visible_when?.includes(
		props?.loggedInStatus as string
	);

	return isVisible ? (
		<div className="w-full">
			{blok.body?.map((blok) =>
				props?.isLoading ? (
					<div className="flex w-full justify-center" key={blok._uid}>
						<Skeleton variant="rectangular" animation="wave">
							<DynamicComponent blok={blok} props={props} key={blok._uid} />
						</Skeleton>
					</div>
				) : (
					<DynamicComponent blok={blok} props={props} key={blok._uid} />
				)
			)}
		</div>
	) : null;
};
