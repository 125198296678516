/**
 * @returns a memoized version of the originalFunction.
 *   If a timeToLiveInMilliseconds is given,
 *     for each set of arguments, the memo for those arguments will expire and
 *     the originalFunction will be called again.
 */
export function memoize<T extends Array<unknown>, U>(
	originalFunction: (...args: T) => U,
	{ timeToLiveInMilliseconds }: { timeToLiveInMilliseconds?: number } = {}
): (...args: T) => U {
	const memo: Record<string, { expirationTimestamp?: number; value: U }> = {};

	const memoizedFunction = (...args: T) => {
		const memoKey = JSON.stringify(args);
		const hasMemoExpiredYet =
			timeToLiveInMilliseconds !== undefined &&
			memo[memoKey]?.expirationTimestamp !== undefined &&
			Number(memo[memoKey].expirationTimestamp) < Date.now();
		if (!memo[memoKey] || hasMemoExpiredYet) {
			memo[memoKey] = {
				...(timeToLiveInMilliseconds !== undefined
					? { expirationTimestamp: Date.now() + timeToLiveInMilliseconds }
					: {}),
				value: originalFunction(...args),
			};
		}
		return memo[memoKey].value;
	};
	return memoizedFunction;
}
