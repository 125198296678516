import Image from 'next/image';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';

export type CMSPdfLinkProps = {
	blok: {
		caption?: string;
		document: {
			filename: string;
		};
		label: string;
	};
};

const CMSPdfLink: FC<CMSPdfLinkProps> = ({
	blok: { caption, document, label },
}) => (
	<Container className="py-2">
		<div className="flex items-center space-x-2">
			<a
				href={document.filename}
				target="_blank"
				className="flex items-center space-x-4"
				rel="noreferrer"
			>
				<Image
					width={30}
					height={40}
					src="/icons/pdf.png"
					alt="PDF"
					style={{
						maxWidth: '100%',
						height: 'auto',
					}}
				/>
				<p className="font-bold text-soholights underline">{label}</p>
			</a>
			<p>(PDF)</p>
			{caption ? <p>{caption}</p> : null}
		</div>
	</Container>
);

export { CMSPdfLink };
