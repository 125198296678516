import {
	CoralPadding,
	CoralSpacing,
	Stack as CoralStack,
} from '@krakentech/coral';
import clsx from 'clsx';
import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { StoryblokComponentProps, StoryContent } from '@/types/storyblok';

type Direction =
	| 'vertical'
	| 'horizontal'
	| 'vertical-reverse'
	| 'horizontal-reverse';
interface StackProps {
	blok: {
		background: 'primary' | 'secondary';
		children: StoryContent;
		direction?: Direction;
		direction_mobile?: Direction;
		gap?: keyof CoralSpacing | 0;
		gap_mobile?: keyof CoralSpacing | 0;
		padding_x?: CoralPadding;
		padding_y?: CoralPadding;
	};
	props?: StoryblokComponentProps;
}

export const Stack: FC<StackProps> = ({
	blok: {
		background,
		children,
		direction = 'horizontal',
		direction_mobile = 'vertical',
		gap = 'sm',
		gap_mobile = 'sm',
		padding_x,
		padding_y,
	},
	props,
}) => {
	const { breakpoint } = useBreakpoint();

	const responsiveDirection =
		breakpoint === 'sm' ? direction_mobile : direction;

	const responsiveGap = breakpoint === 'sm' ? gap_mobile : gap;

	return (
		<div
			className={clsx({
				'px-2': padding_x === 'xs',
				'py-2': padding_y === 'xs',
				'px-4': padding_x === 'sm',
				'py-4': padding_y === 'sm',
				'px-6': padding_x === 'md',
				'py-6': padding_y === 'md',
				'px-8': padding_x === 'lg',
				'py-8': padding_y === 'lg',
				'px-10': padding_x === 'xl',
				'py-10': padding_y === 'xl',
				'bg-primary': background === 'primary',
				'bg-secondary': background === 'secondary',
			})}
		>
			<CoralStack direction={responsiveDirection} gap={responsiveGap}>
				{children.map((blok) => (
					<CoralStack.Item key={blok._uid}>
						<DynamicComponent blok={blok} props={props} />
					</CoralStack.Item>
				))}
			</CoralStack>
		</div>
	);
};
