import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { noop } from '@/utils/noop';

export type AnalyticsContextProps = {
	isAnalyticsReady: boolean;
	setIsAnalyticsReady: (value: boolean) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextProps>({
	isAnalyticsReady: false,
	setIsAnalyticsReady: noop,
});

export const AnalyticsProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isAnalyticsReady, setIsAnalyticsReady] = useState(false);
	return (
		<AnalyticsContext.Provider
			value={{ isAnalyticsReady, setIsAnalyticsReady }}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalyticsContext = (): AnalyticsContextProps =>
	useContext(AnalyticsContext);
