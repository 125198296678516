import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Container } from '@/components/storyblok/bloks/Container';
import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
} from '@/components/storyblok/utils/constants';

export type CollapsibleDetailsRichTextProps = {
	blok: {
		is_open: boolean;
		rich_text: unknown;
		title: string;
	};
};

export const CollapsibleDetailsRichText: FC<
	CollapsibleDetailsRichTextProps
> = ({ blok: { title, rich_text, is_open } }) => {
	return (
		<Container className="py-4 text-lg">
			<details className="space-y-4" open={is_open}>
				<summary className="font-bold">{title}</summary>
				<section className="mx-auto max-w-3xl break-words text-lg leading-10">
					{render(rich_text, {
						...RICH_TEXT_CONSTANTS(),
						nodeResolvers: {
							...RICH_TEXT_NODE_RESOLVERS_HEADING,
						},
					})}
				</section>
			</details>
		</Container>
	);
};
