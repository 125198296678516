import clsx from 'clsx';
import Image from 'next/image';
import { FC } from 'react';

import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';

type CMSImageProps = {
	blok: {
		background_color?: string;
		desktop_image_width?: number;
		image_align?: string;
		image_desktop: {
			alt: string;
			filename: string;
		};
		no_padding?: boolean;
	};
};

export const CMSImage: FC<CMSImageProps> = ({
	blok: {
		background_color = 'bg-primary',
		desktop_image_width = 500,
		image_align = 'justify-start',
		image_desktop,
		no_padding = false,
	},
}) => {
	const optimizedDesktopImage = optimizeStoryblokImage({
		src: image_desktop.filename,
		options: { smartCrop: true, fitIn: true },
	});

	return image_desktop?.filename ? (
		<div
			className={clsx('flex w-full flex-col', background_color, {
				'py-10 px-4': !no_padding,
			})}
		>
			<div className={`relative flex min-w-full ${image_align}`}>
				<Image
					className="h-auto"
					src={optimizedDesktopImage}
					alt={image_desktop?.alt}
					width={desktop_image_width || 0}
					height={0} // height has no impact
					sizes="(max-width: 768px) 100vw"
				/>
			</div>
		</div>
	) : (
		<div>Please add an image</div>
	);
};
