/**
 * Generate an array of numbers.
 *
 * @param {number} start The start of the range.
 * @param {number} end The end of the range (not included).
 * @param {number} [step=1] The value to increment or decrement by.
 *
 * If `end` is not specified, we start at 0 and go until `start`
 *
 * @example
 * range(4) -> [0, 1, 2, 3]
 * range(0, -4, -1) -> [0, -1, -2, -3]
 * range(1, 5) -> [1, 2, 3, 4]
 * range(0, 20, 5) -> [0, 5, 10, 15]
 */
export const range = (start: number, end?: number, step = 1): number[] => {
	const output = [];

	if (typeof end === 'undefined') {
		end = start;
		start = 0;
	}

	if (Math.sign(step) > 0) {
		for (let i = start; i < end; i += step) {
			output.push(i);
		}
	} else {
		for (let i = start; i > end; i += step) {
			output.push(i);
		}
	}

	return output;
};
