import { differenceInSeconds, isFuture } from 'date-fns';
import { FC, ReactNode, useEffect, useState } from 'react';

import { CountdownTimerFrame } from '@/components/svgs/CountdownTimerFrame';

type CountDownType = {
	days: string;
	hours: string;
	minutes: string;
	seconds: string;
};

/**
 * Pads a number with "0"s (possibly repeated) so that the resulting string reaches a given length.
 * The padding is applied from the start (left) of the current string.
 *
 * @param num the number to pre-fix "0"'s to.
 * @param places the given length, or number of "0"'s to prefix.
 * @returns string - padded with zeros i.e 1 -> "01"
 */
const zeroPad = (num: number, places = 2) => String(num).padStart(places, '0');

const useCountdown = (
	endDate: Date,
	updateIntervalMilliseconds = 1000
): CountDownType => {
	const getReturnValues = (endDate: Date) => {
		const secondsDiff = differenceInSeconds(endDate, new Date());
		const days = zeroPad(Math.floor(secondsDiff / (60 * 60 * 24)));
		const hours = zeroPad(
			Math.floor((secondsDiff % (60 * 60 * 24)) / (60 * 60))
		);
		const minutes = zeroPad(Math.floor((secondsDiff % (60 * 60)) / 60));
		const seconds = zeroPad(Math.floor(secondsDiff % 60));
		return { days, hours, minutes, seconds };
	};
	const [values, setValues] = useState<CountDownType>(getReturnValues(endDate));

	useEffect(() => {
		const interval = setInterval(() => {
			setValues(getReturnValues(endDate));
		}, updateIntervalMilliseconds);
		return () => clearInterval(interval);
	}, [endDate]);

	return values;
};

type CountDownProps = {
	endDate?: string;
	stayTunedRichTextNode?: ReactNode;
};

export const CountDown: FC<CountDownProps> = ({
	endDate,
	stayTunedRichTextNode,
}) => {
	return endDate && isFuture(new Date(endDate)) ? (
		<CountDownTimer endDate={new Date(endDate)} />
	) : (
		<StayTunedDynamicFrame stayTunedRichTextNode={stayTunedRichTextNode} />
	);
};

const CountDownTimer: FC<{ endDate: Date }> = ({ endDate }) => {
	const { days, hours, minutes, seconds } = useCountdown(endDate);

	// Prevent hydration errors due to changing days, hours, minutes, seconds
	const [isClient, setIsClient] = useState(false);
	useEffect(() => setIsClient(true), []);
	if (!isClient) {
		// Reserve the components height to prevent CLS
		return <div className="h-[188px]" />;
	}

	return (
		<div
			data-testid="countdown-timer"
			className="m-auto grid w-auto max-w-[365px] text-ice"
		>
			<div className="col-start-1 row-start-1">
				<CountdownTimerFrame width={350} height="100%" />
			</div>
			<div className="col-start-1 row-start-1">
				<div className="p-16">
					{/* @todo: use font-family: Digital-7; */}
					<div className="flex gap-4 font-mono text-4xl font-bold">
						<span>{days}</span>
						<span>{hours}</span>
						<span>{minutes}</span>
						<span>{seconds}</span>
					</div>
					<div className="flex gap-6 text-sm">
						{/* Days */}
						<span className="ml-4">日</span>
						{/* Hours */}
						<span className="ml-3">時間</span>
						{/* Minutes */}
						<span className="ml-4">分</span>
						{/* Seconds */}
						<span className="ml-5">秒</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const StayTunedDynamicFrame: FC<{ stayTunedRichTextNode?: ReactNode }> = ({
	stayTunedRichTextNode,
}) => {
	return (
		<div
			data-testid="stay-tuned-dynamic-frame"
			className="m-auto grid w-[365px] text-ice"
		>
			<div className="col-start-1 row-start-1">
				<CountdownTimerFrame width={350} height="100%" />
			</div>
			<div className="col-start-1 row-start-1">
				<div className="flex h-full items-center justify-center">
					{stayTunedRichTextNode}
				</div>
			</div>
		</div>
	);
};
