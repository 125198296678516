/* eslint-disable no-irregular-whitespace */

/**
 * See: https://en.wikipedia.org/wiki/Halfwidth_and_Fullwidth_Forms_(Unicode_block)
 * ！   ＂	＃	＄	％	＆	＇	（	）	＊	＋	，	－	．	／
 * ０   １	２	３	４	５	６	７	８	９	：	；	＜	＝	＞	？
 * ＠	Ａ	Ｂ	Ｃ	Ｄ	Ｅ	Ｆ	Ｇ	Ｈ	Ｉ	Ｊ	Ｋ	Ｌ	Ｍ	Ｎ	Ｏ
 * Ｐ	Ｑ	Ｒ	Ｓ	Ｔ	Ｕ	Ｖ	Ｗ	Ｘ	Ｙ	Ｚ	［	＼	］	＾	＿
 * ｀	ａ	ｂ	ｃ	ｄ	ｅ	ｆ	ｇ	ｈ	ｉ	ｊ	ｋ	ｌ	ｍ	ｎ	ｏ
 * ｐ	ｑ	ｒ	ｓ	ｔ	ｕ	ｖ	ｗ	ｘ	ｙ	ｚ	｛	｜	｝	～
 */
const fullWidthCharacterRegex = /[！-～]/g;

const fullWidthSpaceRegex = /　/g;

/**
 * Convert Full-Width characters to Half-Width.
 * Example: "ａｂｃｄｅｆｇ　＋！１２３" -> "abcdefg +!123"
 *
 * @param value string to convert to half-width characters
 * @param maxLength maximum length of the string to be converted
 * @returns output string converted to half-width characters
 */
export const fullWidthToHalfWidth = (
	value: string,
	maxLength?: number
): string =>
	value
		.slice(0, maxLength)
		.replace(fullWidthSpaceRegex, ' ')
		.replace(fullWidthCharacterRegex, (halfwidthChar) =>
			String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0)
		);
