import { FC } from 'react';

import { FacebookLogo } from '@/components/svgs/FacebookLogo';

// Dec 2021: It is currently not possible to pre-fill the post's text like you can for Twitter
// when sharing to Facebook. source: https://stackoverflow.com/a/5799060/12679806
// Facebook will use the page's meta tags to provide an OGP style card attached to the post.
export const ReferralFacebookShareIcon: FC<{
	shareLink: string;
}> = ({ shareLink }) => (
	<a
		href={`http://www.facebook.com/sharer.php?u=${shareLink}`}
		target="_blank"
		aria-label="フェイスブックでシェアする" // Share to Facebook
		rel="noreferrer noopener"
	>
		<FacebookLogo />
	</a>
);
