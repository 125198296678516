import { getCookie } from 'cookies-next';
import { differenceInMilliseconds, fromUnixTime } from 'date-fns';
import { NextRouter } from 'next/router';
import {
	ActionFunctionMap,
	assign,
	ConditionPredicate,
	Machine,
	MachineConfig,
	send,
	ServiceConfig,
} from 'xstate';

import { ONE_MINUTE_IN_MILLISECONDS } from '@/constants/constants';
import { assertEventType } from '@/machines/type-guards';
import { graphqlClientWithAuthSetters as graphqlClient } from '@/services/graphql-client';
import {
	MasqueradeAuthenticationMutation,
	ObtainKrakenTokenMutation,
} from '@/services/typed-graphql-sdk';

export interface StateSchema {
	states: {
		checkingMWAuthToken: {
			states: {
				initial: Record<string, unknown>;
				invalidMWAuthToken: Record<string, unknown>;
				validatingMWAuthToken: Record<string, unknown>;
			};
		};
		checkingMasqueradeToken: {
			states: {
				initial: Record<string, unknown>;
				invalidMasqueradeAttempt: Record<string, unknown>;
				validatingMasqueradeToken: Record<string, unknown>;
			};
		};
		checkingRefreshTokenValid: {
			states: {
				initial: Record<string, unknown>;
				refreshingAuthenticationToken: Record<string, unknown>;
			};
		};
		loggedIn: {
			states: {
				idle: Record<string, unknown>;
				masquerading: Record<string, unknown>;
				refreshingToken: Record<string, unknown>;
				usingMWAuthToken: Record<string, unknown>;
			};
		};
		notLoggedIn: Record<string, unknown>;
	};
}

export type Event =
	| {
			data: ObtainKrakenTokenMutation;
			type: 'LOGIN';
	  }
	| {
			type: 'SIGN_OUT';
	  }
	| {
			type: 'error.platform.searchLocalStorageForToken';
	  }
	| {
			data: ObtainKrakenTokenMutation;
			type: 'done.invoke.refreshToken';
	  }
	| {
			data: ObtainKrakenTokenMutation;
			type: 'done.invoke.refreshAuthenticationToken';
	  }
	| {
			data: {
				refreshToken: string;
			};
			type: 'done.invoke.searchLocalStorageForToken';
	  }
	| {
			type: 'REFRESH';
	  }
	| {
			data: MasqueradeAuthenticationMutation;
			type: 'done.invoke.validateMasqueradeToken';
	  }
	| {
			data: {
				MWAuthToken: string;
			};
			type: 'done.invoke.validateMWAuthToken';
	  };

export interface Context {
	graphqlClient: typeof graphqlClient;
	refreshToken: string | undefined;
	router?: NextRouter;
	shouldRedirectOnLogin: boolean;
	tokenExpiresIn: number | undefined;
}

const machineConfig: MachineConfig<Context, StateSchema, Event> = {
	id: 'auth',
	initial: 'checkingMWAuthToken',
	context: {
		tokenExpiresIn: undefined,
		graphqlClient,
		refreshToken: undefined,
		shouldRedirectOnLogin: true,
	},
	states: {
		checkingMWAuthToken: {
			tags: 'checkingAuthentication',
			initial: 'initial',
			states: {
				initial: {
					meta: 'Check if MWAuthToken present in cookies',
					always: [
						{
							target: 'validatingMWAuthToken',
							cond: 'isMWAuthToken',
						},
						{
							// If not MWAuth link then move on to check for masquerade
							target: '#auth.checkingMasqueradeToken',
							cond: 'isNotMWAuthToken',
						},
					],
				},
				validatingMWAuthToken: {
					invoke: {
						src: 'validateMWAuthToken',
						onDone: {
							target: '#auth.loggedIn.usingMWAuthToken',
							actions: 'setMWAuthToken',
						},
						onError: {
							target: 'invalidMWAuthToken',
							actions: 'redirect404',
						},
					},
				},
				invalidMWAuthToken: {
					type: 'final',
				},
			},
		},
		checkingMasqueradeToken: {
			tags: 'checkingAuthentication',
			initial: 'initial',
			states: {
				initial: {
					meta: 'Check whether url includes "/masquerade"',
					always: [
						{
							target: 'validatingMasqueradeToken',
							cond: 'isMasqueradeUrl',
						},
						{
							// User is not masquerading. Continue auth checks as normal.
							target: '#auth.checkingRefreshTokenValid',
							cond: 'isNotMasqueradeUrl',
						},
					],
				},
				validatingMasqueradeToken: {
					invoke: {
						src: 'validateMasqueradeToken',
						onDone: {
							target: '#auth.loggedIn.masquerading',
							actions: 'setMasqueradeToken',
						},
						onError: {
							target: 'invalidMasqueradeAttempt',
							actions: 'redirect404',
						},
					},
				},
				invalidMasqueradeAttempt: {
					type: 'final',
				},
			},
		},
		checkingRefreshTokenValid: {
			tags: 'checkingAuthentication',
			initial: 'initial',
			states: {
				initial: {
					invoke: {
						src: 'searchLocalStorageForToken',
						onDone: {
							target: 'refreshingAuthenticationToken',
						},
						onError: {
							target: '#auth.notLoggedIn',
							actions: 'assignShouldRedirectOnLoginToTrue', // The user is trying to access a protected route, they should be redirected after log in
						},
					},
				},
				refreshingAuthenticationToken: {
					invoke: {
						src: 'refreshAuthenticationToken',
						onDone: {
							target: '#auth.loggedIn',
							actions: ['setToken', 'setTokenExpiresIn', 'setRefreshToken'],
						},
						onError: {
							target: '#auth.notLoggedIn',
							actions: 'removeRefreshTokenFromLocalStorage',
						},
					},
				},
			},
		},
		notLoggedIn: {
			on: {
				LOGIN: {
					target: 'loggedIn',
					actions: [
						'setTokenFromLogin',
						'setTokenExpiresInFromLogin',
						'setRefreshTokenFromLogin',
						'setRefreshTokenInLocalStorage',
						'resetIsDismissedByCtaId',
					],
				},
			},
		},
		loggedIn: {
			on: {
				SIGN_OUT: {
					target: '#auth.notLoggedIn',
					actions: [
						'removeToken',
						'removeRefreshTokenFromLocalStorage',
						'assignShouldRedirectOnLoginToFalse', // We shouldn't redirect the user if they manually log out and log in again; they should be pushed to /account
					],
				},
			},
			initial: 'idle',
			states: {
				idle: {
					meta: {
						message: 'Attempt refresh 1min before expiry of token',
					},
					entry: 'sendDelayedRefreshEvent',
					on: {
						REFRESH: 'refreshingToken',
					},
				},
				refreshingToken: {
					invoke: {
						src: 'refreshToken',
						onDone: {
							target: 'idle',
							actions: 'setTokenFromRefresh',
						},
						onError: '#auth.notLoggedIn',
					},
				},
				masquerading: {},
				usingMWAuthToken: {},
			},
		},
	},
};

export function differenceInMillisecondsBetweenNowAndUnixTime(
	timestamp: number
): number {
	return differenceInMilliseconds(
		fromUnixTime(timestamp),
		new Date(Date.now())
	);
}

const actions: ActionFunctionMap<Context, Event> = {
	setToken: (ctx, evt) => {
		assertEventType(evt, 'done.invoke.refreshAuthenticationToken');
		if (!evt.data.obtainKrakenToken?.token) {
			throw 'Data from response missing';
		}
		ctx.graphqlClient.setAuthHeader(`JWT ${evt.data.obtainKrakenToken?.token}`);
	},
	setRefreshToken: assign((_, evt) => {
		assertEventType(evt, 'done.invoke.refreshAuthenticationToken');
		if (!evt.data.obtainKrakenToken?.refreshToken) {
			throw 'Data payload missing';
		}
		return {
			refreshToken: evt.data.obtainKrakenToken?.refreshToken,
		};
	}),
	setRefreshTokenFromLogin: assign((_, evt) => {
		assertEventType(evt, 'LOGIN');
		if (!evt.data.obtainKrakenToken?.refreshToken) {
			throw 'setRefreshTokenFromLogin: Token not provided';
		}
		return {
			refreshToken: evt.data.obtainKrakenToken?.refreshToken,
		};
	}),
	setTokenFromLogin: (ctx, evt) => {
		assertEventType(evt, 'LOGIN');
		if (!evt.data.obtainKrakenToken?.token) {
			throw 'setTokenFromLogin: Token not provided';
		}
		ctx.graphqlClient.setAuthHeader(`JWT ${evt.data.obtainKrakenToken?.token}`);
	},
	setTokenExpiresInFromLogin: assign((_, evt) => {
		assertEventType(evt, 'LOGIN');
		if (!evt.data.obtainKrakenToken?.payload) {
			throw 'Data missing';
		}

		const tokenExpiresIn = differenceInMillisecondsBetweenNowAndUnixTime(
			evt.data.obtainKrakenToken?.payload.exp
		);
		return {
			tokenExpiresIn,
		};
	}),
	setTokenExpiresIn: assign((_, evt) => {
		assertEventType(evt, 'done.invoke.refreshAuthenticationToken');
		if (!evt.data.obtainKrakenToken?.payload) {
			throw 'Data from response missing';
		}
		const tokenExpiresIn = differenceInMillisecondsBetweenNowAndUnixTime(
			evt.data.obtainKrakenToken?.payload.exp
		);
		return {
			tokenExpiresIn,
		};
	}),
	setRefreshTokenInLocalStorage: (_, evt) => {
		assertEventType(evt, 'LOGIN');
		if (!evt.data.obtainKrakenToken?.refreshToken) {
			throw 'setRefreshTokenInLocalStorage: Token not provided';
		}
		window.localStorage.setItem(
			'refreshToken',
			evt.data.obtainKrakenToken.refreshToken
		);
	},
	removeToken: (ctx) => {
		ctx.graphqlClient.setAuthHeader('');
	},
	removeRefreshTokenFromLocalStorage: () => {
		window.localStorage.removeItem('refreshToken');
	},
	resetIsDismissedByCtaId: () => {
		Object.keys(window.sessionStorage)
			.filter((key) => key.startsWith('isDismissedByCtaId'))
			.forEach((key) => window.sessionStorage.removeItem(key));
	},
	setTokenFromRefresh: (ctx, evt) => {
		assertEventType(evt, 'done.invoke.refreshToken');
		if (!evt.data.obtainKrakenToken?.token) {
			throw 'setTokenFromRefresh: Token not provided';
		}
		ctx.graphqlClient.setAuthHeader(`JWT ${evt.data.obtainKrakenToken?.token}`);
	},
	sendDelayedRefreshEvent: send('REFRESH', {
		delay: (ctx) => {
			if (ctx.tokenExpiresIn === undefined) {
				throw 'tokenExpiresIn is undefined';
			}
			const MILLISECONDS_UNTIL_REFRESH =
				ctx.tokenExpiresIn - ONE_MINUTE_IN_MILLISECONDS;
			return MILLISECONDS_UNTIL_REFRESH;
		},
	}),
	setMasqueradeToken: (ctx, evt) => {
		assertEventType(evt, 'done.invoke.validateMasqueradeToken');
		if (
			!evt.data.masqueradeAuthentication ||
			!evt.data.masqueradeAuthentication.token
		) {
			throw 'Data from masquerade mutation response missing';
		}
		ctx.graphqlClient.setAuthHeader(
			`${evt.data.masqueradeAuthentication.token}`
		);
		ctx.router?.push('/account');
	},
	setMWAuthToken: (ctx, evt) => {
		assertEventType(evt, 'done.invoke.validateMWAuthToken');
		if (!evt.data?.MWAuthToken) {
			throw 'MWAuthToken missing';
		}
		ctx.graphqlClient.setAuthHeader(`JWT ${evt.data?.MWAuthToken}`);
	},
	redirect404: (ctx) => {
		ctx.router?.push('/404');
	},
	assignShouldRedirectOnLoginToTrue: assign((_, evt) => {
		assertEventType(evt, 'error.platform.searchLocalStorageForToken');
		return {
			shouldRedirectOnLogin: true,
		};
	}),
	assignShouldRedirectOnLoginToFalse: assign((_, evt) => {
		assertEventType(evt, 'SIGN_OUT');
		return {
			shouldRedirectOnLogin: false,
		};
	}),
};

const services: Record<string, ServiceConfig<Context, Event>> = {
	searchLocalStorageForToken: () =>
		new Promise((resolve, reject) => {
			const refreshToken = window.localStorage.getItem('refreshToken');
			if (refreshToken) {
				resolve({ refreshToken });
			}
			reject();
		}),
	refreshAuthenticationToken: (_, evt) => {
		assertEventType(evt, 'done.invoke.searchLocalStorageForToken');
		return graphqlClient.obtainKrakenToken({
			input: {
				refreshToken: evt.data.refreshToken,
			},
		});
	},
	refreshToken: (ctx) => {
		if (ctx.refreshToken === undefined) {
			throw 'Refresh token is undefined';
		}
		return graphqlClient.obtainKrakenToken({
			input: {
				refreshToken: ctx.refreshToken,
			},
		});
	},
	validateMasqueradeToken: () => {
		/* Shape of URL: '[baseUrl]/masquerade/[userId]/[masqueradeToken] */
		const paths = window.location.pathname.split('/');
		const [masqueradeToken, userId] = paths.reverse();
		if (!userId || !masqueradeToken) {
			throw 'User ID or masquerade token missing from url';
		}
		return graphqlClient.masqueradeAuthentication({
			masqueradeToken,
			userId,
		});
	},
	validateMWAuthToken: () =>
		new Promise((resolve, reject) => {
			const MWAuthToken = getCookie('MWAuthToken');
			if (MWAuthToken) {
				resolve({ MWAuthToken });
			}
			reject();
		}),
};

const guards: Record<string, ConditionPredicate<Context, Event>> = {
	isMasqueradeUrl: (context) => {
		return context.router?.pathname.startsWith('/masquerade') === true;
	},
	isNotMasqueradeUrl: (context) => {
		return context.router?.pathname.startsWith('/masquerade') !== true;
	},
	isMWAuthToken: () => {
		return Boolean(getCookie('MWAuthToken')) === true;
	},
	isNotMWAuthToken: () => {
		return Boolean(getCookie('MWAuthToken')) !== true;
	},
};
const machine = Machine(machineConfig, { actions, services, guards });

export { machine };
