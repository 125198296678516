import clsx from 'clsx';
import { FC } from 'react';
import {
	NODE_IMAGE,
	NODE_UL,
	render,
} from 'storyblok-rich-text-react-renderer';

import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
	RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
	textAlignMap,
} from '@/components/storyblok/utils/constants';
import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';

type RichTextProps = {
	blok: {
		background: 'primary' | 'secondary';
		no_padding?: boolean;
		rich_text: unknown;
		text_align: 'left' | 'right' | 'center';
	};
	props: any;
};

export const RichText: FC<RichTextProps> = ({
	blok: { no_padding = false, rich_text, background, text_align },
	props,
}) => (
	<div
		className={clsx('w-full', textAlignMap[text_align], {
			'py-3 px-4': !no_padding,
			'bg-primary': background === 'primary',
			'bg-secondary': background === 'secondary',
		})}
	>
		<section className="mx-auto max-w-3xl break-words text-lg leading-10">
			{render(rich_text, {
				...RICH_TEXT_CONSTANTS(props),
				nodeResolvers: {
					...RICH_TEXT_NODE_RESOLVERS_HEADING,
					...RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS(text_align),
					[NODE_UL]: (children) => (
						<ul className="my-4 list-disc pl-4 md:pl-8">{children}</ul>
					),
					[NODE_IMAGE]: (children, props) =>
						props.src ? (
							<img
								{...props}
								alt={props.alt ?? ''}
								src={optimizeStoryblokImage({ src: props.src })}
							>
								{children}
							</img>
						) : null,
				},
			})}
		</section>
	</div>
);
