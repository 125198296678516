function request<T>(
	method: 'GET' | 'POST',
	endpoint: string,
	customConfig?: {
		[key: string]: unknown;
		body?: string;
		headers?: Record<string, unknown>;
	},
	body?: Record<string, unknown>
): Promise<T> {
	const headers = { 'Content-Type': 'application/json' };

	const POSTMethodConfig = body ? { body: JSON.stringify(body) } : {};

	const config = {
		method,
		...customConfig,
		headers: {
			...headers,
			...customConfig?.headers,
		},
		...(method === 'POST' && POSTMethodConfig),
	};

	return window.fetch(endpoint, config).then(async (response) => {
		if (response.ok) {
			return await response.json();
		} else {
			const errorMessage = await response.text();
			return Promise.reject(new Error(errorMessage));
		}
	});
}

const get = <T>(
	endpoint: string,
	config?: Record<string, unknown>
): Promise<T> => request<T>('GET', endpoint, config);

const post = <T>(
	endpoint: string,
	body: Record<string, unknown>,
	config?: Record<string, unknown>
): Promise<T> => request<T>('POST', endpoint, config, body);

export default {
	get,
	post,
};
