import { Grid, Skeleton } from '@krakentech/coral';
import { FC, useEffect, useState } from 'react';

import { BlogCard } from '@/components/pages/home/HomePageSectionBlog';
import apiClient from '@/services/api-client';
import { BlogpostItemCardFragment } from '@/services/graphql-storyblok';
import { memoize } from '@/utils/memoize';

interface Props {
	blok: {
		blogs: string[];
	};
}

const memoizedFetchBlogs = memoize(async (featuredBlogUUIDs: string[]) =>
	apiClient.post<BlogpostItemCardFragment[]>(
		'/api/storyblok/fetch-featured-blogs',
		{
			featuredBlogUUIDs,
		}
	)
);

export const FeaturedBlogBlok: FC<Props> = ({ blok }) => {
	const [featuredBlogs, setFeaturedBlogs] = useState<{
		blogs: BlogpostItemCardFragment[];
		blokState: 'idle' | 'loading' | 'error';
	}>({
		blokState: 'loading',
		blogs: [],
	});

	useEffect(() => {
		const fetchStories = async (blogUUIDs: string[]) => {
			try {
				const blogStories = await memoizedFetchBlogs(blogUUIDs);

				setFeaturedBlogs({
					blokState: 'idle',
					blogs: blogStories,
				});
			} catch (e) {
				setFeaturedBlogs({
					blokState: 'error',
					blogs: [],
				});
			}
		};

		fetchStories(blok.blogs);
	}, [blok]);

	return (
		<div className="mx-auto flex w-full">
			<Grid
				templateColumns="repeat(1,1fr)"
				templateRows="repeat(3,1fr)"
				md={{
					templateColumns: 'repeat(3,1fr)',
					templateRows: 'repeat(1,1fr)',
				}}
				gap="sm"
			>
				{featuredBlogs.blokState === 'error'
					? null
					: featuredBlogs.blokState === 'idle' && featuredBlogs?.blogs?.length
						? featuredBlogs?.blogs.map((blogItem, index) => {
								return (
									<Grid.Item key={index}>
										<BlogCard
											excerpt={blogItem.content?.excerpt ?? undefined}
											href={blogItem.full_slug ?? undefined}
											imageSrc={
												blogItem.content?.banner_image_desktop?.filename ??
												undefined
											}
											name={blogItem.name ?? undefined}
										/>
									</Grid.Item>
								);
							})
						: Array.from(Array(3)).map((index) => (
								<Grid.Item key={index}>
									<Skeleton variant="rectangular" width={150} height={300} />
								</Grid.Item>
							))}
			</Grid>
		</div>
	);
};
