// From https://github.com/cahilfoley/react-snowfall

import React, { useCallback, useEffect, useRef } from 'react';

import {
	ParticleProps,
	defaultConfig,
	useParticles,
} from '@/components/shared/animations/Particle';
import {
	useComponentSize,
	useDeepMemo,
} from '@/components/shared/animations/utils';

export interface ParticleFallProps extends Partial<ParticleProps> {
	/**
	 * The number of particles to be rendered.
	 *
	 * The default value is 150.
	 */
	count?: number;
	/**
	 * Any style properties that will be passed to the canvas element.
	 */
	style?: React.CSSProperties;
}

// Target of 30 frames per second (How fast the petals move)
const targetFrameTime = 1000 / 30;

const ParticleFall = ({
	color = defaultConfig.color,
	changeFrequency = defaultConfig.changeFrequency,
	radius = defaultConfig.radius,
	speed = defaultConfig.speed,
	wind = defaultConfig.wind,
	rotationSpeed = defaultConfig.rotationSpeed,
	count = 150,
	images = [],
}: ParticleFallProps = {}): JSX.Element => {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasSize = useComponentSize(canvasRef);
	const animationFrame = useRef(0);

	const lastUpdate = useRef(Date.now());
	const config = useDeepMemo<ParticleProps>({
		color,
		changeFrequency,
		radius,
		speed,
		wind,
		rotationSpeed,
		images,
	});
	const particles = useParticles(canvasRef, count, config);

	const render = useCallback(
		(framesPassed = 1) => {
			const canvas = canvasRef.current;
			if (canvas) {
				// Update the positions of the particles
				particles.forEach((particle) => particle.update(canvas, framesPassed));

				// Render them if the canvas is available
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.setTransform(1, 0, 0, 1, 0, 0);
					ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);

					particles.forEach((particles) => particles.draw(ctx));
				}
			}
		},
		[particles]
	);

	const loop = useCallback(() => {
		// Update based on time passed so that a slow frame rate won't slow down the particles
		const now = Date.now();
		const msPassed = Date.now() - lastUpdate.current;
		lastUpdate.current = now;

		// Frames that would have passed if running at 60 fps
		const framesPassed = msPassed / targetFrameTime;

		render(framesPassed);

		animationFrame.current = requestAnimationFrame(loop);
	}, [render]);

	useEffect(() => {
		loop();
		return () => cancelAnimationFrame(animationFrame.current);
	}, [loop]);

	return (
		<canvas
			ref={canvasRef}
			height={canvasSize.height}
			width={canvasSize.width}
			style={{
				pointerEvents: 'none',
				backgroundColor: 'transparent',
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			}}
		/>
	);
};

export { ParticleFall };
