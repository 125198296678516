import { Button, Container } from '@krakentech/coral';
import { FC, useEffect, useState } from 'react';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import apiClient from '@/services/api-client';
import { StoryblokComponentProps } from '@/types/storyblok';
import { memoize } from '@/utils/memoize';

type ButtonProps = {
	blok: {
		senryu_name: string;
	};
	props?: StoryblokComponentProps;
};

const memoizedVoteSenryu = memoize(async (senryuName: string) =>
	apiClient.post('/api/storyblok/vote-senryu', {
		senryuName,
	})
);

export const VoteButton: FC<ButtonProps> = ({ blok: { senryu_name } }) => {
	const [isDisabled, setIsDisabled] = useState(false);

	const [userSenryuVotes, setUserSenryuVotes] = useLocalStorage<string[]>(
		'senryuVotes',
		[]
	);

	useEffect(() => {
		setIsDisabled(
			userSenryuVotes.includes(senryu_name) || userSenryuVotes.length >= 3
		);
	}, [setIsDisabled, userSenryuVotes]);

	const handleSenryuVote = () => {
		memoizedVoteSenryu(senryu_name);
		setUserSenryuVotes([...userSenryuVotes, senryu_name]);
		setIsDisabled(true);
	};

	return (
		<Container>
			<Button
				disabled={isDisabled}
				onClick={() => {
					handleSenryuVote();
				}}
			>
				投票する
			</Button>
		</Container>
	);
};
