import { FC, SetStateAction, useState } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';
import { DynamicComponent } from '@/components/storyblok/index';
import { StoryContent } from '@/types/storyblok';

type TabProps = {
	blok: {
		documents: StoryContent;
		heading: string;
	};
	isSelected: boolean;
	setCurrentHeading: (value: SetStateAction<string>) => void;
};

export type CMSTabsProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary';
		bloks: TabProps['blok'][];
		default_tab: string;
	};
};

const isSelectedStyles =
	'inline-block p-2 font-bold bg-primary rounded-tl rounded-tr border-2 border-b-0 border-electricpurpleshade2 translate-y-0.5 cursor-pointer';

const isNotSelectedStyles =
	'inline-block p-2 font-bold translate-y-0.5 cursor-pointer';

const Tab: FC<TabProps> = ({ blok, setCurrentHeading, isSelected }) => {
	return (
		<button
			className={isSelected ? isSelectedStyles : isNotSelectedStyles}
			onClick={() => setCurrentHeading(blok.heading)}
			key={blok.heading}
		>
			{blok.heading}
		</button>
	);
};

export const CMSTabs: FC<CMSTabsProps> = ({
	blok: { default_tab, bloks, background_color = 'bg-primary' },
}) => {
	const [currentHeading, setCurrentHeading] = useState(default_tab);
	const selectedTabBloks = bloks.filter(
		(blok) => blok.heading === currentHeading
	);

	return (
		<div className={`w-full ${background_color}`}>
			<Container>
				{bloks.map((tab) => (
					<Tab
						blok={tab}
						setCurrentHeading={setCurrentHeading}
						isSelected={currentHeading === tab.heading}
						key={tab.heading}
					/>
				))}
				<div className="flex flex-col items-center space-y-6 border-2 border-electricpurpleshade2 bg-primary p-4 sm:flex-row sm:space-y-0 sm:space-x-8">
					<div className="flex flex-wrap gap-8">
						{selectedTabBloks.map((selectedTab) =>
							selectedTab.documents.map((blok) => (
								<DynamicComponent blok={blok} key={blok._uid} />
							))
						)}
					</div>
				</div>
			</Container>
		</div>
	);
};
