export enum ColorHex {
	akoya = '#FA98FF',
	aquamarine = '#BDF2FF',
	blueberry = '#2D1A83',
	bluetang = '#6675F6',
	donatellopurple = '#674FFF',
	hemocyanin = '#180048',
	ice = '#F0FFFF',
	ink = '#5840FF',
	lilacpurple = '#898EFF',
	palepurple = '#4E4074',
	periwinkle = '#949FFF',
	purplehaze = '#A49FC8',
	siphon = '#100030',
	soholights = '#F050F8',
	voltage = '#60F0F8',
	yabaired = '#D93535',
}
