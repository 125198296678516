import { Button as CoralButton, Link as CoralLink } from '@krakentech/coral';
import type {
	ButtonProps as CoralButtonProps,
	LinkProps as CoralLinkProps,
} from '@krakentech/coral';
import NextLink from 'next/link';
import { FC, ReactNode } from 'react';

type Props = {
	as?: string;
	children?: ReactNode;
	className?: string;
	href: string;
	isButton?: boolean;
};

/*
 * Coral button and link components both have a property called 'color' which
 * prevent the use of a simple intersection type
 */
type LinkProps = Props & Maybe<CoralLinkProps>;
type ButtonProps = Props & Maybe<CoralButtonProps>;

const Link: FC<LinkProps | ButtonProps> = ({
	as,
	children,
	className,
	href,
	isButton = false,
	...props
}) => {
	return (
		<NextLink as={as} className={className} href={href} passHref legacyBehavior>
			{isButton ? (
				<CoralButton {...(props as ButtonProps)}>{children}</CoralButton>
			) : (
				<CoralLink {...(props as LinkProps)}>{children}</CoralLink>
			)}
		</NextLink>
	);
};

export { Link };
